.Listings{
    color: var(--black);
    font-family: 'GothamRounded-Book';
    position: relative; 
    padding-top: var(--offset-top); 
}
.ListingSearch{
    width: 480px;
    margin: auto;
}
.ListingSearch input{
    width: 100%;
}
.ListingSearch{
    width: 580px;
    border: 2px solid var(--blue);
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background-color: var(--white);
    margin: 0 auto 10px auto;
}
.ListingSearch input{
    width: 97%;
    outline: none;
    border: none;
    text-align: center;
    padding: 6px;
    box-sizing: border-box;
    color: var(--gray);
    font-family: 'GothamRounded-Medium';
    font-size: var(--l-large);
}
.ListingSearch input::placeholder{
    font-size: var(--large);
    opacity: 0.8;
}
.ListingSearch img{
    width: 3.5%;
    height: auto;
    opacity: 0.8;
    margin: 0 12px 0 0;
    filter: invert(44%) sepia(0%) saturate(0%) hue-rotate(357deg) brightness(98%) contrast(85%);
}
.CountContainer{
    display: flex;
    align-items: center;
    text-align: center;
        /* gap: 5px; */
}
.BookedContainer p:nth-child(1),.AvailableContainer p:nth-child(1){
    font-family: 'GothamRounded-Bold';
    font-size: 26px;

}
.BookedContainer p:nth-child(2),.AvailableContainer p:nth-child(2){
    font-family: 'GothamRounded-Medium';
    font-size: var(--SS-small);
}
.CountContainer span{
    font-size: 30px;
    margin: -12px 8px 0 0;
    font-family: 'GothamRounded-Bold';
}
.TicketHeading{
    margin: 0 0 0 25px;
}
.TicketHeading p{
    text-align: center;
    font-family: 'GothamRounded-Medium';
}
.FirstContainer{
    /* display: flex; */
    align-items: center;
    /* width: 68%; */
    /* border: 2px solid red; */
    justify-content: space-between;
}
.NewIcon{
    height: 22px;
    vertical-align: middle;
}
.AccountHr{
	width: 55%;
	height: 1.5px;
	margin: auto;
	background-color: var(--blue);
}
.AccountHeading{
	color: var(--black);
	margin:  15px auto;
	font-family: 'GothamRounded-Medium';
    text-align: center;
    /* text-decoration: underline; */
	
}
.AccountHeading span{
    font-family: 'GothamRounded-Medium';
   border-bottom: 2px solid var(--black);
    font-size: var(--large);
    /* width: 50%; */
    /* border: 2px solid red; */
    box-sizing: border-box;
    width: 80px;
}
.AccountHeading img{
width: 25px;
vertical-align: middle;
margin-right: 10px;
transform: rotate(-22deg);
filter: brightness(0);
}
.ActiveInactive{
    text-align: center;
   

}
.ActiveInactive button{
    border: none;
  
    border-radius: 15px;
    width: 120px;
    padding: 10px;
    font-size: 15px;
    font-family: GothamRounded-Medium;
    border: 2px solid var(--blue);

    margin: 0px 20px 20px 10px;
}
.ActiveInactive button:hover{
    background-color: var(--blue);
    color: black;
    opacity: 1;
}
.ActiveInactive img{
    width: 15px;
    vertical-align: middle;
    margin-left: 5px;
}
.ActiveInactive .Selected{
    background-color: var(--blue);
    color: var(--black);
    font-family: GothamRounded-Bold;
    font-size: 18px;
    padding: 8px;
}
.ActiveInactive .Nonselected{
    background-color: inherit;
    color: var(--white);
}
.NewListingLink{
    background: var(--blue);
    display: inline-block;
    border-radius: 20px;
    padding: 10px 0;
    /* position: absolute;
    left: 20%;
    top: 155px; */
    transition: 0.2s;
    width: 50px;
    text-align: center;
    white-space: nowrap;
    margin: 0 0 0 0px;
    position: absolute;
    left: 20%;
    /* top: 20%; */
}
.NewListingLink span{
    display: inline-block;
    opacity: 0;
}
.NewListingLink:hover span{
    margin: 0 5px;
    font-family: 'GothamRounded-Medium';
    vertical-align: middle;
    opacity: 1;
    transition: opacity 0.2s;
}

.NewListingLink:hover{
    width: 170px;
}
.NewListingLink:hover img{
    margin-left: 0;
    transition: margin 0.2s;
}

.NewListingLink img{
    margin-left: 15px;
}


.NewListingLink2{
    display: none;
    text-align: center;
    background: var(--blue);
    width: 220px;
    margin:5px  auto 20px auto;
    border-radius: 20px;
    padding: 10px 15px;
    font-family: GothamRounded-Medium;
}
.NewListingLink2 img{
    margin-right: 10px;
}

.TogglePosition{
    position: absolute;
    margin: -30px 265px
}
.Week{
    text-align: center;
}
.ListingsHead{
    border: 2px solid var(--black);
    border-radius: 20px;
    width: 825px;
    overflow: hidden;
    margin: 15px auto 0 auto;
}
.ListingsCont{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
}
.Listings1 p{
    margin:0;
    margin-bottom: 2.5px;
    padding: 0 30px;
    box-sizing: border-box;
}
.Listings1 p:first-of-type{
    color: var(--black);
    font-family: 'GothamRounded-Medium';
}
.Listings1 p:last-of-type{
    font-size: small;
    /* margin-left: 0px; */
}
.Listings1 p a{
    color: var(--black);
}
.Listings1{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
.Listings1 .DateTime{
    padding: 0 20px;
    box-sizing: border-box;
}
.Listings2_2{
    width: 20%;
    background-color: var(--blue);
    color: var(--black);
    border-radius: 0px 20px 20px 0px;
    margin  : -1px;  
    text-align: center;
    font-family: 'GothamRounded-Medium';
    cursor: pointer;
}
.Listings2{
    width: 25%;
    color: var(--black);
    border-radius: 0px 20px 20px 0px;
    margin  : -1px;  
    text-align: center;
    font-family: 'GothamRounded-Medium';
    cursor: pointer;
}
.Listings2p {
    font-size: 15px;
}
.Listings2pC{
    color: var(--black);
}
.Expand , .Collapse{
    vertical-align: middle;
    width: 25px;
    filter: brightness(0);
}
.EmptyListings{
    text-align: center;
    margin-top: 30px;
}
/* .Expand{

    transform: rotate(360deg);
  
    transition: transform 0.25s linear;
}
.Collapse{
   
    transform: rotate(360deg);
    transition: transform 0.25s linear;
} */
.CollapsedDiv{
    width: 95%;
    background-color: var(--blue);
    margin:15px auto;
    border-radius: 20px;
    animation: growDown 300ms ease-in-out forwards;
    transform-origin: top center;
  
}
.FadeDiv{
    background-color: var(--l-gray);
}
.CautionImageContainer img{
    width: 30px;
    height: auto;
    filter: invert(49%) sepia(73%) saturate(1911%) hue-rotate(320deg) brightness(116%) contrast(91%);
}
@keyframes growDown {
    0% {
      transform: scaleY(0)
    }
    80% {
      transform: scaleY(1.1)
    }
    100% {
      transform: scaleY(1)
    }
  }
.CollapsedHeading{
    border-radius: 20px 20px 0 0;
    color: var(--black);
    text-align: center;
    background-color: var(--white);
    font-family: 'GothamRounded-Medium';
}
.CollapsedInner{
    font-family: 'GothamRounded-Medium';
    display: flex;
    flex-direction: row;
    color: var(--black);
    padding:8px 0;
    align-items: center;
    justify-content: space-evenly;
}


.CollapsedInner p {
    margin:0;
}

.CollapsedInner1 img{
    width: 25px;
    vertical-align: middle;
    cursor: pointer;
}
.CollapsedInner1 img:hover{
    filter: invert(1);
}


.CollapsedInner2p , .CollapsedInner3p , .CollapsedInner5p{
    font-family: 'GothamRounded-Bold';
    font-size: large;
    text-align: center;
}
.CollapsedInner2p{
    font-size: 30px;  
}

.Border{
    border-right: 2px solid var(--black);
    height: 50px;
}
.CollapsedInner6 p{
    margin-top: 10px;
}
.ViewButton {
    display: flex;
    justify-content: space-around;
}
    
.ViewButton button{
    min-width: 145px;
    padding: 5px;
    border-radius: 15px;
    border: none;
    font-family: 'GothamRounded-Medium';
    margin-top: -8px;
    margin-bottom: 3px;
}
    .ViewButton img{
        width: 15px;
        vertical-align: middle;
        margin: auto 5px ;
    }
    
    
.NoTickets{
    text-align: center;
    font-size: 14px;
}

.InactiveP{
    font-size: small;
    width: 80%;
    text-align: center;
    padding-top: 12px;
    padding-bottom: 12px;
}
/* .EditForm{
    width: 95%;
    background-color: var(--blue);
    margin:15px auto;
    border-radius: 20px;
    color: var(--black);
    font-size: small;
    font-family: GothamRounded-Medium;
}
.EditFormp{
    background-color: var(--white);
    text-align: center;
    border-radius: 20px 20px 0 0;
}
.EditFlex{
    display: flex;
    flex-direction: row;
    width: 70%;
    margin: 10px auto;
    justify-content: space-around;
}
.EditFlex p {
    margin:0;
}
.EditFlex img{
    width: 15px;
    vertical-align: middle;
    margin: auto 5px ;
}
.Table1Container{
    display: flex;
    flex-direction: row;
    padding-left: 15px;
}
.Table1Col1{
    width: 110px;
   
}
.Table1Div1{

    width: 400px;
}
.Table1Div2_7{
    margin-left: -10px;
}
.Table1{
    width: 100%;
    padding:15px 0 15px 2.5px;
}
.MaintianWidth{
    width: 125px;
}
.MaintianWidth1{
    width: 104px;
}
.Adjac{
    position: absolute;
    padding-left: 2px;
}

.TableHr{
    height: 2px;
    width: 50%;
    margin: auto;
    background-color: var(--black);
}
.EditForm select , .EditForm input{
    border:2px solid var(--m-gray);
    border-radius: 10px;
    padding: 5px;
    text-align: center;
    text-align-last: center;
    -ms-text-align-last: center;
    -moz-text-align-last: center;
    color: var(--gray);
}


.TableAdj{
    border-radius: 10px;
    width: 26px;
    height: 26px;
    vertical-align: middle;
}
.Tablep{
    text-align: center;
}
.EditFlex button{
    border: none;
    background-color: transparent;
    vertical-align: middle;
    font-family: 'GothamRounded-Bold';
}
.Table1Margin{
    position: absolute;
    right: 0;
}
.Table1Margin label{
    margin-left: 20px;
}
.TableButton1 ,.TableButton2 , .TableButton3{
    padding: 7px;
    width: 140px;
    border-radius: 15px;
  
    font-family: 'GothamRounded-Medium';
    margin-bottom: 15px;
}
.TableButton1{
    background-color: var(--white);
}
.TableButton2{
    background-color: var(--l-green);
}
.TableButton3{
    position: relative;
    background-color: var(--pink);
}
.Table2Row td{
   padding-top: 18px;
}
.TablepCheck{
    margin-left: -20px;
}
.TableInputShort{
    width: 50%;
}
.File{
    border: none;
}
.HardTicket{
    padding: 15px;
   
    width: 50%;
    margin:auto;
    text-align: center;
  
}
.HardTicketPhoto{
    display: flex;
    background-color: var(--white);
    height: 220px;
    justify-content: space-around;
    align-items: center;
    border-radius: 20px;
}
 */

.NTicketCont{
    background-color: var(--white);
    width: 60% ;
    height: 500px;
    margin: 60px auto 0px auto;
    border: 1px solid var(--black);
    border-radius: 10px;
    padding: 0px 10px;
    font-family: GothamRounded-Book;
}
.Price{
    font-family: 'GothamRounded-Bold';
    font-size: 16px;
}
.NticketHead{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid var(--black) ;
  
    padding: 15px 70px;
}
.NticketHeadp{
    font-family: GothamRounded-Medium;
}
.NticketHead div p ,.Nticket2_2{
    margin: 0;
    font-size: 13px;
    margin-bottom: 5px;
    color: var(--black);
}
.NticketHead div p{
    text-align: left;
}
.NticketHead div p:first-of-type , .Nticket2_1{
    color: var(--black);
    font-size: 14px;
}
.Nticket2{
    width: 80%;
    margin: auto;
    text-align: center;
}
.NCont{
    display: flex;
    flex-direction: row;
    width: 70%;
    margin: 15px auto;
    justify-content: center;
}
.NticketList{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--blue);
    width: 80%;
   /* width: 70%;
    margin: auto; */
    color: var(--black);
    padding: 15px;
    border-radius: 10px 0px 0px 10px;
    border-right: 3px solid var(--black);
}

.NCont2{
    background-color: var(--blue);
    border-radius: 0px 10px 10px 0px;
    display: flex;
    position: relative;
}
.NticketListR{
    background-color: #3c585c;
  }
.NticketListDiv1 p{
    margin: 0;
    font-family: 'GothamRounded-Bold';
}
.NticketListDiv1 p:last-of-type{
    font-family: 'GothamRounded-Medium';
    font-size: 13px;
}
.DeleteHover, .SearchHover , .DownloadHover{
    background-color: transparent;
    border:none;
    padding: 0 12px;
}
.NCont img{
    width: 25px;
}
.NCont i{
   font-size: 30px;
  /* vertical-align: middle; */
}
.NticketListDiv2{
    display: flex;
}
.DeleteHover:hover {
    filter: invert(52%) sepia(46%) saturate(6302%) hue-rotate(330deg) brightness(95%) contrast(108%);
}
.SearchHover img:hover , .DownloadHover img:hover{
   filter: invert();
}

.BorderBeforeSearch{
    width: 2px;
    background-color: var(--black);
    height: 100%;
}
.DownloadHover1{
    filter: invert()
}

.DeleteLabel{
    visibility: hidden;
    position: absolute;
    margin-top: -35px;
    margin-left: 20px;
    background-color: rgba(0,0,0,0.9);
    color: var(--white);
    padding: 5px 20px;
    border-radius: 10px 10px 10px 0px;
    font-size: small;
    border: 1px solid var(--white);
}
.DeleteHover:hover + .DeleteLabel{
    visibility: visible;
}
.DownloadHover:hover + .DownloadLabel{
    visibility: visible;
}


.Tooltip{
    visibility: hidden;
    position: absolute;
    width: 150px;
    margin-top: -35px;
    margin-left: 20px;
    right: 100px;
    background-color: rgba(0,0,0,0.9);
    color: var(--white);
    padding: 5px 20px;
    border-radius: 10px 10px 0px 10px;
    font-size: small;
    border: 1px solid var(--white);
}
.SwitchClass:hover .Tooltip{
    visibility: visible;
}

/*.DownloadLabel{
    visibility: hidden;
    position: absolute;
    margin-top: -25px;
    margin-left: 90px;
    background-color: rgba(0,0,0,0.9);
    color: var(--white);
    padding: 5px 20px;
    border-radius: 10px 10px 10px 0px;
    font-size: small;
    border: 1px solid var(--white);
}
.DownloadLabel1{
    visibility: hidden;
    position: absolute;
    margin-top: -25px;
    margin-left: 40px;
    background-color: rgba(0,0,0,0.9);
    color: var(--white);
    padding: 5px 20px;
    border-radius: 10px 10px 10px 0px;
    font-size: small;
    border: 1px solid var(--white);
}*/
.SearchLabel{
    visibility: hidden;
    position: absolute;
    width: 100px;
    z-index: 99;
    margin-top: -10px;
    margin-left: 25px;
    background-color: rgba(0,0,0,0.9);
    color: var(--white);
    padding: 5px 20px;
    border-radius: 10px 10px 10px 0px;
    font-size: 12px;
    border: 1px solid var(--white);
}
.DownloadHover1:hover + .DownloadLabel1{
    visibility: visible;
}
.SearchHover:hover + .SearchLabel{
    visibility: visible;
}
/* .CustomFile::file-selector-button {
        border: 2px solid rgb(139, 137, 137);
        padding: 6px 14px;
        border-radius: 8px;
        background-color: rgb(161, 152, 152);
        transition: 1s;
      } */
.Browse{
    /* width: 200px; */
    margin: auto 32px auto 13px;
    border: 2px solid var(--gray);
    padding: 5px 17px;
    border-radius: 8px;
    background-color: var(--m-gray);
}
.BrowseHide{
    display: none;
}

.FileNameText{
    margin: 10px 0;
}

.NticketHeadMedia{
    display: none;
}
.MobilePriceListing{
    display: none;
}
.TicketDisplay{
    height: 60%;
}
.ETicketsContainer{
    height: 70%;
    overflow: auto;
}
.TicketImage{
    height: 100%;
    max-width: 100%;
    display: block;
    margin: auto;
}
.TicketDisplay button{
}
.BackButton{
    cursor: pointer;
}

@media(max-width:1300px)
{
    .NewListingLink{
        left: 15%;
    }
    /* .FirstContainer{
        width: 72%;
    } */
}
@media(max-width:1100px)
{
    .NewListingLink{
        left: 10%;
    }
    /* .FirstContainer{
        width: 78%;
    } */
}
@media(max-width:1000px)
{
    .NewListingLink{
        left: 5%;
    }
    /* .FirstContainer{
        width: 82%;
    } */
    /* .FirstContainer{
        width: 100%;
        justify-content: center;
    } */
}
@media(max-width:900px)
{
    .NewListingLink{
        left: 3%;
    }
    /* .ListingSearch{
        width: 100%;
    } */
    /* .ListingSearch img{
        width: 6%;
    } */
}
@media(max-width:850px)
{
    .CollapsedInner1{
        padding: 2px;
     
    }
    .CollapsedInner1 img, .CautionImageContainer img{
        width: 20px;
    }
    .Border{
        border-right: 1.5px solid var(--black);
       
    }
    .ListingsHead{
        width: 95%;
    }
    /* .ListingSearch{
        width:100%;
        margin: auto;
    } */
    /* .ListingSearch input{
        padding: 6px 45px 6px 45px;
    } */
    .NewListingLink{
        display: none;
    }
    .Hide{
        display: none;
    }

    .NewListingLink2{
      display: block;
    }
    .CollapsedDiv{
        font-size: 15px;
    }
    .CollapsedDiv p {
        text-align: center;
    }
    .CollapsedInner2p{
    }
    .CollapsedInner3p , .CollapsedInner5p{
        font-size: 15px;
    }
    

    .AccountHr{
        width: 95%;
     
    }
    .NTicketCont{
        width: 90%;
    }
    .NticketHead{
       display: none;
    }
    .NCont{
        width: 100%;
    }
    .Nticket2{
        display: none;
    }

    .NticketHeadMedia {
        color: rgba(255,255,255,0.8);
        display: block;
    }

    .MHr{
        width: 80%;
        margin: auto;
        height: 2px;
        background-color: var(--blue);
    }

    .NMedia2{
        display: flex;
        justify-content: space-between;
        padding: 15px 10px;
    }
    .NticketHeadp1 {
        margin: 0;
        font-size: 17px;
        margin-bottom: 5px;
        color: var(--blue);
  }

    .NHeadp_1{
      font-size: 15px;
      font-family: 'GothamRounded-Light'
    }

    .NLocation{
      margin: 0;
      font-size: 14px;
      text-align: initial;
      font-family: 'GothamRounded-Light'
    }
  .SearchLabel,.DeleteLabel
  {
      display: none;
  }
  /* .FirstContainer{
    width: 100%;
    justify-content: center;
} */
}
@media(max-width: 768px){
    .MobileHide{
        display: none;
    }
    .MobilePriceListing{
        display: block;
        font-family: 'GothamRounded-Bold';
    }
    .CollapsedDiv{
        font-size: 12px;
    }
    .CollapsedInner2p{
        font-size: 20px;
    }
    .Border{
        margin: 0px 5px;
    }
    .CollapsedInner{
        padding: 5px;
    }
    .ViewButton button{
        margin-top: 0px;
    }
    /* .ListingSearch{
        width: 100%;
    } */
    .ListingSearch input{
        padding: 6px 45px 6px 45px;
    }
}
@media(max-width:650px){
    .ListingSearch{
        width: 85%;
    }
}
@media(max-width: 500px){
    .CollapsedDiv{
        font-size: 11px;
    }
    .CollapsedInner3p , .CollapsedInner5p{
        font-size: 13px;
    }
    .ListingSearch input{
        padding: 6px 0px 6px 0px;
    }
    .ListingSearch img{
        width: 6%;
    }
    .BookedContainer p:nth-child(1),.AvailableContainer p:nth-child(1){
        font-size: 18px;
    }
    .BookedContainer p:nth-child(2),.AvailableContainer p:nth-child(2){
        font-size: 12px;
    }

}
@media(max-width:400px){
.NewListingLink2{
    font-size: small;
    padding: 7px;
    width: 180px;
}
.Listings1 p{
    font-size: small;
}
.ActiveInactive button{
    padding: 7px;
    width: 105px;
    border-radius: 10px;
}
}

.Help{
    display: inline-block;
    margin: 0 3px;
    cursor: pointer;
    position: relative;
}
.Help div{
    background: var(--blue);
    color: var(--black);
    padding: 1px 5px;
    border-radius: 50%;
}

.Tooltip{
    visibility: hidden;
    position: absolute;
    width: 150px;
    bottom: -20px;
    left: 10px;
    background-color: rgba(0,0,0,0.9);
    color: var(--white);
    padding: 5px 20px;
    border-radius: 0px 10px 10px 10px;
    font-size: small;
    border: 1px solid var(--white);
    z-index: 99;
}
.Help:hover .Tooltip{
    visibility: visible;
}

@media (max-width: 420px){
    .Tooltip{
        top: 20px;
        left: -70px;
    }
}