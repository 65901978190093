.Link{
	text-decoration: underline;
	color: var(--blue-dark);
}
.Link:hover{
	color: var(--black);
}
.MainContent{
	color: var(--black);
	width: 80%;
	font-size: 14px;
	margin: auto;
	font-family: 'GothamRounded-Book';
	line-height: 1.5;
}
.PrivacyContainer{
	padding-top: 100px;
}
.Heading {
	text-align: center;
	color: var(--black);
	margin: 20px auto;
	font-family: 'GothamRounded-Medium';
	font-size: 18px;
}

.SubHeading{
	color: var(--black);
	font-size: 18px;
}

@media (max-width: 768px){
	.MainContent{
		width: 90%;
	}
}