.container{
    padding-top: var(--offset-top);
}
.container hr{
    background-color: var(--black);
    border: 1px solid var(--black);
    opacity: 0.9;
    text-align: center;
    height:1px;
}
.para_container{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.para_container p:nth-child(even){
    color: var(--black);
    text-align: center;
    font-family: 'GothamRounded-Book';
    font-weight: bold;
    margin-bottom: 30px;
}
.para_container p:nth-child(odd){
    color: var(--black);
    opacity: 0.9;
    text-align: center;
    font-family: 'GothamRounded-Light';
}
.empty_box img{
    height: 40px;
    vertical-align: middle;
    border-radius: 10px;
    margin-right: 10px;
}
.events_location  p{
    color:var(--black);
    font-family: 'GothamRounded-Medium';
    font-size: 14px;
}
.events_location{
    display:flex;
    flex-direction: row;
    align-items: center;
   margin-left: 20px;
}
select{
    background-color: var(--blue);
    padding:5px 40px;
    border-radius: 20px;
    font-family: 'GothamRounded-Medium';
    text-align: center;
}

.second_container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin:0 auto  20px auto;
    width:60%;
}
.outer_container{
    background-color: var(--blue);
    width: 70%;
    margin: 0 auto 20px auto;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.Artists.outer_container{
    width: 94%;
}
.inner_container{
    display: flex;
    flex-direction: row;
    color: var(--blue);
    background-color: var(--white);
    justify-content: space-between;
    border-radius: 0 20px 20px 0;
    align-items: center;
    padding: 10px;
    width:90%;
}
.date_container {
    padding: 10px;
    width:10%;
}
.date_container p{
    margin: 0;
    text-align: center;
}
.date_container p:nth-child(2){
    font-family: 'GothamRounded-Bold';
    font-size: 1.5rem;
}
.date_container p:nth-child(1){
    font-family: 'GothamRounded-Bold';
    font-size: 1.1rem;
}
.date_container p:nth-child(3){
    font-family: 'GothamRounded-Medium';
}
.events_text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;
}
.events_text p{
    margin: 0;
}
.events_text p:nth-child(1){
    color: var(--black);
    font-family: 'GothamRounded-Medium';
    margin-bottom: 10px;
}
.events_text p a{
    color: var(--black);
}
.events_text p:nth-child(2){
    color:var(--m-gray);
    font-family: 'GothamRounded-Book';
    font-size: 0.8rem;
}
.events_text span{
    color: var(--blue);
    white-space: nowrap;
}

.events_button{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 30%;
}
.events_button button{
    background-color: var(--blue);
    padding: 10px 30px;
    text-align: center;
    border-radius: 20px;
    outline: none;
    border: none;
    font-family: 'GothamRounded-Medium';
}

.Artists, .Events{
    margin-top: 30px;
}
.EventsHeading{
    text-align: center;
}
.EventsHeading p:nth-child(1){
    color: var(--black);
    font-family: 'GothamRounded-Medium';
}
.EventsHeading p:nth-child(2){
    color: var(--black);
    font-family: 'GothamRounded-Book';
    font-size: 14px;
}
.ArtistsTab{
    width: 75%;
    margin: 20px auto;
}
.ArtistsTab .Tab{
    background: var(--blue);
    width: 94%;
    margin: auto;
    text-align: center;
    padding: 20px 0;
    border-radius: 20px;
    font-family: 'GothamRounded-Bold';
    font-size: 14px;
    cursor: pointer;
}
.ArtistsTab .Tab b{
    font-family: 'GothamRounded-Bold';
    margin: 0 10px;
}
.ArtistsTab .Tab span{
    font-family: 'GothamRounded-Light';
    margin-left: 10px;
}
.ArtistsTab .Tab span span{
    font-family: 'GothamRounded-Bold';
}
.ArtistsTab .Tab img{
    height: 18px;
    vertical-align: middle;
    margin-left: 10px;
}

.ShareIcon{
    float: right;
    margin-right: 20px;
}
.ArtistsTab.Open{
    border: 2px solid var(--black);
    border-radius: 20px;
}
.ArtistsTab.Open .Tab{
    background: transparent;
    color: var(--black);
}
.ArtistsTab.Open .Tab img{
    filter: invert(1);
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
}

.FilterPopup{
    text-align: center;
}
.FilterHeading{
    color: var(--blue);
    padding: 0px 30px;
    padding-bottom: 5px;
    border-bottom: 1px solid var(--blue);
    font-family: 'GothamRounded-Medium';
}
.FilterSubHeading{
    color: var(--white);
    margin: 10px 0;
    font-size: 12px;
}
.FilterPopupContainer{
    width: 200px;
    margin: 50px auto 0 auto;
    background: var(--black);
    border-radius: 20px;
    border: 2px solid var(--blue);
    padding: 20px 50px;
    color: var(--white);
}
.FilterItem{
    margin: 10px 0;
    font-size: 12px;
    font-family: 'GothamRounded-Medium';
}
.FilterItem input{
    height: 30px;
    width: 30px;
    vertical-align: middle;
}
.ConfirmFilter button{
    padding: 7px 40px;
    border-radius: 20px;
}
.ShowMore{
    text-align: center;
    font-family: 'GothamRounded-Bold';
    color: var(--black);
    cursor: pointer;
    font-size: 15px;
}
.events_text span.BoldGreen{
    font-family: 'GothamRounded-Bold';
    color: var(--green);
}
@media(max-width:1200px){
    .events_button{
        width: 50%;
    }
}
@media(max-width:1000px){
    .outer_container{
        width: 80%;
    }
    .second_container{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        margin:0 auto  20px auto;
        width:80%;
    }
    .container hr{
        width: 100%;
    }
    .ArtistsTab{
        width: 80%;
    }
    .ArtistsTab .Tab{
        width: 100%;
    }
}
@media(max-width: 768px){
    .inner_container{
        display: flex;
        flex-direction: column;
    }
    .events_button{
        margin-top: 10px;
        width: 70%;
        justify-content: space-between;
        align-self: flex-start;
    }
    .ArtistsTab .Tab b:nth-child(1){
        display: none;
    }
    .ArtistsTab .Tab > span{
        display: block;
        margin: 0;
    }
    .events_text{
        align-self: flex-start;
        width: 100%;
    }
}
@media(max-width:600px){
    .second_container{
        width: 90%;
    }
    .outer_container{
        width: 90%;
    }
    .date_container{
        width: 15%;
    }
    .ArtistsTab{
        width: 90%;
    }
}

@media(max-width:500px){
    .date_container{
        text-align: center;
        padding-top: 30px;
    }
    .second_container{
        width:90%;
    }
    .events_button{
        width: 100%;
    }
}
@media(max-width: 400px){
    .outer_container{
        width: 95%;
    }
    .date_container{
        padding-left: 5px;
    }
    .date_container p:nth-child(2){
        font-size: 18px;
    }
    .date_container p:nth-child(1){
        font-size: 16px;
    }
    .date_container p:nth-child(3){
        font-size: 16px;
    }
}
@media(max-width: 350px){
    .date_container{
        padding-left: 2px;
    }
    .date_container p:nth-child(2){
        font-size: 18px;
    }
    .date_container p:nth-child(1){
        font-size: 16px;
    }
    .date_container p:nth-child(3){
        font-size: 16px;
    }
    .events_button button{
        padding: 10px 18px;
    }
}


.CountryFilter{
    display: flex;
    justify-content: center;
    color: var(--blue);
}
.CountryFilter div{
    margin: 0 10px;
    cursor: pointer;
}
.CountryFilter img{
    height: 30px;
    border-radius: 10px;
    border: 2px solid var(--black);
}
.CountryFilter div.CountrySelected img{
    border-color: var(--blue);
}
.NoResults{
    color: var(--black);
    text-align: center;
    font-family: 'GothamRounded-Medium';
    margin: 20px 0;
    font-size: 18px;
}
.EventNotFound{
    color: var(--black);
    text-align: center;
    margin-top: 40px;
}
.EventNotFound span{
    color: var(--black);
    cursor: pointer;
    text-decoration: underline;
}

.SearchedEventsContainer{
    display: flex;
    align-items: flex-start;
}
.EventsShowcase div img{
    width: 100%;
}
.EventsShowcase{
    margin-top: 30px;
    flex-basis: 15%;
}
.SearchedEventsList{
    flex-basis: 100%;
}

@media (max-width: 768px){
    .SearchedEventsContainer{
        flex-direction: column;
    }
    .EventsShowcase{
        display: flex;
        margin: auto;
    }
    .EventsShowcase div{
        flex-basis: 33%;
    }
    .EventsShowcase div img{
        height: 80px;
        width: auto;
    }
    .SearchedEventsList{
        width: 100%;
    }
}