.Container{
    padding-top: 80px;
}
.NTicketCont{
    background-color: var(--black);
    width: 60%;
    height: 500px;
    margin: 0px auto 0px auto;
    border: 1px solid var(--blue);
    border-radius: 10px;
    padding: 0px 10px;
    font-family: GothamRounded-Book;
}
.Price{
    font-family: 'GothamRounded-Bold';
    font-size: 16px;
}
.NticketHead{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid var(--blue) ;
  
    padding: 15px 70px;
}
.NticketHeadp{
    font-family: GothamRounded-Medium;
}
.NticketHead div p ,.Nticket2_2{
    margin: 0;
    font-size: 13px;
    margin-bottom: 5px;
    color: rgba(255,255,255,0.8);
}
.NticketHead div p{
    text-align: left;
}
.NticketHead div p:first-of-type , .Nticket2_1{
    color: var(--blue);
    font-size: 14px;
}
.Nticket2{
    width: 80%;
    margin: auto;
    text-align: center;
}
.NCont{
    display: flex;
    flex-direction: row;
    width: 70%;
    margin: 15px auto;
    justify-content: center;
}
.NticketList{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--blue);
    width: 80%;
    color: var(--black);
    padding: 15px;
    border-radius: 10px 0px 0px 10px;
    border-right: 3px solid var(--black);
}

.NCont2{
    background-color: var(--blue);
    border-radius: 0px 10px 10px 0px;
    display: flex;
    position: relative;
}
.NticketListR{
    background-color: #3c585c;
  }
.NticketListDiv1 p{
    margin: 0;
    font-family: 'GothamRounded-Bold';
}
.NticketListDiv1 p:last-of-type{
    font-family: 'GothamRounded-Medium';
    font-size: 13px;
}
.DownloadHover, .SearchHover , .DownloadHover{
    background-color: transparent;
    border:none;
    padding: 0 12px;
}
.NCont img{
    width: 25px;
}
.NCont i{
   font-size: 30px;
}
.NticketListDiv2{
    display: flex;
}
.SearchHover img:hover , .DownloadHover img:hover{
   filter: invert(1);
}

.BorderBeforeSearch{
    width: 2px;
    background-color: var(--black);
    height: 100%;
}
.DownloadHover1{
    filter: invert()
}

.DownloadLabel{
    visibility: hidden;
    position: absolute;
    margin-top: -35px;
    margin-left: 20px;
    background-color: rgba(0,0,0,0.9);
    color: var(--white);
    padding: 5px 20px;
    border-radius: 10px 10px 0px 10px;
    font-size: small;
    border: 1px solid var(--white);
    right: 0;
}
.DownloadHover:hover + .DownloadLabel{
    visibility: visible;
}

.Tooltip{
    visibility: hidden;
    position: absolute;
    width: 150px;
    margin-top: -35px;
    margin-left: 20px;
    right: 100px;
    background-color: rgba(0,0,0,0.9);
    color: var(--white);
    padding: 5px 20px;
    border-radius: 10px 10px 0px 10px;
    font-size: small;
    border: 1px solid var(--white);
}
.SwitchClass:hover .Tooltip{
    visibility: visible;
}
.SearchLabel{
    visibility: hidden;
    position: absolute;
    margin-top: -20px;
    margin-left: 25px;
    background-color: rgba(0,0,0,0.9);
    color: var(--white);
    padding: 5px 20px;
    border-radius: 10px 10px 10px 0px;
    font-size: small;
    border: 1px solid var(--white);
}
.DownloadHover1:hover + .DownloadLabel1{
    visibility: visible;
}
.SearchHover:hover + .SearchLabel{
    visibility: visible;
}
.Browse{
    /* width: 200px; */
    margin: auto 32px auto 13px;
    border: 2px solid var(--gray);
    padding: 5px 17px;
    border-radius: 8px;
    background-color: var(--m-gray);
}
.BrowseHide{
    display: none;
}

.FileNameText{
    margin: 10px 0;
}

.NticketHeadMedia{
    display: none;
    text-align: center;
}
.MobilePriceListing{
    display: none;
}
.TicketDisplay{
    height: 60%;
    text-align: center;
}
.ETicketsContainer{
    height: 70%;
    overflow: auto;
}
.TicketImage{
    height: 100%;
    display: block;
    margin: auto;
}
.TicketDisplay button{
}
.BackButton{
    cursor: pointer;
}
@media(max-width:850px)
{
    .NTicketCont{
        width: 90%;
    }
    .NticketHead{
       display: none;
    }
    .NCont{
        width: 100%;
      
    }
    .Nticket2{
        display: none;
    }

    .NticketHeadMedia {
        color: rgba(255,255,255,0.8);
        display: block;
    }

    .MHr{
        width: 80%;
        margin: auto;
        height: 2px;
        background-color: var(--blue);
    }

    .NMedia2{
        display: flex;
        justify-content: space-between;
        padding: 15px 10px;
    }
    .NticketHeadp1 {
        margin: 0;
        font-size: 17px;
        margin-bottom: 5px;
        color: var(--blue);
  }

    .NHeadp_1{
      font-size: 15px;
      font-family: 'GothamRounded-Light'
    }

    .NLocation{
      margin: 0;
      font-size: 14px;
      text-align: initial;
      font-family: 'GothamRounded-Light'
    }
  .SearchLabel,.DeleteLabel
  {
      display: none;
  }
}