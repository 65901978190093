.FAQ{
    color: var(--black);
    padding-top: var(--offset-top);
    width: 80%;
    margin: auto;
}

.Filter{
    float: right;
    width: 200px;
    padding: 15px;
    border-radius: 10px;
    background-color: var(--blue);
    border: 1px solid var(--white);
    font-family: GothamRounded-Medium;
}

.Topic{
    color: var(--black);
    font-family: 'GOTHAMROUNDED-MEDIUM';
    font-size: 22px;
}
.Q{
    cursor: pointer;
    margin-bottom: 0;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    /* color: var(--blue); */
}
.Q img{
    width: 20px;
    vertical-align: middle;
    transition: all 0.5s;
    filter: brightness(0);
}
.ToggleUp img{
    transform: rotate(0deg);
}

.ToggleDown img{
    transform: rotate(90deg);
}
.A{
    padding-left: 25px;
    font-size: 14px;
    font-family: 'GothamRounded-Book';
    color: var(--black);
}
.Date{
    padding-left: 25px;
    font-size: 13px; 
    font-family: GothamRounded-Light;
}
.AnswerNotFound{
    margin-top: 50px;
}
.AnswerNotFound span{
    color: var(--black);
    font-weight: 400;
    cursor: pointer;
}

@media(max-width: 768px){
.FAQ{
    width: 90%;
}

}
@media(max-width:500px)
{
.Q, .A{
    font-size: small;
}

}

@media(max-width:400px)
{
    .Filter{
        width: 170px;
        padding: 10px;
 
    }
}