/* CHECK BEFORE MERGE */

@font-face {
  font-family: GothamRounded-Bold;
  src: url('./assets/fonts/gotham-rounded/GothamRounded-Bold.otf');
}
@font-face {
  font-family: GothamRounded-Medium;
  src: url('./assets/fonts/gotham-rounded/GothamRounded-Medium.otf');
}
@font-face {
  font-family: GothamRounded-Book;
  src: url('./assets/fonts/gotham-rounded/GothamRounded-Book.otf');
}
@font-face {
  font-family: GothamRounded-Light;
  src: url('./assets/fonts/gotham-rounded/GothamRounded-Light.otf');
}


@font-face {
  font-family: AllRoundGothic-Bold;
  src: url('./assets/fonts/gotham-rounded/AllRoundGothic-W03-Bold.ttf');
}
@font-face {
  font-family: AllRoundGothic-Medium;
  src: url('./assets/fonts/gotham-rounded/AllRoundGothic-W03-Medium.ttf');
}


@font-face {
  font-family: Avenir-Bold;
  src: url('./assets/fonts/Avenir LT Std/Avenir LT Std 85 Heavy/Avenir LT Std 85 Heavy.otf');
}
@font-face {
  font-family: Avenir-Medium;
  src: url('./assets/fonts/Avenir LT Std/Avenir LT Std 65 Medium/Avenir LT Std 65 Medium.otf');
}
@font-face {
  font-family: Avenir-Book;
  src: url('./assets/fonts/Avenir LT Std/Avenir LT Std 45 Book/Avenir LT Std 45 Book.otf');
}
@font-face {
  font-family: Avenir-Light;
  src: url('./assets/fonts/Avenir LT Std/Avenir LT Std 35 Light/Avenir LT Std 35 Light.otf');
}


/* 4:3 and 5:4 */

:root{
	--blue: #8DEEFD;
  --blue-thick: #3AD8F0;
  --blue-hover: #6AEBFF;
  --blue-hover-2: #C2F6FF;
  --blue-rgb: 141, 238, 253;
	--white: #FFFFFF;
	--gray: #717171;
  --l-gray: #CCCCCC;
  --m-gray: #979595;
	--black: #050505;
  --blue-dark: #027F92;
  --red: #e15763;
  --l-red: #fc4448;
  --pink: #f36d7c;
  --green: #008A00;
  --l-green: #6df47e;
  --max-size: 1920px;
  --x-large:26px;
  --large:18px;
  --l-large:16px;
  --S-small:14px;
  --SS-small:13px;
  --l-small:12px;
  --offset-top: 100px;
}

/* CHECK BEFORE MERGE */
/* 18604198585 */


body {
  margin: 0;
  font-family: 'GothamRounded-Book';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.backdropCont{
  display: block;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  top:0;
  top: var(--offset-top);
  z-index: 1000;
  /* padding-top: 70px; */
  backdrop-filter: blur(2px);
  -webkit-transition: 0.5s;
  overflow: auto;
  transition: all 0.3s linear;
  max-width: var(--max-size);
  margin: auto;
}
.backdropCont1{
  display: block;
	position: fixed;
	height: 100%;
	width: 100%;
	left: 0;
  right: 0;
	top: 0;
	z-index: 1000;
	backdrop-filter: blur(8px);
	-webkit-transition: 0.5s;
	overflow: auto;
	transition: all 0.3s linear;
	text-align: center;
  max-width: var(--max-size);
  margin: auto;
}


.myButton{
  border: none;
  background-color: var(--blue);
  font-weight: bold;
  padding:8px 18px;
  border-radius: 10px;
  /* margin-bottom: 20px; */
}

.myInput{
  border: 2px solid var(--black);
  border-radius: 8px;
  padding: 5px;
  text-align: center;
  outline: none;
}
input, select{
  font-family: 'GothamRounded-Book' ;
}
input, select, textarea{
  font-size: 16px !important;
}
input::placeholder, textarea::placeholder{
  font-size: 13px;
}
button:focus,input:focus ,select:focus {
  outline: none;
}

button:hover{
  opacity: 0.8;
}
button:active{
  transform: translateY(1px);
}
button{
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    color: var(--black);
}

a:hover , button:hover{
  cursor: pointer;
}
a{
  text-decoration: none;
  color: var(--black);
}

::-webkit-scrollbar {
	width: 7.5px;
	height: 7.5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
	background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
	background:#31353D;
  }
  ::-webkit-scrollbar-corner{
	  border-radius: 1rem;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--l-gray);
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: var(--white);
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: var(--green);
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px var(--green);
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  @media(max-width:768px)
  {
    .switch {
      width: 50px;
      height: 30px;
    }
    .slider:before {
      height: 24px;
      width: 24px;
      bottom: 3px;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(18px);
      -ms-transform: translateX(18px);
      transform: translateX(18px);
    }    
  }

  @-moz-document url-prefix() {
    .backdropCont{
      background: rgba(0,0,0,0.8);
    }
    .backdropCont1{
      background: rgba(0,0,0,0.8);
    }
  }