.ServiceCont {
    width: 50%;
    margin: auto;
    color: var(--black);
    font-size: 15px;
    padding-bottom: 30px;
    font-family: GothamRounded-Book;
    line-height: 1.7;
}

.Service1 p:last-of-type {
    text-align: center;
}

.AboutImg {
    width: 110px;
    filter: brightness(0);
}

.AboutDiv {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    text-align: left;
}

.AboutDiv1 {
    width: 70%;
}

.AboutDiv1 p:first-of-type {
    color: var(--black);
    font-family: 'GothamRounded-Medium';
}
p a{
    color: var(--black);
    text-decoration: underline;
}
.AboutDiv2 {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.AboutHr {
    height: 2.5px;
    width: 45%;
    margin: auto;
    background-color: var(--black);
}

.AboutLastp {
    margin: 25px auto;
    color: var(--black);
}

.AboutHead {
    font-size: 22px;
}

@media(max-width:700px)
{
    .ServiceCont , .AboutDiv1 , .AboutDiv2 {
        width: 92%;
        margin: auto;
    }

    .AboutDiv{
        flex-direction: column;
    }
  .AboutDiv2{
      order: 2;
      margin: 10px 0px;
  }
  .AboutHr {
    height: 1.5px;
    width: 65%;
   
}

.AboutHead{
    display: none;
}
}