.CartCont{
    text-align: center;
}

.Mid{
    margin-top: 150px;
    padding: 0px 15px;
}

.P1{
    cursor: pointer;
    color: var(--blue-dark);
    font-size: large;
    font-family: GothamRounded-Bold;
}
.P3{
    cursor: pointer;
    color: var(--blue-dark);
}

.Black{
    color:var(--black);

}
.CartItems{
    font-family: 'GothamRounded-Bold';
    font-size: 16px;
    margin: 0;
}
.CartItem{
    color: var(--blue-dark);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
}
.CartItem > div{
    margin: 0 10px;
}
.CartItem img{
    height: 30px;
    vertical-align: sub;
    margin-left: 5px;
}
.CartItem > div:nth-child(2) > div:nth-child(2){
    color: var(--black);
    font-family: 'GothamRounded-Medium';
    font-size: 14px;
    margin-top: 5px;
}
.CartItem{
    padding: 20px 0;
    border-bottom: 2px solid var(--blue);
}

.Total{
    padding: 20px 0;
    border-bottom: 2px solid var(--blue);
    color: var(--blue-dark);
    font-family: 'GothamRounded-Bold';
    font-size: 16px;
}
.Total span{
    color: var(--black);
}

.Checkout{
    padding: 20px 0;
}
.Checkout button{
    color: var(--black);
    border: none;
    outline: none;
    background: var(--blue);
    padding: 12px 20px;
    font-family: 'GothamRounded-Bold';
    border-radius: 10px;
    margin: 0 5px;
}
.Checkout button:nth-child(2){
    background: var(--pink);
}
.DeleteButton img{
    filter: invert(60%) sepia(22%) saturate(4959%) hue-rotate(314deg) brightness(103%) contrast(91%);
}