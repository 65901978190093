.Container{
	display: block;
	position: fixed;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	z-index: 1000;
	backdrop-filter: blur(12px);
	-webkit-backdrop-filter: blur(12px); /* For Chrome and Safari */
  -moz-backdrop-filter: blur(12px);    /* For Firefox */
	-webkit-transition: 0.5s;
	overflow: auto;
	transition: all 0.3s linear;
	text-align: center;
}

.Block{
	background: var(--white);
	border: 2px solid var(--black);
	border-radius: 20px;
	color: var(--black);
	display: inline-block;
	padding: 20px;
	margin: 20px;
}