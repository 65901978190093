.AlertBox{
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 99999;
	backdrop-filter: blur(8px);
	text-align: center;
}

.Container{
	margin-top: 100px;
	display: inline-block;
}

.WrapBox{
	background: var(--white);
	border: 2px solid var(--black);
	border-radius: 20px;
	padding: 20px;
}

.AlertTitle{
	color: var(--black);
	font-family: 'GothamRounded-Medium';
	font-size: 18px;
	margin: 10px 0;
}

.AlertMessage{
	color: var(--pink);
	font-size: 14px;
	font-family: 'GothamRounded-Light';
	margin: 10px 0;
}

.AlertButtons{
	margin-top: 20px;
}
.AlertButtons .AcceptButton, .AlertButtons .DenyButton{
	padding: 10px 25px;
	color: var(--black);
	font-family: 'GothamRounded-Medium';
	font-size: 12px;
	background: transparent;
	border: 2px solid var(--black);
	border-radius: 10px;
	margin: 0 20px;
}

.AlertButtons .AcceptButton:hover, .AlertButtons .DenyButton:hover{
	background: var(--blue);
	color: var(--black);
	font-family: 'GothamRounded-Bold';
}
.AlertLink{
	margin-top: 15px;
}
.AlertLink a{
	color: var(--black);
	text-decoration: underline;
	font-size: 14px;
	font-family: 'GothamRounded-Light';
}

@media (min-width: 600px){
	.Container{
		max-width: 500px;
		margin: 100px auto 0 auto;
	}
}

@media (max-width: 350px){
	.WrapBox{
		background: var(--black);
		border: 2px solid var(--blue);
		border-radius: 20px;
		padding: 20px 5px;
	}	
}