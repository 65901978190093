.BannerFull{
	background: url('../../../assets/images/compressed/background/banner-full.jpg');
}

.BannerCollapse{
	background: url('../../../assets/images/compressed/background/banner-collapse.jpg');
}

.Banner{
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill-available;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
	height: auto;
	overflow: hidden;
	padding-bottom: 15px;
	z-index: 999;
	transition: 0.5s;
	position: fixed;
	padding-top: var(--offset-top);
	max-width: var(--max-size);
	border-bottom: 2px solid var(--blue);
	border-left: 2px solid var(--blue);
	border-right: 2px solid var(--blue);
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	background: var(--black);
}

.EventDetailsContainer{
	color: var(--blue);
	text-align: center;
}
 

.Icon{
	height: 20px;
}

.Location div{
	display: inline-block;
	margin: 10px 20px 5px 0;
}
.MobileEventVenue{
	display: none;
	margin-top: 10px;
}
.Location div img, .MobileEventVenue img{
	vertical-align: bottom;
	margin: 0 5px;
}

.Title{
	font-size: 24px;
	font-family: 'GothamRounded-Medium';
	word-spacing: 5px;
	letter-spacing: 5px;
}

.ActionCalls{
	margin-top: 10px;
}
.ActionCalls button{
	margin: 0 20px;
	padding: 5px 40px;
	border: 2px solid var(--blue);
	font-family: 'GothamRounded-Medium';
}
.ActionCalls button.SellButton{
	background: transparent;
	border-color: var(--blue);
	color: var(--blue);
}
.ActionCalls button.SellButton:hover{
	background: var(--blue);
	color: var(--black);
	opacity: 1;
}
.ActionCalls button.WaitlistButton{
	background: var(--black);
	border-color: var(--white);
	color: var(--white);
}
.ActionCalls .EventStats{
	display: inline-block;
	font-size: 12px;
	font-family: 'GothamRounded-Medium';
}
.ActionCalls .EventStats .StatsContainer{
	display: flex;
}
.StatsContainer > div{
	margin-right: 15px;
}
.StatsContainer > div > span{
	font-size: 18px;
	font-family: 'GothamRounded-Bold';
}
@media (max-width: 768px){
	.Title{
		font-size: 20px;
	}
	.ActionCalls button{
		margin: 0 10px;
		padding: 5px 25px;
	}
	.Location{
		font-size: 14px;
	}
	.ActionCalls .EventStats{
		display: none;
	}
}

@media (max-width: 500px){
	.Title{
		font-size: 18px;
		word-spacing: 3px;
		letter-spacing: 2px;
	}
	.ActionCalls button{
		margin: 0 10px;
		padding: 5px 25px;
	}
	.Location{
		font-size: 12px;
	}
	.Banner{
	}
}
@media (max-width: 400px){
	.Title{
		font-size: 16px;
		word-spacing: 2px;
		letter-spacing: 1px;
	}
	.ActionCalls button{
		margin: 0 3px;
		font-size: 12px;
		padding: 5px 20px;
	}
}