.BlockHead .Title{
	color: var(--black);
	font-family: 'GothamRounded-Medium';
	font-size: 20px;
	display: inline-block;
	padding: 0 30px 10px 30px;
	border-bottom: 1px solid var(--black);
}
.BlockHead .Info{
	margin-top: 20px;
	color: var(--black);
	font-size: 14px;
	font-family: 'GothamRounded-Light';
}
.BlockHead .SuccessInfo{
	color: var(--black);
	font-size: 14px;
	font-family: 'GothamRounded-Light';
	width: 80%;
	margin: 30px auto;
}
.BlockHead .SuccessInfo .Success{
	color: var(--black);
	font-family: 'GothamRounded-Bold';
	margin: 10px 0;
}
.BlockHead .ClickOutsideInfo{
	color: var(--black);
	font-family: 'GothamRounded-Light';
	font-size: 14px;
	margin-bottom: 20px;
}
.BlockHead .SuccessInfo .Success img{
	height: 10px;
	vertical-align: middle;
}
.WaitlistForm{
	margin: 20px 0;
}
.WaitlistForm .InputBlock {
	margin: 10px 0;
}
.WaitlistForm .InputBlock input,
.WaitlistForm .InputBlock select{
	height: 30px;
	border-radius: 10px;
	border: 2px solid var(--gray);
	box-sizing: border-box;
	outline: none;
}
.WaitlistForm .InputBlock input{
	width: 90%;
	text-align: center;
}
.WaitlistForm .InputBlock input::placeholder{
	text-align: center;
}
.WaitlistForm .InputBlock select{
	display: inline-block;
}
.WaitlistForm .InputBlock select:nth-child(1){
	width: 50%;
	margin-right: 10px;
}
.WaitlistForm .InputBlock select:nth-child(2){
	width: 35%;
	margin-left: 10px;
}
.WaitlistForm .SubmitButton{
	margin: 20px;
}
.WaitlistForm .SubmitButton button{
	color: var(--black);
	font-size: 12px;
	font-family: 'GothamRounded-Bold';
	background: var(--blue);
	padding: 10px 30px;
	outline: none;
	border: 0;
	border-radius: 50px;
}
.WaitlistForm .SubmitButton img{
	height: 20px;
	vertical-align: middle;
}
.ShowFilter{
	outline: none;
	border: 0;
	background: transparent;
	padding: 4px 10px;
	border-radius: 15px;
	color: var(--blue);
	font-family: 'GothamRounded-Book';
}

@media(max-width: 550px){
	.Filters div{
		display: block !important;
		width: 95% !important;
		margin: auto !important;
		margin-bottom: 10px !important;
	}
}