.SofortForm{
	text-align: center;
	padding: 80px 0;
}
.SofortForm input{
	width: 50%;
	margin: 40px auto 20px auto;
	padding: 5px 0;
	border-radius: 10px;
	border: 2px solid var(--blue);
	outline: none;
}
.SofortForm input::placeholder{
	text-align: center;
}

.SofortForm button{
	background: var(--blue);
	color: var(--black);
	padding: 8px 20px;
	border: 0;
	outline: none;
	font-family: 'GothamRounded-Bold';
}

.SofortForm img{
	display: block;
	height: 50px;
	margin: auto;
}