.LogoLoader{
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 999999;
	top: 0;
	left: 0;
	right: 0;
	max-width: var(--max-size);
	margin: auto;
	background: rgba(0, 0, 0, 0.8);
	text-align: center;
}
.LogoLoader div{
	margin-top: 20%;
    font-size: 50px;
}
.LogoLoader div span.Kaufmein{
	font-family: 'GothamRounded-Medium';
}
.LogoLoader div span.Ticket{
	font-family: 'AllRoundGothic-Medium';
}
.LogoLoader div span.Kaufmein:nth-child(1){
    -webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: var(--white);
	animation: kaufmein forwards 0.3s;
	animation-delay: 0s;
}
.LogoLoader div span.Kaufmein:nth-child(2){
    -webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: var(--white);
	animation: kaufmein forwards 0.3s;
	animation-delay: 0.3s;
}
.LogoLoader div span.Kaufmein:nth-child(3){
    -webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: var(--white);
	animation: kaufmein forwards 0.3s;
	animation-delay: 0.6s;
}
.LogoLoader div span.Kaufmein:nth-child(4){
    -webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: var(--white);
	animation: kaufmein forwards 0.3s;
	animation-delay: 0.9s;
}
.LogoLoader div span.Kaufmein:nth-child(5){
    -webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: var(--white);
	animation: kaufmein forwards 0.3s;
	animation-delay: 1.2s;
}
.LogoLoader div span.Kaufmein:nth-child(6){
    -webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: var(--white);
	animation: kaufmein forwards 0.3s;
	animation-delay: 1.5s;
}
.LogoLoader div span.Kaufmein:nth-child(7){
    -webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: var(--white);
	animation: kaufmein forwards 0.3s;
	animation-delay: 1.8s;
}
.LogoLoader div span.Kaufmein:nth-child(8){
    -webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: var(--white);
	animation: kaufmein forwards 0.3s;
	animation-delay: 2.1s;
}



.LogoLoader div span.Ticket:nth-child(9){
    -webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: var(--blue);
	animation: ticket forwards 0.3s;
	animation-delay: 2.4s;
}
.LogoLoader div span.Ticket:nth-child(10){
    -webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: var(--blue);
	animation: ticket forwards 0.3s;
	animation-delay: 2.7s;
}
.LogoLoader div span.Ticket:nth-child(11){
    -webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: var(--blue);
	animation: ticket forwards 0.3s;
	animation-delay: 3s;
}
.LogoLoader div span.Ticket:nth-child(12){
    -webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: var(--blue);
	animation: ticket forwards 0.3s;
	animation-delay: 3.3s;
}
.LogoLoader div span.Ticket:nth-child(13){
    -webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: var(--blue);
	animation: ticket forwards 0.3s;
	animation-delay: 3.6s;
}
.LogoLoader div span.Ticket:nth-child(14){
    -webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: var(--blue);
	animation: ticket forwards 0.3s;
	animation-delay: 3.9s;
}
.LogoLoader div span.Ticket:nth-child(15){
    -webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: var(--blue);
	animation: ticket forwards 0.3s;
	animation-delay: 4.2s;
}


@media (max-width: 768px){
	.LogoLoader div{
		font-size: 32px;
	}
}
@keyframes kaufmein{
	0%{
		color: var(--black);
	}
	100%{
		color: var(--white);
	}
}

@keyframes ticket{
	0%{
		color: var(--black);
	}
	100%{
		color: var(--blue);
	}
}