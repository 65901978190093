.SearchContainer{
	/* margin-top: 10px; */
}
.SearchIcon img{
	background: var(--blue-thick);
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	height: 40px;
	box-sizing: border-box;
	padding: 12px 10px;
	vertical-align: middle;
	cursor: pointer;
/*	margin-right: 10px;*/
}

/* SEARCH INPUT */
.SearchInput{
	border: 0px solid var(--blue);
	width: 500px;
	padding: 5px;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	-webkit-border-top-left-radius: 10px;
	-webkit-border-bottom-left-radius: 10px;
	-webkit-border-top-right-radius: 0px;
	-webkit-border-bottom-right-radius: 0px;
	height: 40px;
	box-sizing: border-box;
	outline: none;
	vertical-align: middle;
	text-align: center;
	transition: 0.6s;
/*	margin-left: 10px;*/
}


.SearchInput::placeholder{
	padding-left: 30px;
	font-size: 13px;
	font-family: 'GothamRounded-Book';
}


.HeaderAbsolute{
	position: absolute;
	left: 55%; 
	top: 18px;
	z-index: -1;
}

.SearchInput.Header{
	width: 380px;
}
.SearchInput.Header::placeholder{
	text-align: center;
}
.SearchContainer.Header{
	margin: 0;
	position: relative;
	left: -75%;
}

.SearchDrop , .SearchDrop1{
	background-color: var(--white);
	max-height: 50vh;
	height: 50vh;
	overflow-y: scroll;
	width: 535px;
	margin: auto;
	transition: 0.6s;
	text-align: center;
}
.SeeAllButton{
	color: var(--blue-dark);
	background: transparent;
	font-size: 16px;
}
.SearchDrop1{
	width: 415px;
}
.SearchNames{
	margin: 0px 20px;
	margin-top: 30px;;
	text-align: left;
	padding-bottom: 10px;
	border-bottom: 1px solid var(--gray);
	color: var(--black);
	font-family: 'GothamRounded-Bold';
}
.NoResults{
	color: var(--gray);
	text-align: center;
}
.SearchNames1{
	margin: 0px;
	padding: 10px;
	padding-left: 40px;
	text-align: left;
	color: var(--black);
	overflow: hidden;
}
.SearchNames1:hover{
	/*background: var(--blue);*/
	border: 1px solid var(--black);
	margin: 0 2px;
	cursor: pointer;
	font-family: 'GothamRounded-Bold';
}
.SearchNames1:hover .SubList{
	font-family: 'GothamRounded-Book';
}
.SearchNames1.SubList:hover{
	background: var(--blue);
	border: 1px solid var(--black);
	margin: 0 2px;
	cursor: pointer;
}
.SearchNames1:hover a{
	color: var(--black);
}
.SearchNames1 a{
	color: var(--gray);
}
.SearchNames a {
	color: var(--black);
}
.SearchNames1 .Count{
	float: right;
	color: var(--blue-dark);
	font-weight: bold;
}
.SearchNames1 .Count button{
	padding: 3px 20px;
	margin-right: 3px;
}
.animated {
	-webkit-animation-duration: 0.3s;
	animation-duration: 0.3s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	display: inline-block;
}
.EventTitle{
	margin: 5px 0;
}
@-webkit-keyframes fadeIn {
	0% {opacity: 0;}
	100% {opacity: 1;}
}

@keyframes fadeIn {
	0% {opacity: 0;}
	100% {opacity: 1;}
}

.fadeIn {
	-webkit-animation-name: fadeIn;
	animation-name: fadeIn;
}

/* mobile view */

.NewSearchMobile{
	display: inline-block;
	padding-right: 10px;
}

.NewSearchMobile1{
	position: absolute;
	top: 50px;
	right: 10px;
}
.SearchIcon1{
	vertical-align: middle;
	display: inline-block;
}
.SearchIcon1 img{
	background: var(--blue-thick);
	border-radius: 10px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	height: 30px;
	box-sizing: border-box;
	padding: 7px 10px;
	vertical-align: middle;
	cursor: pointer;
	margin-left: -3px;
}

.MobileSearchBar{
	border: 2px solid var(--blue);
    width: 450px;
	padding: 5px;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	height: 30px;
	box-sizing: border-box;
	outline: none;
	vertical-align: middle;
	text-align: center;
	transition: 0.6s;
}
@media(min-width: 1101px) and (max-width: 1300px){
	.SearchInput.Header{
		width: 280px;
	}
	.SearchContainer.Header{
		left: -70%;
	}
	.SearchDrop1{
		width: 315px;
	}
}
@media(max-width:1130px)
{
	.NewSearchMobile .SearchDrop{
		width: 480px;
	}
	.SearchDropJumbotron{
	}
}

@media(max-width:1070px)
{
	.SearchInput.Header{
		width: 250px;		
	}
	.SearchNames1 .Count{
		display: block;
		float: none;
		margin: 5px 0;
	}
}

@media(max-width:680px)
{
	.SearchInput{
		width: 380px;
		transition: 0.6s;
	}
	.MobileSearchBar{
		width: 390px;
	}
	.SearchDrop{
		width: 410px;
		transition: 0.6s;
	}
	.SearchNames{
		margin-left: 5px;
	}
	.SearchNames1{
		padding-left: 15px;
	}
	.NewSearchMobile .SearchDrop{
		width: 420px;
	}
}

@media(max-width:590px)
{
	.MobileSearchBar{
		width: 350px;
	}
	.SearchDrop{
	}
	.NewSearchMobile .SearchDrop{
		width: 380px;
	}
	.SearchDropJumbotron{
	}
}
@media(max-width:550px)
{
	.MobileSearchBar{
		width: 250px;
	}
	.SearchDrop{
	}
	.NewSearchMobile .SearchDrop{
		width: 280px;
	}
	.SearchDropJumbotron{
	}
}

@media(max-width:470px)
{
	.MobileSearchBar{
		width: 200px;
	}
	.SearchInput{
		width: 220px;
		transition: 0.6s;
		
	}
	.SearchDrop{
		width: 250px;
		transition: 0.6s;
	}
	.SearchInput::placeholder{
		padding-left: 0;
		font-size: 13px;
	}
	.NewSearchMobile .SearchDrop{
		width: 230px;
	}
}

@media(max-width:400px)
{
	.MobileSearchBar{
		width: 170px;
	}
	.SearchDrop{
		width: 220px;
		transition: 0.6s;
	}
	.SearchDropJumbotron{
		width: 250px;
	}
	.NewSearchMobile .SearchDrop{
		width: 200px;
	}
}