.PersonalInfo{
	color: var(--black);
}
.FieldError{
	color: var(--l-red);
	font-size: 11px;
	text-align: left;
	margin: 0;
}
.EditIcon{
	width: 100px;
	float: right;
	margin-right: -1px;
	margin-top: -1px;
	cursor: pointer;
}
.Personal1{
	padding: 50px;	
	text-align: left;
	font-size: 14px;
}
.Personal1 h3{
	color: var(--black);
}
.Personal1 h3.Name{
	font-size: 18px;
}
.Personal1 p{
	opacity: 0.8;
}
/* .Personal1 h3{
	color: var(--white);
} */
.PersonalForm{
	width: 60%;
	margin: auto;
	padding: 25px 0;
}
.PersonalForm input,select{
    width: 100%;
    box-sizing: border-box;
	margin-bottom: 8px;
}

.PersonalForm select{
}
.PersonalForm select:first-of-type{
	float: left;
}
.PersonalForm select:last-of-type{
	float: right;
}
.PersonalButton{
	padding:8px 30px;
	margin-top: 15px;
	border: 5px solid var(--blue);
}
/*.PersonalButton:hover{
	background-color: transparent;
	color: var(--white);
	opacity: 1;
}*/
.PersonalButton:first-of-type{
	margin-right: 25px;
}
.Heading{
	color: rgba(255,255,255,0.85);
	display: none;
}
.PhoneNumberField{
	display: flex;
	margin-bottom: 8px;
	position: relative;
}
.PhoneNumberField input{
	margin: 0;
	height: 29px;
}
@media(max-width:800px)
{
	.MediaHide{
		display: none;
	}
	.PersonalForm{
		width: 85%;
	
	}
	.Heading{
		display: block;
	}

}



@media (max-width: 500px){
	.Personal1{
		padding:  20px 10px 20px 40px;
	}
	
}

@media (max-width: 400px){
	.Personal1{
		padding: 40px 10px 20px 30px;
	}
}

.PaymentMethod{
	position: fixed;
	height: 100%;
	width: 100%;
	backdrop-filter: blur(8px);
	top: 0;
	left: 0;
	z-index: 9999;
}
@-moz-document url-prefix() {
    .PaymentMethod{
      background: rgba(0,0,0,0.8);
    }
}

.PaymentMethodContainer{
	margin-top: 100px;
}
.PaymentMethodContainer .WrapBox{
	width: 350px;
	margin: auto;
	background: var(--white);
	border: 2px solid var(--black);
	border-radius: 20px;
	padding: 20px;
	color: var(--black);
	font-size: 14px;
}

.PaymentMethodContainer .InputMethod select{
	width: 210px;
	padding: 5px 10px;
	background: var(--black);
	color: var(--white);
	border: 2px solid var(--blue);
	border-radius: 20px;
	margin: 15px 0;
}

.PaymentMethodContainer .InputMethod input{
	width: 300px;
	padding: 5px 10px;
	background: var(--white);
	border: 2px solid var(--black);
	border-radius: 5px;
	box-sizing: border-box;
}
.PaymentMethodContainer .InputMethod input:disabled{
	background: var(--gray);
}

.PaymentMethodContainer .InputMethod label{
	display: flex;
	margin: 10px 0;
	align-items: center;
}
.PaymentMethodContainer .InputMethod label input:nth-child(1){
	flex-basis: 20%;
	height: 20px;
	width: 20px;
}
.PaymentMethodContainer .InputMethod label input:nth-child(2){
	flex-basis: 80%;
}
.PaymentMethodContainer .ActionButtons button{
	margin: 0 10px;
	background: var(--blue);
	color: var(--black);
	border: 2px solid var(--blue);
	padding: 7px 25px;
	border-radius: 10px;
	font-family: 'GothamRounded-Bold';
}

/*.PaymentMethodContainer .ActionButtons button:hover{
	opacity: 1;
	background: transparent;
	color: var(--blue);
}
.PaymentMethodContainer .ActionButtons button:nth-child(2):hover{
	background: var(--blue);
	color: var(--black);
}*/
.PaymentMethodContainer .ActionButtons button:nth-child(2){
	background: transparent;
	color: var(--black);
	border: 2px solid var(--black);
}
.AddPaymentMethod{
	margin: 20px 0;
}
.AddPaymentMethod div{
	cursor: pointer;
	color: var(--black);
	font-size: 16px;
	font-family: 'GothamRounded-Medium';
}

.Help{
	display: inline-block;
	margin: 0 3px;
	cursor: pointer;
	position: relative;
}
.Help div{
	background: var(--blue);
	color: var(--black);
	padding: 1px 5px;
	border-radius: 50%;
}

.Tooltip{
    visibility: hidden;
    position: absolute;
    width: 150px;
    top: 0px;
    left: 20px;
    background-color: rgba(0,0,0,0.9);
    color: var(--white);
    padding: 5px 20px;
    border-radius: 0px 10px 10px 10px;
    font-size: small;
    border: 1px solid var(--white);
    z-index: 99;
}
.Help:hover .Tooltip{
    visibility: visible;
}


@media (max-width: 768px){
	.Tooltip{
	    left: unset;
	    right: 20px;
	    border-radius: 10px 0px 10px 10px;
	}

}
@media (max-width: 450px){
	.PaymentMethodContainer .WrapBox{
		width: 80%;
		margin: auto;
	}
	.PaymentMethodContainer .ActionButtons{
		display: flex;
		justify-content: space-between;
	}
	.PaymentMethodContainer .ActionButtons button{
		margin: 0;
		padding: 10px 40px;
	}
	.PaymentMethodContainer .InputMethod input, .PaymentMethodContainer .InputMethod select{
		width: 100%;
	}
}

@media (max-width: 450px){
	.PaymentMethodContainer .ActionButtons button{
		padding: 10px 30px;
		margin: 5px;
	}
	.PaymentMethodContainer .ActionButtons{
		flex-wrap: wrap;
		justify-content: center;
	}
	.PersonalButton:first-of-type{
		margin-right: 5px;
	}
	.PersonalButton{
		margin-left: 5px;
		margin-right: 5px;
	}
	.PaymentMethodContainer .InputMethod label input:nth-child(1){
		flex-basis: 10%;
		height: 20px;
		width: 20px;
	}
	.PaymentMethodContainer .InputMethod label input:nth-child(2){
		flex-basis: 90%;
	}
}