.ChangeEmail{
	width: 70%;
	margin: 25px auto;
	padding: 20px 0;
}
.ChangeEmail p {
	margin:  0;
}
.ChangeEmail p.ContactSupport{
	color: var(--black);
	font-size: 14px;
	font-family: 'GothamRounded-Medium';
	margin-top: 15px;
}
.ChangeEmail p.ContactSupport a{
	color: var(--black);
	text-decoration: underline;
}
.EmailP1{
	font-size: 15px;
	color: var(--black);
	font-family: 'GothamRounded-Book';
}
.EmailP2{
	word-break: break-all;
	font-size: larger;
	color: var(--black);
	font-family: 'GothamRounded-Medium';

}
.ChangeInput{
	margin: 30px auto;
	width: 80%;
}
.ChangeInput input{
	width: 100%;
	box-sizing: border-box;
	margin: 5px 0;
}
.EmailButton{
	padding:8px 30px;
	margin-top: 15px;
	border: 5px solid var(--blue);
	margin-right: 20px;
	font-family: 'GothamRounded-Bold';
}
.EmailButton:hover{
	background-color: transparent;
	color: var(--white);
	opacity: 1;
}
.Heading{
	color: rgba(255,255,255,0.85);
	display: none;
}
.Error{
	border-color: var(--red);
}
@media(max-width: 1300px) and (min-width: 850px){
	.ChangeEmail{
		width: 100%;
	}
}
@media(max-width:800px)
{
	.Heading{
		display: block;
	}
	.ChangeEmail{
		width: 95%;

	}
	.ChangeInput{
	
		width: 95%;
	}
	.EmailP1{
	
	font-size: 13px;
		
	}
	.EmailP2{

		font-size: large;
	
		
	}
}