.MainContent{
	color: var(--black);
	width: 80%;
	font-size: 14px;
	margin: auto;
	font-family: 'GothamRounded-Book';
	line-height: 1.5;
}
.TermsContainer{
	padding-top: 100px;
}

.Heading {
	text-align: center;
	color: var(--black);
	margin: 20px auto;
	font-family: 'GothamRounded-Medium';
	font-size: 18px;
}

.SubHeading{
	color: var(--black);
	font-size: 18px;
}

.MainContent p{
	white-space: pre-wrap; /* CSS3 */    
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */    
    white-space: -o-pre-wrap; /* Opera 7 */    
    word-wrap: break-word; /* Internet Explorer 5.5+ */
}

@media (max-width: 768px){
	.MainContent{
		width: 90%;
	}
}