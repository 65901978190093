.ServiceCont {
    width: 50%;
    margin: auto;
    color: var(--black);
    font-size: 15px;
    padding-bottom: 30px;
    font-family: GothamRounded-Book;
    line-height: 1.7;
}

.AboutHead {
    font-size: 22px;
}

.Visionp {
    color: var(--black);
    margin: 30px auto;
    font-family: 'GothamRounded-Medium';
}

.AboutHr {
    height: 2.5px;
    width: 45%;
    margin: 30px auto;
    background-color: var(--black);
}

.VisionIcons img {
    width: 30px;
    margin-right: 20px;
    filter: brightness(0);
}
.VisionLastImg {
    width: 200px;
    margin: 40px auto 80px auto;
    filter: brightness(0);
}


@media(max-width:700px)
{
    .ServiceCont {
        width: 92%;
    }

    .AboutHr {
        height: 1.5px;
        width: 65%;
       
    }
    .AboutHead{
        display: none;
    }
}