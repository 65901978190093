.Sell {
	padding-top: var(--offset-top);
	padding-bottom: 30px;
}
.TextBlue {
	color: var(--black);
}
.TextBlue a{
	color: var(--black);
	text-decoration: underline;
}
.Upload {
	color: var(--black);
	margin: -400px auto 0 auto;
}
.NoMarginLeft{
	margin-left: 0px !important;
}
.ConditionContainer{
	/* padding: 5px; */
	padding: 20px;
    padding-left: 40px;
	width: auto;
	color: var(--black);
	border: 2px solid var(--blue);
	border-radius: 5px;
}
.ConditionContainer li{
	padding: 5px 10px;
	font-size: smaller;
}

.ConditionContainer li:last-child{
	padding: 5px 10px 0px 10px;
}

.ConditionContainer li::marker {
	  color: var(--black);
	  font-size: 15px;
  }
.OuterConditionContainer{
	
	color: var(--black);
}
.AccountHr {
	width: 55%;
	height: 2px;
	margin: auto;
	background-color: var(--blue);
}

.AccountHeading {
	text-align: center;
	color: var(--black);
	margin: 15px auto;
	font-family: 'GothamRounded-Bold';
}
.AccountHeading span:nth-child(2){
	text-decoration: underline;
}
.AccountHeading img {
	width: 25px;
	vertical-align: middle;
	margin-right: 10px;
	margin-top: -8px;
	transform: rotate(-20deg);
}
.FormContainer{
	display: flex;
	/* align-items: center; */
	/* border: 2px solid red; */
	width: 90%;
	margin: 5px auto 0 auto;
	justify-content: space-around;
}
.FormOneContainer{
	width: 45%;
	/* border: 2px solid blue; */
}
.FormTwoContainer{
	width: 45%;
}
.Sellp {
	text-align: center;
	margin: 0;
	color: var(--black);
	line-height: 18px;
	font-family: 'GothamRounded-Light';
	font-size: 13px;
	width: 60%;
	margin: auto;
}

.Sellp a {
	color: var(--black);
	font-family: 'GothamRounded-Medium';
	text-decoration: underline;
}
.PaymentInfo p{
	font-family: 'GothamRounded-Bold';
}

.SellHeadp1 {
	font-family: 'GothamRounded-Bold';
	padding-bottom: 7px;
	font-size: 20px;
}

.SellHeadp2 {
	font-size: 15px;
	font-family: GothamRounded-Medium;
}

.SellHeadp2 span:first-of-type {
	margin-right: 30px;
}

.SellMain {
	width: 850px;
	margin: 25px auto 0 auto;
	border: 0px solid var(--blue);
	border-radius: 20px;
	background-color: var(--white);
	background-image: url('../../assets/images/icons/newlistingboxbackground.png');
	background-repeat: no-repeat;
	background-position: bottom left;
	background-size: 300px;	
}
.SellMainHead {
	background-color: var(--blue);
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	text-align: center;
	padding: 15px 7px;
	margin: -1.25px;
}

.SellMainHead p {
	margin: 0;
}

.Policy{
	margin: 0px !important;
	/* margin-left: -25px !important; */
	padding: 0px 0px 20px 0px !important;
	
}
.AgreementPolicy p{
	font-size: medium !important;
	padding: 0px 0px 0px 10px !important;
}
.AgreementPolicy label{
	align-items: center !important;
}


.MobileTicketContainer{
	padding: 20px;
	font-family: 'GothamRounded-Medium';
}
.MobileTicketContainer p{
	font-size: small;
	text-align: center;
	color: var(--black);
}
.AgreementCondition input{
	width: 30px;
	height: 30px;
}
.AgreementCondition{
	margin: 30px 20px;
	position: relative;
	
}
.AgreementConditionCheckmark{
	/* margin: 0px 10px 0px 0px !important; */
	left: -5px !important;
}
.AgreementCondition p{
	font-size: small;
	padding: 0px 0px 0px 30px;
	text-align: left;
	font-family: 'GothamRounded-Medium';
	/* padding-right: 10px; */
	
}
.AgreementCondition label{
	display: flex;
	align-items: flex-start;
	margin: 0 0px;
	font-family: 'GothamRounded-Medium';
}
.AgreementCondition input{
	width: 20px;
	height: 20px;
	margin-right: 10px;
	/* display: none; */
}
.SquareLabelContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .SquareLabelContainer p {
    font-family: 'GothamRounded-Book';;
	
	margin: 0px;
  }
  
  
  /* Create a custom checkbox */
  .SquareCheckmark {
    position: absolute;
    left: -20px;
    height: 13px;
    width: 13px;
    margin: 2px;
    border-radius: 2px;
    border: 1.5px solid var(--black);
    background-color: var(--white);
  }
  
  /* On mouse-over, add a grey background color */
  .SquareLabelContainer:hover input ~ .SquareCheckmark {
    background-color: var(--white);
  }
  .SquareCheckmark:after {
    content: "";
    position: absolute;
    display: none;
}
.SquareLabelContainer input:checked ~ .SquareCheckmark:after {
  display: block;
}
  /* When the checkbox is checked, add a blue background */
  .SquareLabelContainer input:checked ~ .SquareCheckmark {
    background-color: var(--blue) !important ;
    border: 1.5px solid var(--blue) !important;
  }
  
  /* Style the checkmark/indicator */
  .SquareLabelContainer .SquareCheckmark:after {
      
    left: 4px;
    bottom: 3px;
    width: 3px;
    height: 8px;

      border: solid var(--black);
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
  }
.ErrorInput {
	background-color: var(--pink);
	color: var(--white);
}

.FormMain{
    padding: 20px 0px 20px 25px;
	color: var(--white);
	font-size: 14px;
	font-family: GothamRounded-Medium;

}
.PriceError {
	text-align: center;
	color: var(--red);
	font-size: 12px;
	margin: 10px 0;
}
.MaxPrice{
	color: var(--black);
	text-align: left;
	margin: 5px 0 0 0;
}
.Form1{
    display: flex;
    flex-direction: row;
}

.Form1_1{
    width: 100%;
	/* border: 2px solid green; */
	align-items: center;
}

.Form2{
	width: 90%;
	margin: auto;
}

.Form3_1{
	width: 50%;
}

.Form1_2{
    width: 50%;
}

.Form1 span{
    display: inline-block;
    width: 118px;
}
/* .Form1_1 span{
	width: 40%;
} */
/* .Form1_1 select{
    width: 260px;
} */

.Form3_1 select{
	width: 260px;
}
.Form3_1 input{
	margin-top: 10px;
	width: 260px;
	box-sizing: border-box;
}
.Form3_1 input[type="checkbox"]{
	width: 40px;
	margin: 0;
	vertical-align: middle;
}

.Form3_1 select:last-of-type{
	width: 170px;
}
.Form1_2 select {
    width: 135px;
}

.Form1{
	justify-content: space-evenly;	
}
.Form2_1 {
    width: 50%;
    margin-bottom: 10px;
	
}
.Form2_2 , .PriceDiv2{
    /* width: 45%; */
    margin-bottom: 15px;
}
.Form2_1 input , .Form2_2 input{
	margin-left: 10px;
	width: 260px;
}
.Form2_1 span , .Form2_2 span , .PriceDiv1 span , .PriceDiv2 span{
    width: auto;
    margin-right: 5px;
}

.Form2_1 input , .Form2_2 input {
    width: 260px;
}


.Form1_1.OtherDetailsTextArea{
	width: 50%;
	margin-top: 30px;
	margin: 0 auto;
	/* border: 2px solid red; */
}
.ExtraInfoContainer{
	width: 88%;
	display: flex;
	align-items: center;
	/* justify-content: space-around; */
	/* border: 2px solid red; */
	box-sizing: border-box;
	margin: 0 auto 20px auto;
	gap: 10px;
}
.BelowContainer{
	width: 90%;
	margin: 0 auto;
	display: flex;
	justify-content: space-around;
align-items: flex-start;
	box-sizing: border-box;
}
.BelowFormContainer{
	width: 55%;
	/* border: 2px solid red; */
}
.BelowFormContainerTwo{
	width: 40%;
	/* border: 2px solid red; */
}
.PointerContainer{
	display: flex;
	justify-content: center;
	align-items: center;
}
.PointerContainer > div{
	width: 25px;
	height: 25px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: var(--blue);
	color: var(--black);
	border-radius: 50%;
}
.ExtraInfoText p{
	font-family: GothamRounded-Medium;
	font-size: var(--l-small);
	opacity: 0.9;
	line-height: 18px;
}
.Form1_1.OtherDetailsTextArea textarea{
	width: 100%;
	height: 120px;
    box-sizing: border-box;
    margin: 0 auto 2px auto;
    color: var(--black);
    font-family: 'GothamRounded-Medium';
    border: 2px solid var(--blue);
    border-radius: 10px;
    padding: 15px 5px 5px 5px;
    text-align: center;
	text-align-last: center;
	outline: none;
}
.ViewI {
	width: 22px;
	vertical-align: middle;
}

.ViewButtonI {
	width: 15px;
	margin-right: 10px;
}
.FormMain select , .FormMain input{
    border:2px solid var(--blue);
    border-radius: 10px;
    padding: 5px;
    text-align: center;
    text-align-last: center;
    -ms-text-align-last: center;
    -moz-text-align-last: center;
}


.TableAdj{
    border-radius: 10px;
    width: 26px;
    height: 26px;
    vertical-align: middle;
 
}
.TableAdj1{
    width: 70px;
    display: -webkit-inline-box;
    vertical-align: middle;
    margin-left: 5px;
}
.TableAdj3{
    width: 80px;
    display: -webkit-inline-box;
    vertical-align: middle;
    margin-left: 5px;
}
.Adjac{
    position: absolute;
    padding-left: 2px;
	margin-top: 15px;
}
.TableHr1{
    height: 2.5px;
    width: 50%;
    margin: 25px auto 20px auto;
    background-color: var(--gray);

}
.TableHr5{
    height: 2.5px;
    width: 50%;
    margin: -20px auto 20px auto;
    background-color: var(--blue);

}
.TableHr3{
	height: 2.5px;
    width: 50%;
    margin: 0px auto 20px auto;
    background-color: var(--gray);
}
.TableHrHide{
	height: 2.5px;
    width: 50%;
    margin: 15px auto 20px auto;
    background-color: var(--gray);
}
.TableHr{
    height: 2px;
    width: 50%;
    margin: 15px auto 5px auto;
 

    background-color: var(--gray);
}

.TableOther{
    text-align: center;
    margin: 20px auto 0px auto;
}
.TableOther1{
    text-align: center;
    margin: 40px auto;
}
.TableOther1 input::placeholder{
}
.SizeWarning{
 	color: var(--m-gray);
}
.Active {
	background-color: var(--green);
	color: var(--black);
	font-family: GothamRounded-Medium;
}
.Price{
    font-family: 'GothamRounded-Bold';
    font-size: 16px;
}
.AdjMain2{
 display: none;
}

.Tablep{
    text-align: center;
	margin: -10px auto 0 auto;
}
.ShippingDescription{
	display: flex;
	flex-direction: column;
}
.container {
	display: block;
	position: relative;
	/* padding-left: 35px; */
	/* margin-bottom: 12px; */
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	right:55px;
	/* top: 13px; */
  }
  
  /* Hide the browser's default checkbox */
  .container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
  }
  /* .OuterContainer{
	background-color: var(--black);
  } */
  /* Create a custom checkbox */
  
  .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 38px;
	width: 38px;
	background-color: var(--white);
	border: 2px solid var(--blue);
	border-radius: 5px;
  }
  
  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
	background-color: var(--white);
  }
  
  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
	background-color: var(--blue);
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
	content: "";
	position: absolute;
	display: none;
  }
  
  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
	display: block;
  }
  
  /* Style the checkmark/indicator */
  .container .checkmark:after {
	left: 10px;
	top: 4px; 
	width: 10px;
	height: 20px;
	border: solid var(--black);
	border-width: 0 5px 5px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
  }
.ShippingDescription p:nth-child(1){
	font-size: var(--l-large);
}
.SellShippingText p{
	color: var(--black);
	opacity: 0.5;
	text-align: center;
	font-size: var(--SS-small);
	font-family: 'GothamRounded-Bold';
    margin: 25px 0 35px 0;
	
}
.ShippingDescription p:nth-child(2),.ShippingDescription p:nth-child(3){
	font-size: var(--S-small);
	margin: 0;
	padding: 0;
}
.TablepCheck p {
    margin:0;
}

.EditFlexMain{
    width: 70%;
    margin: auto;
}

.EditFlex{
    display: flex;
	justify-content: space-evenly;
}
.File{
    border: none;
}
.HardTicket{
    padding: 15px;
    width: 38%;
    margin: -40px auto 5px auto;
    text-align: center;
  
}
.HardTicketPhoto{
    display: flex;
    background-color: var(--white);
    height: 170px;
    justify-content: space-around;
    align-items: center;
    border-radius: 20px;
	border: 2px solid var(--blue);
}
.Browse{
    /* width: 200px; */
	/* border: 2px solid var(--l-gray); */
	padding: 10px 50px;
	border-radius: 8px;
	background-color: var(--blue);
	color: var(--black);
	cursor: pointer;
}
.BrowseHide {
    display: none;
}
.BrowseMedia
{
	/* margin: auto 32px auto 13px; */
	border: 2px solid var(--l-gray);
	padding: 15px;
	border-radius: 8px;
	background-color: var(--blue);
	color: var(--black);
	display: none;
	width: 160px;
	margin: auto;
}


  
.FileNameText{
    margin: 10px 0;
    word-break: break-all;
}
.FileNameText.EText{
	color: var(--white);
}
.FileNameText.HText{
	color: var(--black);
}
.SizeWarning{
	color: var(--m-gray);
}
.EditButtons{

	display: flex;
	align-items: center;
	width: 60%;
	/* border: 2px solid red; */
	justify-content: space-between;
	margin: 0 0 0 20px;
	padding: 20px 0;
 
}
.PaymentSave{
	width: 60%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0px auto;
	padding: 20px 0;
}
.PaymentSaveMargin{
	margin: 20px auto;
	justify-content: center;
}

.PaymentBack{
	width: 70%;
	/* border: 2px solid red; */
	display: flex;
	align-items: center;
	margin: 0 0 0 20px;
}
.PaymentBack button{
	width: 80%;
	background: none;
	color: var(--blue);
	outline: none;
	border: none;
   font-family: 'GothamRounded-Bold';
   font-size: var(--l-large);
}
.ShippingNumber{
	margin: 50px 0 40px 0px!important;
}
.ShippingNumber input{
	border-radius: none!important;
	width: 40%!important;
}
.PaymentBack img{
	width: 20px;
    /* vertical-align: middle; */
	height: auto;
	filter: invert(77%) sepia(68%) saturate(329%) hue-rotate(156deg) brightness(103%) contrast(98%);
	transform: rotate(180deg);
	margin:0px 0px -5px 0px;
}
.SaveButton{
	width: 35%;
	text-align: center;
    padding: 10px 0px;
    border-radius: 15px;
    font-family: 'GothamRounded-Bold';
    margin-right: 0px;
    border: 2px solid var(--blue);
	font-size: var(--l-large);
	background-color: var(--blue);

}
.EditButtons img{
    width: 15px;
    vertical-align: middle;
    /* margin: auto 5px ; */
}


.EditButtons button{
	width: 40%;
	text-align: center;
    padding: 12px 0px;
    border-radius: 15px;
    font-family: 'GothamRounded-Bold';
    margin-right: 0px;
    border: 2px solid var(--blue);
	font-size: var(--l-large);
}
.EditButtons button:last-of-type{
    margin-right: 0;
}
.BackShopContainer{
	/* text-align: left; */
	width: 35%;
	display: flex;
	align-items: center;
	
}

.TicketBackContainer{
	width: 80%;
	margin: 0 0 0 -50px;
}
.BackShopContainer img,.TicketBackContainer img{
	width: 15px;
	transform: rotate(180deg);
	height: auto;
	margin: 0 5px 3px 0px;
}
.BackShopContainer button,.TicketBackContainer button{
	width: 70%;
	background: none;
	border: none;
	outline: none;
	color: var(--black);
}

.ChooseTickets .BackShopContainer{
	width: 100%;
}
.ChooseTickets .BackShopContainer button{
	width: auto;
	padding: 10px 20px;
	display: flex;
	align-items: center;
	font-family: 'GothamRounded-Bold';
}

.TableButton1{
    background-color: var(--white);
}
.TableButton2{
    background-color: var(--blue);
}
.TableButton2.Disabled{
	background-color: var(--l-gray);
}
.TableButton2:hover{
	opacity: 1;
}
.TableButton3{
    background-color: transparent;
    color: var(--blue);
}
.TableButton3 img{
	filter: invert(96%) sepia(25%) saturate(3360%) hue-rotate(152deg) brightness(105%) contrast(96%);
}
.TableButton3:hover{
	opacity: 1;
	background-color: var(--blue);
	color: var(--black);
}
.TableButton3:hover img{
	filter: none;
}


.Shipped{
	text-align: center;
}
.Shipped select{
	width: 150px;
}
.Shipped span{
	margin-right: 10px;
}
.SaleCompletionContainer{
	width: 850px;
	margin: 100px auto;
	border: 2px solid var(--blue);
	border-radius: 20px;
	background-image: url('../../assets/images/icons/newlistingboxbackground.png');
	background-repeat: no-repeat;
	background-position: bottom left;
	background-size: 300px;
}
.SellContSave {
	text-align: center;
	background-image: url("../../assets/images/icons/endpage.png");
	background-position: right;
	background-size: contain;
	background-repeat: no-repeat;
}
.SellContSave p{
	font-family: 'GothamRounded-Bold';
	font-size: var(--x-large);
}
.OuterPopContainer p{
	color: var(--white);
}
/* .Sell p{
	color: var(--blue);
} */
.SellSave img {
	width: 22px;
	vertical-align: middle;
	margin-right: 6px;
}

.Savep {
	font-family: GothamRounded-Medium;
	color: var(--white);
	margin: 20px 0;
	font-size: 16px;
}

.newBlack {
	filter: brightness(0%);
}

.SellSave {
	background-color: var(--blue);
	font-size: 13px;
	font-family: 'GothamRounded-Bold';
	border-radius: 20px;
	margin-bottom: 20px;
	/* width: 200px; */
	border: none;
	padding: 12px 10px;
}

.Right {
	float: right;
} 

.MediaShow{
	display: none;
}
.MediaHide{
	cursor: pointer;
}

.ChooseTickets .EventInfo{
	text-align: center;
	margin: 20px 0;
	color: var(--black);
}
.AllTickets{
	text-align: center;
}
.AllTickets .ImageContainer img{
	height: 150px;
}
.AllTickets .ImageContainer span{
	position: absolute;
	top: 10px;
	right: 20px;
	color: var(--white);
	font-size: 24px;
}
.AllTickets .ImageContainer{
	display: inline-block;
	position: relative;
	margin: 20px;
	border: 2px solid var(--blue);
	border-radius: 15px;
	overflow: hidden;
	background: var(--blue);
}
.ImageContainer .ImageOptions{
	display: flex;
	justify-content: space-evenly;
	padding: 10px 0;
}
.ImageContainer .ImageOptions img{
	height: 20px;
	cursor: pointer;
	padding: 2px;
	display: block;
}
.ImageContainer .ImageOptions .CheckImage{
	border: 1px solid var(--black);
}
.HiddenImage{
	filter: opacity(0);
	-webkit-filter: opacity(0);
	-moz-filter: opacity(0);
}
.AllTickets .ImageContainer img.Selected{
	filter: brightness(0.4);
}
.ChooseTickets .UploadButton{
	margin: 20px;
	text-align: center;
}
.ChooseTickets .UploadButton button{
	background: var(--blue);
	color: var(--black);
	font-family: 'GothamRounded-Medium';
	font-size: 16px;
	padding: 10px 25px;
	border: 0;
	outline: 0;
	border-radius: 10px;
	margin: 5px 10px;
}
.UploadButton label.AddAnotherFile{
	background: var(--blue);
	font-size: 16px;
	font-family: 'GothamRounded-Medium';
	color: var(--black);
	padding: 9px 25px;
	border-radius: 10px;
	display: inline-block;
	cursor: pointer;
}
.UploadButton label.AddAnotherFile input{
	display: none;
}
.FullSizeImage{
	position: fixed;
	z-index: 9999;
	background: rgba(0, 0, 0, 0.7);
	height: 100%;
	width: 100%;
	overflow: auto;
	top: 0;
	left: 0;
}
.FullSizeImage .Container{
	width: 75%;
	margin: 50px auto;
	position: relative;
}
.FullSizeImage .CloseImage{
	position: absolute;
	right: 10px;
	top: 10px;
}
.FullSizeImage .CloseImage img{
	height: 20px;
	cursor: pointer;
}
.FullSizeImage .Container .DisplayImage{
	width: 100%;
}
  .SellMain{
	  width: 900px;
	  /* padding: 30px 0 30px 0; */
  }
  .Sellp {
	width: 85%;
  }
  .MediaHide , .Browse , .PriceError{
  }
  .BrowseMedia{
	  display: block;
  }
  /* .SellHeadp1{
	  font-size: 16px;
  }
  .SellHeadp2 span:first-of-type {
	margin-right: 0px;
  } */
  .MediaShow{
	  display: block;
  }
  .SellHeadp2{
	  font-size:13px
  }
.FormMain{
	font-size: 13px;
    padding: 15px 0;
    overflow-y: scroll;
    overflow-x: hidden;
    color: var(--black);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}
    ::-webkit-scrollbar {
        display: none;
    }
   .AdjMain2{
    display: block;
   }
 
    .Form1 , .Form2 , .Form3_1{
        flex-direction: column;
    
    }
    
.Form1_1 , .Form1_2 , .Form2_1 , .Form2_2 , .Form3_1{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 5px auto;
    text-align: center;
	
}
.Form1_1 input{
	width: 72%;
	box-sizing: border-box;
	margin-bottom: 2px;
	color: var(--black);
	font-family: 'GothamRounded-Medium';
}
.Form1_1 input::placeholder{
}

.AdjMain2{
	text-align: center;
	margin-top: 10px;
}
.Form1_1 > span{
    text-align: left;
    flex-basis: 28%;
}
.Form1_1 > div{
	flex-basis: 72%;
}

.Form1{
	margin: 10px 0;
}
.PriceDiv1   {
  	display: flex;
  	/* justify-content: space-between; */
    width: 100%;
    /* margin: auto; */
	margin: 10px 0 0  0;
    text-align: center;
}
.PriceDiv2{
	display: flex;
	/* justify-content: space-between; */
  width: 100%;
  /* margin: auto; */
  /* margin: 5px 0 0  0; */
  text-align: center;
}

.PriceDiv1 span:nth-child(1) , .PriceDiv2 span:nth-child(1){
    /* text-align: right; */
	width: 70%;
	text-align: left;
}

.AdjMain{
  display: none;
}

.TableButtonView{
    display: inherit;
    margin-bottom: 15px;
}
.TableHrHide{
	display: none;
}
.Agreement{
	color: var(--black);
}
/* .ShippingDescription{
	display: flex;
	justify-content: space-around;
	align-items: center;
} */
/* .ShippingDescription .ContactButton{
	cursor: pointer;
}
.ShippingDescription .ContactButton img{
	height: 20px;
} */
@media(max-width:850px){
	.EditButtons{
		width: 55%;
	}
	.EditButtons button{
		width: 40%;
		font-size: var(--S-small)
	}
	.BackShopContainer{
		width: 85%;
		text-align: left;
	}
	.BackShopContainer button{
		width:50%;
	}
	.BackShopContainer img{
		width: 10px;
		height: auto;
	}
	.EditButtons button img{
		width: 10px;
	}
	.PaymentBack{
		width: 90%;
		margin: 0 0 0 -40px;
	}
	.PaymentBack button{
		width: 100%;
		font-size: 14px;
	}
	.ShippingNumber input{
		border-radius: none!important;
		width: 55%!important;
	}
}
@media(max-width:786px){
	.FormContainer{
		flex-direction: column;
	}
	.FormOneContainer{
		width: 100%;
	}
	.FormTwoContainer{
		width: 100%;
	}
  .PointerContainer{
	width:35px;
	height: 25px;
  }
  .BackShopContainer img{
	width: 10px;
	height: auto;
}
.TicketBackContainer{
	width: 60%;
	margin: 0 0 0 -50px;
}
.TicketBackContainer button{
	width: 100%;
}
.PaymentBack{
	width: 90%;
	margin: 0 0 0 -30px;
}
.PaymentBack button{
	width: 100%;
	font-size: 14px;
}

}
@media (max-width: 1000px){
	.SellMain{
		width: 90%;
	}
	.PriceDiv1 span, .PriceDiv2 span{
		width: none;
		margin: 0;
	}
	.PriceDiv1 span:nth-child(1),.PriceDiv2 span:nth-child(1){
		width: 80%;
	}
	.PriceDiv1 span:nth-child(2),.PriceDiv2 span:nth-child(2){
		width: 20%;
	}
}
@media(max-width: 768px){
	/* .Form1_1{
		width: 90%;
	} */
	.EditFlexMain{
	    width: 90%;
    }
    .EditFlex{
    	justify-content: space-between;
    }
    /* .PriceDiv2, .PriceDiv1{
    	width: 90%;
    } */
	.HardTicket{
	    width: 80%;
	}
	.Form1_1.OtherDetailsTextArea{
		width: 70%;
	}
	.BelowContainer{
		flex-direction: column;

	}
	.BelowContainer .Form1{
		width: 100%;
	}
	.PriceDiv1 span, .PriceDiv2 span{
		width: none;
		margin: 0;
	}
	.PriceDiv1 span:nth-child(1),.PriceDiv2 span:nth-child(1){
		width: 80%;
	}
	.PriceDiv1 span:nth-child(2),.PriceDiv2 span:nth-child(2){
		width: 20%;
	}
	.PaymentBack{
		margin: 0 0 0 0px;
	}
}
@media(max-width: 600px){
	.EditFlex{
		display: grid;
		grid-template-columns: auto auto;
		width: 80%;
		margin: auto;
	}
	.PointerContainer{
		width:40px;
		height: 20px;
	  }
	  .TicketBackContainer{
		width: 70%;
		margin: 0 0 0 0px;
	}
	.ShippingNumber input{
		border-radius: none!important;
		width: 65%!important;
	}
	
}
@media(max-width: 500px){
	.EditFlex{
		width: 90%;
	}
	/* .Form1_1 input{
		width: 200px;
	} */
	.PointerContainer{
		width:45px;
		height: 20px;
	  }
	  .ExtraInfoContainer{
		width: 90%;
	  }
	  .Form1_1.OtherDetailsTextArea{
		width: 80%;
	}
	.Form1_1 input{
		width: 60%;
	}
	.ShippingNumber input{
		border-radius: none!important;
		width: 90%!important;
	}
}
@media(max-width:400px){
	.FormContainer{
		width: 95%;
	}
	.PointerContainer{
		width:50px;
		height: 20px;
	  }
	  .BackShopContainer button{
		font-size: 11px;
	  }
}

.Agreement{
	margin: 30px 20px;
	position: relative;
}
.Agreement label{
	display: flex;
	align-items: center;
	margin: 0 0px;
	font-family: 'GothamRounded-Medium';
}
.Agreement input{
	width: 40px;
	height: 40px;
	margin-right: 10px;
	/* display: none; */
}
  



.Agreement a{
	color: var(--black);
	text-decoration: underline;
}

.WarningMessage{
	text-align: center;
	color: var(--pink);
	font-size: 12px;
}
.Help{
	display: inline-block;
	margin: 0 3px;
	cursor: pointer;
	position: relative;
}
.Help div{
	background: var(--blue);
	color: var(--black);
	padding: 1px 5px;
	border-radius: 50%;
}

.Tooltip{
    visibility: hidden;
    position: absolute;
    width: 150px;
    top: 0px;
    left: 20px;
    background-color: rgba(0,0,0,0.9);
    color: var(--white);
    padding: 5px 20px;
    border-radius: 0px 10px 10px 10px;
    font-size: small;
    border: 1px solid var(--white);
    z-index: 99;
}
.Help:hover .Tooltip{
    visibility: visible;
}

.RemovePicture{
	color: var(--black);
	padding: 5px 20px;
	border: 1px solid var(--black);
	cursor: pointer;
	width: fit-content;
	margin: auto;
}



.PaymentMethod{
/*	position: fixed;
	height: 100%;
	width: 100%;
	backdrop-filter: blur(8px);
	top: 0;
	left: 0;
	z-index: 9999;*/
}
.PaymentMethodContainer{
	margin-top: 50px;
}
.PaymentMethodContainer .WrapBox{
	width: 80%;
	margin: auto;
	padding: 0 20px;
	color: var(--white);
	display: flex;
	/* justify-content: space-between; */
	/* align-items: center; */
}
.PaymentMethodContainer .WrapBox > div{
	font-family: 'GothamRounded-Bold';
	padding-top: 10px;
	width: 50%;
	color: var(--black);
}
.PaymentMethodContainer .WrapBox > form{
	width: 50%;
}
.Tagline{
	margin: 10px 0 0 0;
	font-size: var(--S-small);
	font-family: 'GothamRounded-Medium';

}
.PaymentMethodContainer .InputMethod select{
	width: 210px;
	padding: 5px 10px;
	background: var(--black);
	color: var(--white);
	border: 2px solid var(--blue);
	border-radius: 20px;
	margin: 15px 0;
}


.PaymentMethodContainer .InputMethod input{
	width: 70%;
	padding: 5px 10px;
	background: var(--white);
	border: 2px solid var(--blue);
	border-radius: 5px;
	box-sizing: border-box;
	border-radius: 20px;
}
.Tagline2{
	color: var(--black);
	font-family: 'GothamRounded-Bold';
	margin: 20px 0 0 0;
	font-size: var(--S-small);
}
.PaymentMethodContainer.Tagline2 input{
	opacity: 1;
}
.PaymentMethodContainer .InputMethod input:disabled{
	background: var(--gray);
}

.PaymentMethodContainer .InputMethod label{
	display: flex;
	
	align-items: center;
	margin: 0px 0 10px 0;
}

.PaymentMethodContainer .InputMethod label input:nth-child(1){
	flex-basis: 20%;
	height: 20px;
	width: 20px;
}
.PaymentMethodContainer .InputMethod label input:nth-child(2){
	flex-basis: 100%;
}
.PaymentMethodContainer .ActionButtons{
	text-align: center;
	margin: 20px 0;
}
.PaymentMethodContainer .ActionButtons button{
	margin: 5px 10px;
	background: var(--blue);
	color: var(--black);
	border: 2px solid var(--blue);
	padding: 7px 25px;
	border-radius: 10px;
	font-family: 'GothamRounded-Bold';
}
.PaymentMethod .TitleTextPayment{
	text-align: center;
	margin-top: 30px;
}
.PaymentMethod .TitleTextPayment > div:nth-child(1){
	color: var(--blue);
	font-family: 'GothamRounded-Bold';
	font-size: 18px;
}
.PaymentMethod .TitleTextPayment > div:nth-child(2){
	color: var(--white);
	font-family: 'GothamRounded-Medium';
	font-size: 14px;
}

.PaymentMethodContainer .ActionButtons button:hover{
	opacity: 1;
	background: transparent;
	color: var(--blue);
}
.PaymentMethodContainer .ActionButtons button:nth-child(2):hover{
	background: var(--blue);
	color: var(--black);
}
.PaymentMethodContainer .ActionButtons button:nth-child(2){
	background: transparent;
	color: var(--blue);
	border: 2px solid var(--blue);
}
.AddPaymentMethod{
	margin: 20px 0;
}
.PhoneContainer{
	width: 50%!important;

}
.PhoneContainer input{
	border-radius: 20px!important;
}
.AddPaymentMethod div{
	cursor: pointer;
	color: var(--blue);
	font-size: 16px;
	font-family: 'GothamRounded-Medium';
}

/* .PhoneNumberField{
	display: flex;
	margin-bottom: 8px;
	position: relative;
	
} */
.PaymentMethodContainer .PhoneNumberField input{
	margin: 0;
	height: 29px;
	padding-left: 70px;
	/* margin: 20px 0 10px 0; */
}
.PaymentMethodContainer .PhoneNumberField > div{
	flex-basis: 20%;
	/* margin: 20px 0 10px 0; */
}
.PhoneText{
	margin: 25px 0 0 0;
}

.PhoneInput{
	margin:23px 0 0 7%;
}


.ShippingOptions{
	text-align: left;
	margin: 30px;
	color: var(--white);
}
.ShippingMethod{
	padding: 40px 40px 40px 100px;
    position: relative;
	margin: 30px 0;
	border: 2px solid var(--black);
	color: var(--black);
	border-radius: 10px;
	
}
.ShippingMethod img{
	height: 25px;
}
.ShippingMethod .Logo.Klarna img{
	height: 30px;
}
label.ShippingMethod{
	display: block;
}
.ShippingMethod .Logo{
	position: absolute;
	right: 50px;
	top: 40px;
}

/* Hide the browser's default checkbox */
.ShippingMethod input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.ShippingMethod .Checkmark {
  position: absolute;
  top: 40px;
  left: 50px;
  height: 20px;
  width: 20px;
  border: 1px solid var(--blue);
  background-color: var(--white);
  border-radius: 50%;
}
.ShippingMethod .Heading{
	font-size: 18px;
	font-family: 'GothamRounded-Bold';
	color: var(--black);
	
}
/* On mouse-over, add a grey background color */
.ShippingMethod:hover input ~ .Checkmark {
  background-color: var(--blue);
}
.TransitionContainer{
	width: 25%;
	display: flex;
	align-items: center;
	margin: 20px auto 30px auto;
	justify-content: space-between;
}
.StepOneContainer,.StepTwoContainer,.StepThreeContainer{
	width: 10%;
}
.StepNumberOneImageContainer{
	width: 50px;
	height: auto;
}
.StepNumberOneImageContainer img{
	width: 100%;
	height: auto;
}
.FirstThreeDotsContainer{
	width: 30%;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 25px;
}
.FirstThreeDotsContainer div{
	background-color: var(--black);
	color: var(--black);
	width: 8px;
	height: 8px;
	border-radius: 50%;
	/* border: 2px solid blue; */
	opacity: 1;
	margin: 30px 0 0 0;
}

.StepTwoImageContainer{
	width: 50px;
	height: auto;
}
.StepTwoImageContainer img{
	width: 100%;
	height: auto;
}
.SecondThreeDotsContainer{
	width: 30%;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 25px;
}
.HelpTwoForm{
	width: 38%;
	/* border: 2px solid red; */
	/* text-align: right; */
	display: flex;
	margin: 20px 0 0 -5px;
	justify-content: space-between;
}
.SecondThreeDotsContainer div{
	background-color: var(--black);
	color: var(--black);
	width: 7px;
	height: 7px;
	border-radius: 50%;
	margin: 30px 0 0 0;
	opacity:0.5;
}
.StepThreeImageContainer{
	width: 50px;
	height: auto;
}
.StepThreeImageContainer img{
	width: 100%;
	height: auto;
}
/* When the checkbox is checked, add a blue background */
/* .ShippingMethod input:checked ~ .Checkmark {
  background-color: var(--blue-dark);
  border: 1px solid var(--white);
} */
/* .ShippingMethod .Tagline, .ShippingMethod .Tagline2{
	display: none;
}
.ShippingMethod input:checked ~ .Tagline{
	display: block;
}
.ShippingMethod input:checked ~ .Tagline2{
	display: block;
} */
/* Create the checkmark/indicator (hidden when not checked) */
/* .Checkmark:after {
  content: "";
  position: absolute;
  display: none;
} */

/* Show the checkmark when checked */
/* .ShippingMethod input:checked ~ .Checkmark:after {
  display: block;
} */

.FirstDotsBlue div{
	background-color: var(--blue);
	color: var(--blue);
}
.SecondDotsBlue div{
	background-color: var(--blue);
	color: var(--blue);
	width: 8px;
	height: 8px;
	border-radius: 50%;
	/* border: 2px solid blue; */
	opacity: 1;
	margin: 30px 0 0 0;
}
.MakeLessOpacity{
	opacity: 0.5;
}
@media(max-width:1300px){
	.TransitionContainer{
		width: 35%;
	}
}
@media(max-width:1000px){
	.TransitionContainer{
		width: 45%;
	}
	.BookShopContainer button{
		font-size:var(--S-small);
	}
}
@media (max-width: 768px){
	/* .PaymentMethodContainer .WrapBox{
		display: block;
		text-align: center;
		width: 100%;
		box-sizing: border-box;
	}
	.PaymentMethodContainer .WrapBox > div{
		width: 70%;
		margin: auto;
	}
	.PaymentMethodContainer .WrapBox > form{
		width: 70%;
		margin: auto;
	} */
	.TransitionContainer{
		width: 55%;
	}
	.HelpTwoForm{
		width:35%;
	}
  .Agreement {
	font-size: 14px;
  }
  .Agreement input{
	width: 40px;
	height:40px;
  }
  .PaymentMethodContainer .WrapBox{
	width: 90%;
  }
  .ShippingMethod{
	padding: 40px 40px 40px 80px;
  }
  /* .PhoneInput{
	margin: 23px 0 0 28px;
} */
 
  .PaymentMethodContainer .PhoneNumberField input{
	margin: 0;
	height: 29px;
	padding-left: 85px;
	/* margin: 20px 0 10px 0; */
}
}
@media(max-width:650px){
	.BackShopContainer button{
		width: 70%;
	}
	.Agreement input{
		width: 60px;
		height:60px;
	  }
	.PaymentMethodContainer .WrapBox{
		width: 95%;
	}

	  .PaymentMethodContainer .WrapBox > form{
		width: 65%;
		/* margin: auto; */
	}
	.PaymentMethodContainer .WrapBox > div{
		width: 35%;
		/* margin: auto; */
	}
	.PaymentInput{
		padding-left: 70px!important;
	}
	/* .PhoneInput{
		margin: 23px 0 0 48px;
	} */
	.TicketBackContainer{
		width: 80%;
		margin: 0 0 0 -30px;
	}
	.PaymentBack{
		width: 90%;
		margin: 0 0 0 -10px;
	}
	.PaymentBack button{
		width: 100%;
		font-size: 13px;
	}
	.PhoneInput{
		margin:23px 0 0 10%;
	}
	.PaymentMethodContainer .WrapBox{
		padding: 0;
	}
	}
@media(max-width:600px){
	.TransitionContainer{
		width: 70%;
	}
	.HelpTwoForm{
		width:50%;
	}
	.ShippingOptions{
		margin: 30px 20px 30px 20px;
	}
	.ShippingMethod{
		padding: 40px 20px 40px 80px;
	}
	.PaymentMethodContainer .PhoneNumberField input{
		margin: 0;
		height: 29px;
		padding-left: 150px;
		text-align: left;
		/* margin: 20px 0 10px 0; */
	}
	.PaymentMethodContainer .PhoneNumberField input::placeholder{
		/* margin: 0;
		height: 29px;
		padding-left: 150px; */
		text-align: left;
		transform: translateX(-15%);
		/* margin: 20px 0 10px 0; */
	}
	.PaymentBack button{
		font-size: var(--S-small);
	}
	.PaymentBack img{
		margin: 0 0px 0 0;
	}
	.TicketBackContainer{
		width: 100%;
		margin: 0 0 0 -10px;
	}
	.PaymentBack{
		width: 90%;
		margin: 0 0 0 -10px;
	}
	.PaymentBack button{
		width: 100%;
		font-size: 13px;
	}
	.PaymentBack button img{
		margin: 0px 0 -5px 0;
	}
.PhoneInput{
	width: 120%;
}
}
@media(max-width:500px){
	.ShippingOptions{
		margin: 30px 20px 30px 20px;
	}
	.ShippingMethod{
		padding: 40px 20px 40px 80px;
	}
	.checkmark{
		width: 30px;
		height: 30px;
	}
	.Agreement input{
		width: 70px;
		height:70px;
	  }
	  .Agreement {
		font-size: 12px;
	  }
	  .TransitionContainer{
		width: 80%;
	}
	.ShippingMethod{
		padding: 40px 20px 40px 70px;
	  }
	
	.PaymentMethodContainer .InputMethod input{
		width: 70%!important;
		padding: 5px 10px 5px 100px;
		background: var(--white);
		border: 2px solid var(--blue);
		border-radius: 5px;
		box-sizing: border-box;
		border-radius: 20px;
	}
	.InputMethod input{
		width: 70%!important;
		
	}
	.PaymentBack button img{
		margin: 0px 3px -15px 0;
	}
	.PaymentMethodContainer .PaymentRadio input{
		width: 70%!important;
		padding: 5px 10px 5px 10px;
		background: var(--white);
		border: 2px solid var(--blue);
		border-radius: 5px;
		box-sizing: border-box;
		border-radius: 20px;
	}
}
@media (max-width: 450px){

	/* .PaymentMethodContainer .WrapBox > div{
		width: 100%;
	}
	.PaymentMethodContainer .WrapBox > form{
		width: 100%;
	}
	.TransitionContainer{
		width: 90%;
	} */
	.PaymentMethodContainer .InputMethod input{
		width: 20%;
	}
	.HelpTwoForm{
		width:52%;
	}
	.EditButtons button{
		width: 50%;
	}
	
	.BackShopContainer button{
		width: 80%;
	}
	.BackShopContainer img{
		width: 15px;
	}
	.ShippingOptions{
		margin: 30px 10px 30px 10px;
	}
	.TransitionContainer{
		width: 90%;
	}
	/* .ShippingMethod{
		padding: 40px 10px 40px 10px;
	} */
	.Agreement input{
		width: 70px;
		height:70px;
	  }
	  .PaymentMethodContainer .PhoneNumberField input{
		margin: 0;
		height: 29px;
		padding-left: 120px;
		/* margin: 20px 0 10px 0; */
	}
	.PaymentMethodContainer .PhoneNumberField input::placeholder{
		/* margin: 0;
		height: 29px;
		padding-left: 150px; */
		text-align: left;
		transform: translateX(-50%);
		/* margin: 20px 0 10px 0; */
	}
	.PaymentInput{
		width: 80%!important;
	}
	.EditButtons{
		width: 90%;
		margin:auto;
	}
	.BackShopContainer{
		width: 40%;
		border: 2px solid var(--blue);
		border-radius: 15px;
		text-align: center;
		display: flex;
		justify-content: center;
		padding: 0px 0;
	}
	
	.BackShopContainer img{
		margin: 0 0px 0 0;
	}
	.EditButtons button{
		width: 40%;
	}
	.BackShopContainer button{
		font-size:11px;
		width:80%;
	}
	.TicketBackContainer{
		width: 40%;
		margin: 0 0 0 -5px;
	}
	.TicketBackContainer button{
		font-size: 9px;
	}
	.PaymentSave{
		width: 90%;
		margin:20px auto 30px auto ;

		
	}
	.PaymentBack{
		margin: 0 0 0 -10px;
		width: 60%;
		border: 2px solid var(--blue);
		padding: 10px 0;
		border-radius: 15px;
	}
	.PaymentBack button{
		font-size: 12px;
	}
	.PaymentBack button img{
		margin: 0 0 -2px 0;
		width: 12px;
	}
}

@media (max-width:370px) {
	.Agreement label{
		margin: 0 10px;
	}

    /* .EditButtons button{
        width: 130px;
        padding: 10px 5px;
    }
    .EditButtons img{
       margin-right: 1px;
    } */
    .Form1_1{
    	width: 100%;
    }
    .SellMain form{
    	font-size: 12px;
    }
    .PriceDiv2, .PriceDiv1{
    	width: 100%;
    }
	.PriceDiv1 span:nth-child(1),.PriceDiv2 span:nth-child(1){
		width: 70%;
	}
	.PriceDiv1 span:nth-child(2),.PriceDiv2 span:nth-child(2){
		width: 30%;
	}
	.PointerContainer{
		width:50px;
		height: 20px;
	  }

	  .StepOneContainer,.StepTwoContainer,.StepThreeContainer{
		width: 8%;
	  }
	  .FirstThreeDotsContainer{
		width: 20%;
		gap: 15px;
	 
		/* border: 2px solid red; */
	  }
	  .StepOneContainer{
		margin-left: 5px;
	  }
	  .StepThreeContainer{
		margin-right: 10px;
	  }
	  .SecondThreeDotsContainer{
		width: 20%;
		gap: 15px;
		margin-left: 10px;
	  }
	  .BackShopContainer{
		width: 50%;
	}
	.BackShopContainer img{
		margin: 0 5px 0 0;
	}
	.Agreement input{
		width: 70px;
		height:70px;
	  }
	  .Agreement {
		font-size: 12px;
	  }
	  .PaymentBack button{
		font-size: 10px;
	}
	 
}




.Payment.PaymentLoader{
	position: fixed;
	z-index: 99999;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.5);
	height: 100vh;
	width: 100vw;
	text-align: center;
}
.Payment.PaymentLoader .Container.WrapBox.WaitBox{
	margin-top: 100px;
}

.Payment{
	width: 65%;
}
.Payment .Container.WrapBox{
	margin: 0;
	width: 95%;
	background: var(--black);
	padding: 30px;
	border: 2px solid var(--blue);
}
.Payment .Container.WrapBox.WaitBox{
	display: inline-block;
	width: auto;
	border-radius: 20px;
}
.WaitBox .Title{
	color: var(--blue);
}
.WaitBox .Heading{
	color: var(--blue);
	font-family: 'GothamRounded-Medium';
	font-size: 16px;
	margin: 10px 0;
}
.WaitBox .SubHeading{
	color: var(--white);
	font-family: 'GothamRounded-Light';
	font-size: 14px;
}
.LoadingIcon img{
	height: 40px;
	margin: 20px 0;
	animation: Rotate linear infinite 2s;
}

@keyframes Rotate{
	from {
		transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
	}
}
.LoadingIcon{
	margin-top: 10px;
}
