.PaymentBackground{
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	backdrop-filter: blur(8px);
	height: 100%;
	width: 100%;
	text-align: center;
	overflow: auto;
}
@-moz-document url-prefix() {
    .PaymentBackground{
      background: rgba(0,0,0,0.8);
    }
}
.Radiocontainer {
	display: block;
	position: relative;
	padding-left: 50px;
	margin-bottom: 12px;
	/* cursor: pointer;
	font-size: 22px; */
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	}
  
  /* Hide the browser's default radio button */
  .Radiocontainer input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
  }
  
  /* Create a custom radio button */
  .checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 25px;
		width: 25px;
		background-color: #eee;
		border-radius: 50%;
		border: 2px solid var(--blue);
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
	}
  /* On mouse-over, add a grey background color */
  .RadioContainerLabel:hover input ~ .checkmark {
		background-color: #ccc;
  }
  
  /* When the radio button is checked, add a blue background */
  .Radiocontainer input:checked ~ .checkmark {
		background-color: var(--blue-dark);
		border: 1px solid var(--white);
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
	  left: 9px;
    bottom: 7px;
    width: 3px;
    height: 8px;
    border: solid var(--white);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  /* Show the indicator (dot/circle) when checked */
  .Radiocontainer input:checked ~ .checkmark:after {
		display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .Radiocontainer .checkmark:after {
	  /*top: 9px;
	  left: 9px;
	  width: 8px;
	  height: 8px;
	  border-radius: 50%;*/
  }
  .HideCheckMark{
	display: none;
  }
 
.HidePricing{
	display: none;
}
  .MainContainer{
	display: flex;
	margin: 0 0 0 5px;
	
  }
  .FirstContainer{
	width: 85%;
  }
  .SecondContainer{
	width: 15%;
	font-size: 22px;
	font-family: 'GothamRounded-Bold';
	margin: 10px 0 10px 0px;
  }
  .FreeContainer{
	font-size: 18px;
  }
.Radiocontainer>div>div:nth-child(1){
	text-align: left;
	color: var(--blue);
	font-family: 'GothamRounded-Bold';
	font-size: 22px;
	
}
.Radiocontainer>div{
	/* width: 90%; */
}
.Radiocontainer>div>div:nth-child(2){
	text-align: left;
	font-family: 'GothamRounded-Medium';
    margin: -10px 0 0 0;
	}
	
.ChangeViewContainer>div>div:nth-child(1){
	color: var(--black);
}
.ChangeViewContainer>div>div:nth-child(2){
	color: var(--black);
	/* font-family: 'GothamRounded-Bold'; */
	font-family: 'GothamRounded-Medium';
}
.ChangeContainer{
	color: var(--black);

}

.Payment.PaymentLoader{
	position: fixed;
	z-index: 99999;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.5);
	height: 100vh;
	width: 100vw;
	text-align: center;
}
.Payment.PaymentLoader .Container.WrapBox.WaitBox{
	margin-top: 100px;
}

.Payment{
	width: 65%;
}
.Payment .Container.WrapBox{
	margin: 0;
	width: 95%;
}
.BackgroundContainer{
	margin-top: 10px;
	padding-top: 50px;
	padding-bottom: 50px;
	max-width: var(--max-size);
	margin: auto;
}
.Cart .Container.WrapBox{
	margin: 0;
	width: 95%;
}
.Cart{
	width: 35%;
}
.Payment, .Cart{
	display: inline-block;
	vertical-align: middle;
}
.TicketOwner{
	margin: 20px 0;
}
.TicketOwner .OwnerImage img{
	height: 50px;
	vertical-align: middle;
}
.TicketOwner .OwnerInfo,
.TicketOwner .OwnerImage{
	display: inline-block;
	margin-right: 20px;
	vertical-align: middle;
}
.TicketOwner .OwnerInfo{
	font-family: 'GothamRounded-Medium';
	text-align: left;
	font-size: 14px;
}
.TicketOwner .OwnerInfo div:nth-child(1){
	color: var(--black);
}
.TicketOwner .OwnerInfo div:nth-child(2){
	color: var(--black);
}
.TicketOwner .OwnerInfo div:nth-child(2) img{
	height: 15px;
	vertical-align: middle;
	filter: brightness(0);
}


.Ticket{
	border: 2px solid var(--blue);
	border-radius: 20px;
	background: url('../../../assets/images/icons/ticket-inside-box.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: right 10px;
	text-align: left;
	position: relative;
}
.Ticket .Title{
	color: var(--black);
	background: var(--blue);
	display: block;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	font-size: 12px;
	padding: 3px 0;
	letter-spacing: 2px;
	font-family: 'GothamRounded-Bold';
	text-align: center;
	margin-top: -1px;
}


.TicketInfo{
	display: inline-block;
	margin: 10px 0 10px 20px;
	padding-right: 20px;
	border-bottom: 1px solid var(--black);
}
.TicketInfo div{
	margin: 8px 0;
}
.TicketInfo .EventName{
	font-family: 'GothamRounded-Bold';
}
.TicketInfo .EventLocation{
	font-family: 'GothamRounded-Book';
	font-size: 14px;
}

.TicketTimings{
	margin: 10px 0 10px 20px;
}
.TicketTimings div{
	font-size: 14px;
	margin: 5px 0;
}
.TicketPrice{
	margin: 20px;
	text-align: left;
	font-size: 14px;
}
.TicketPrice > div{
	display: inline-block;
	vertical-align: middle;
}
.TicketPrice .Price{
	font-size: 18px;
	font-family: 'GothamRounded-Bold';
	position: absolute;
	right: 20px;
}
.BuyButton{
	margin: 20px 0;
}
.BuyButton button{
	padding: 15px 40px;
	color: var(--black);
	background: var(--blue);
	border-radius: 10px;
	outline: none;
	border: 0;
	font-family: 'GothamRounded-Bold';
}
.ShipperInfo{
	margin: 20px 0;
	font-size: 14px;
	font-family: 'GothamRounded-Medium';
}
.Agreement{
	width: 70%;
	font-size: 12px;
	text-align: center;
	margin: auto;
}
.Agreement a{
	color: var(--black);
	text-decoration: underline;
}
.Quantity{
	text-align: center;
	color: var(--black);
	position: absolute;
	right: 35px;
	bottom: 65px;
}
.Quantity .Number{
	font-size: 28px;
	font-family: 'GothamRounded-Bold';
}
.Quantity .Number div{
	font-size: 14px;
}
/* Buy Tickets */



/* Payment */
.Payment{
	position: relative;
}
.Payment .ProgressBar{
	font-size: 48px;
	color: var(--blue);
	font-family: 'GothamRounded-Medium';
	padding: 10px 50px;
}
.Payment .ProgressBar .Close{
	position: absolute;
	left: 50px;
	top: 50px;
	cursor: pointer;
	filter: brightness(0);
}
.Payment .ProgressBar .Progress div{
	display: inline-block;
}
.Payment .ProgressBar .Progress div img{
	height: 80px;
}
.Payment .ProgressBar .Progress .StepOneGap{
	color: var(--black);
	opacity: 0.5;
	vertical-align: super;
	margin: 0 50px;
	letter-spacing: 20px;
}
.Payment .ProgressBar .Progress .StepOneGap.GapFill{
	opacity: 1;
}
.Payment .Title{
	color: var(--black);
	font-family: 'GothamRounded-Medium';
	font-size: 20px;
	display: inline-block;
	padding: 0 30px 10px 30px;
	border-bottom: 1px solid var(--black);
	margin: 20px 0;
}
.Payment .Title .TitleSub{
	color: var(--black);
	font-size: 14px;
	font-family: 'GothamRounded-Book';
}
.WrapBox{
	background: var(--white);
	border: 0px solid var(--blue);
	border-radius: 20px;
	color: var(--black);
	padding: 20px;
	margin: 20px auto;
	width: 80%;
}
.ChangeColorContainer{
	background: var(--white);
	border: 2px solid var(--black);
}
.ShippingCharge {
  display: block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: left;
  margin: 20px 0;
  padding: 0 50px;
}
.ShippingCharge .LabelText{
	margin-top: 20px;
}
.ShippingCharge .LabelText .PostCompanyName, .ShippingCharge .LabelText .ShippingPrice{
	font-size: 22px;
	font-family: 'GothamRounded-Bold';
	margin: 10px 0;
}
.ShippingCharge .LabelText div:nth-child(2){
	font-size: 16px;
	font-family: 'GothamRounded-Light';
}
.ShippingCharge .LabelText .ShippingPrice{
	position: absolute;
	right: 0;
	top: 0;
}
/* Hide the browser's default checkbox */
.ShippingCharge input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.ShippingCharge .Checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  border: 1px solid var(--blue);
  background-color: var(--white);
  
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.ShippingCharge:hover input ~ .Checkmark {
  background-color: var(--blue);
}

/* When the checkbox is checked, add a blue background */
.ShippingCharge input:checked ~ .Checkmark {
  background-color: var(--blue-dark);
}

/* Create the checkmark/indicator (hidden when not checked) */
.Checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.ShippingCharge input:checked ~ .Checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.ShippingCharge .Checkmark:after {
  left: 11px;
  top: 6px;
  width: 5px;
  height: 10px;
  border: solid var(--white);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.NextButton{
	text-align: center;
	margin-top: 30px;
}
.NextButton button{
	padding: 12px 50px;
	color: var(--black);
	background: var(--blue);
	border-radius: 10px;
	outline: none;
	border: 0;
	font-size: 18px;
	font-family: 'GothamRounded-Medium';
  cursor: pointer;
  margin: 0 30px;
}
/* payment */

.Cart{
}
.Cart .TicketOwner > div{
	display: inline-block;
}
.Cart .TicketOwner .OwnerInfo img{
	vertical-align: middle;
}
.Cart .Title{
	color: var(--black);
	font-family: 'GothamRounded-Medium';
	font-size: 20px;
	display: inline-block;
	padding: 10px 100px;
	border-bottom: 1px solid var(--black);
	margin-top: 20px;
}
.TicketDetails{
	border: 2px solid var(--black);
	padding: 5px 20px;
	display: inline-block;
	border-radius: 20px;
	width: 100%;
	margin: auto;
	box-sizing: border-box;
}
.TicketDetails .TicketType{
	color: var(--black);
	font-family: 'GothamRounded-Medium';
}
.TicketDetails .EventInfo{
	margin: 10px;
	font-size: 14px;
	border-bottom: 2px solid var(--black);
	padding: 0px;
}
.TicketDetails .EventInfo div{
	margin: 10px 0;
}
.TicketDetails .EventInfo .EventName{
	font-family: 'GothamRounded-Bold';
}
.TicketDetails .TicketTimings{
	margin-left: 0;
}
.Cart .PriceDetails{
	margin: 20px auto;
	border-bottom: 2px solid var(--black);
	padding-bottom: 15px;
}
.Cart .PriceDetails .PriceBlock{
	font-size: 14px;
	font-family: GothamRounded-Medium;
	margin: 10px;
	text-align: left;
	position: relative;
	display: flex;
	justify-content: space-between;
}
.PriceBlock{
	white-space: nowrap;
}
.Cart .PriceDetails .PriceBlock > div{
	display: inline-block;
}
.Cart .PriceDetails .PriceBlock div:nth-child(2){
	float: right;
}
.Cart .PriceDetails .PriceBlock.TotalPrice{
	font-size: 16px;
	margin-top: 20px;
}
.PriceSubBlock{
	color: var(--gray);
	padding-left: 20px;
	box-sizing: border-box;
}
.PriceToggleShow{
	height: 20px;
	vertical-align: middle;
	margin: 0 5px;
	filter: brightness(0);
}
.Cart .SessionTime{
	font-size: 14px;
	font-family: 'GothamRounded-Medium';
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}
.Cart .SessionTime > div{
	margin-bottom: 10px;
}
.Cart .SessionTime img{
	height: 40px;
	vertical-align: middle;
	display: inline-block;
	margin-right: 10px;
	filter: brightness(0);
}
.Cart .SessionTime .Time{
	display: inline-block;
	vertical-align: middle;
}
.Cart .SessionTime .Time span{
	font-size: 24px;
	color: var(--blue);
	margin-right: 5px;
}




.PaymentMethods{
	text-align: left;
	margin: 30px;
}
.PaymentMethod{
	padding: 40px;
	padding-left: 100px;
	position: relative;
	margin: 30px 0;
	border: 2px solid var(--black);
	border-radius: 10px;
}
.PaymentMethod img{
	height: 25px;
}
.PaymentMethod .Logo.Klarna img{
	/* height: 30px; */
}
label.PaymentMethod{
	display: block;
}
.PaymentMethod .Logo{
	position: absolute;
	right: 50px;
	top: 40px;
	background-color: var(--white);
	width: 70px;
	height: auto;
	padding: 10px;
	border-radius: 10px;
	text-align: center;
	border: 2px solid var(--black);
}
.PaymentLogos{
	position: absolute;
	right: 50px;
	top: 30px;
	/* border: 2px solid red; */
	width: 35%;
	display: flex;
	justify-content: space-between;
	
}
.VisaContainer,.AmericanContainer,.MasterCardContainer{
	background-color: var(--white);
	width: 70px;
	height: 30px;
	padding: 10px;
	border-radius: 10px;
	text-align: center;
	border: 2px solid var(--black);
	display: flex;
	align-items: center;
	justify-content: center;
}

.Payment .Logo img,.VisaContainer img,.MasterCardContainer img{
	width: 100%;
	object-fit:contain ;
	height: auto;
}
.AmericanContainer img{
	width: 100%;
	object-fit:contain ;
	height: 70px;
}
/* Hide the browser's default checkbox */
.PaymentMethod input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.PaymentMethod .Checkmark {
  position: absolute;
  top: 40px;
  left: 50px;
  height: 20px;
  width: 20px;
  border: 1px solid var(--black);
  background-color: var(--white);
  border-radius: 50%;
}
.PaymentMethod .Heading{
	font-size: 18px;
	font-family: 'GothamRounded-Bold';
}
/* On mouse-over, add a grey background color */
.PaymentMethod:hover input ~ .Checkmark {
  background-color: var(--blue-dark);
}

/* When the checkbox is checked, add a blue background */
.PaymentMethod input:checked ~ .Checkmark {
  background-color: var(--blue-dark);
  border: 1px solid var(--white);
}

/* Create the checkmark/indicator (hidden when not checked) */
.Checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
	top: 4px; 
	width: 3px;
	height: 8px;
	border: solid var(--white);
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

/* Show the checkmark when checked */
.PaymentMethod input:checked ~ .Checkmark:after {
  display: block;
}



  /* Style the checkmark/indicator */
  .Newsletter .PaymentMethod .checkmark:after {
	left: 10px;
	top: 4px; 
	width: 10px;
	height: 20px;
	border: solid var(--black);
	border-width: 0 5px 5px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
  }



.Agreement .PaymentMethod{
	padding-left: 0px;
	padding-right: 0px;
}
.Agreement{
	width: auto;
	margin: 30px;
}
.Agreement.Newsletter label{
	margin-top: 0;
	padding-top: 0;
}

/* Hide the browser's default checkbox */
.Agreement input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  visibility: hidden;
}

/* Create a custom checkbox */

/* On mouse-over, add a grey background color */
.Agreement:hover input ~ .Checkmark {
  background-color: var(--white);
}

/* When the checkbox is checked, add a blue background */
.Agreement input:checked ~ .Checkmark {
  background-color: var(--blue-dark);
}

/* Create the checkmark/indicator (hidden when not checked) */
.Checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.Agreement input:checked ~ .Checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.Agreement .Checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid var(--white);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.Agreement label div{
	margin-left: 10px;
}

.Agreement label.PaymentMethod{
	display: flex;
	justify-content: center;
	align-items: center;
}
.Agreement .Checkmark {
  position: relative;
  left: unset;
  top: unset;
  height: 20px;
  width: 20px;
  border: 1px solid var(--black);
  background-color: var(--white);
  border-radius: 5px;
  display: block;
}
.NewsletterText{
	width: fit-content;
}

.PaymentMethod .Tagline{
	display: block;
	font-size: 14px;
	font-family: 'GothamRounded-Light';
	margin-top: 5px;
}
.PaymentMethod .Tagline2{
	display: none;
	margin-top: 10px;
}

.Agreement .PaymentMethod{
	border: 0;
	padding-bottom: 0;
}
.NextButton.MakePayment{
	margin-top: 0px;
}
.ErrorMessage{
	color: var(--red);
	margin: 20px 0;
}

.Payment .Container.WrapBox.WaitBox{
	display: inline-block;
	width: auto;
	border-radius: 20px;
}
.WaitBox .Heading{
	color: var(--black);
	font-family: 'GothamRounded-Medium';
	font-size: 16px;
	margin: 10px 0;
}
.WaitBox .SubHeading{
	color: var(--black);
	font-family: 'GothamRounded-Light';
	font-size: 14px;
}
.LoadingIcon img{
	height: 40px;
	margin: 20px 0;
	animation: Rotate linear infinite 2s;
	filter: brightness(0);
}

@keyframes Rotate{
	from {
		transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
	}
}
.LoadingIcon{
	margin-top: 10px;
}

.Payment .PaymentStatus .Close{
	position: absolute;
	left: 50px;
	top: 30px;
	cursor: pointer;
	font-size: 48px;
	color: var(--black);
	font-family: 'GothamRounded-Medium';
}

.PaymentStatusMessage{
	font-size: 14px;
	font-family: 'GothamRounded-Book';
	margin: 20px;
}

.Payment .PaymentStatus .TicketDetails{
	padding: 10px 50px;
	border-left: 20px solid var(--black);
	border-right: 20px solid var(--black);
}

.Payment .PaymentStatus .TicketDetails .TicketType{
	font-size: 14px;
}
.Payment .PaymentStatusImage{
	margin: 20px 0;
}
.Payment .PaymentStatusImage img{
	height: 200px;
	filter: brightness(0);
}
.Payment .PaymentStatusFailImage{
	position: absolute;
	right: 50px;
	top: 40px;
}
.Payment .PaymentStatusFailImage img{
	height: 70px;
}
.Disclaimer{
	font-size: 14px;
	margin: 20px 0;
}
.Payment .PaymentStatusButtons button{
	padding: 15px 40px;
	color: var(--black);
	background: var(--blue);
	border-radius: 10px;
	outline: none;
	border: 0;
	font-size: 14px;
	font-family: 'GothamRounded-Bold';
	margin: 0px 20px;
}

.MobileCart{
	display: none;
	position: fixed;
	width: 100%;
	text-align: center;
	color: var(--white);
	background: var(--black);
	top: 0;
	left: 0;
	z-index: 9999;
}
.MobileCart .Heading{
	color: var(--blue);
	font-family: 'GothamRounded-Medium';
	font-size: 14px;
}
.MobileCart .Heading img{
	height: 25px;
	vertical-align: middle;
}
.MobileCart .CollapseCart img,.MobileCart .ExpandCart img{
	height: 30px;
}
.MobileCart .WrapBox{
	position: relative;
}
.MobileCart .CollapseCart{
	position: absolute;
	right: 10px;
	top: 10px;
}
.MobileCart .ExpandCart{
	position: absolute;
	right: 10px;
	top: 20px;	
}
.MobileCart .CollapsedCart{
	border: 2px solid var(--blue);
	border-radius: 20px;
	text-align: left;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 60px;
	margin: 10px 30px;
	position: relative;
}
.MobileCart .CollapsedCart .TicketType{
	color: var(--blue);
	font-size: 16px;
	margin: 10px 0;
}
.MobileCart .Container{
	background: var(--black);
}
.ShippingAbroadWarningText{
	margin-top: 20px;
	font-size: 18px;
	font-weight: bold;
	color: var(--red);
	text-align: left;
}
.WarnBlackText{
	color: var(--black);
	font-family: 'GothamRounded-Light';
}
.MakeLessOpacity{
	opacity: 0.5;
}
.MakeLessOpacityInternational .ParaContainer, .MakeLessOpacityInternational .HeadlineContainer{
	opacity: 0.5;
}
.HideShippingOption{
	display: none;
}
.GrayedButton{
	background: var(--gray) !important;
}
/*@media (min-width: 1300px){
	.Agreement .Checkmark{
		left: 1%;
	}
}
@media (min-width: 1350px){
	.Agreement .Checkmark{
		left: 3%;
	}
}
@media (min-width: 1400px){
	.Agreement .Checkmark{
		left: 5%;
	}
}
@media (min-width: 1500px){
	.Agreement .Checkmark{
		left: 8%;
	}
}
@media (min-width: 1600px){
	.Agreement .Checkmark{
		left: 10%;
	}
}
@media (min-width: 1700px){
	.Agreement .Checkmark{
		left: 12%;
	}
}
@media (min-width: 1800px){
	.Agreement .Checkmark{
		left: 15%;
	}
}
@media (min-width: 1900px){
	.Agreement .Checkmark{
		left: 16%;
	}
}
*/
@media(max-width:1400px){
	.PaymentLogos{
		width: 40%;
	}
}
@media (max-width: 1100px){
	.Payment .ProgressBar .Progress div img{
		height: 60px;
	}
	.Payment .ProgressBar .Progress .StepOneGap{
		margin: 0 30px;
		letter-spacing: 10px;
	}
	.Payment .ProgressBar .Close{
		top: 45px;
		left: 50px;
	}
	.Payment .ProgressBar{
		padding: 10px 20px;
	}
	.Payment .Container.WrapBox{
		width: 90%;
		margin: auto;
	}
	.Cart .WrapBox{
		width: 90%;
		margin: 0;
		padding: 20px 10px;
	}
	.PaymentLogos{
	
		right: 20px;
		top: 30px;
		/* border: 2px solid red; */
		width: 45%;
		display: flex;
		justify-content: space-between;
		
	}
	/* .FirstContainer{
		width: 80%;
	}
	.SecondContainer{
		width: 20%;
	} */
	.Radiocontainer>div>div:nth-child(1){
	
		font-size: 18px;
		
	}
	
	.Radiocontainer>div>div:nth-child(2){
		text-align: left;
		font-family: 'GothamRounded-Medium';
		margin: -10px 0 0 0;
		font-size: 14px;
		}
		.SecondContainer,.ChangeContainer{
			font-size:18px ;
		}
}

@media (max-width: 900px){
	.PaymentBackground{
		backdrop-filter: unset;
		background-color: rgba(0, 0, 0, 0.9);
	}

	.Cart .WrapBox{
		display: none;
	}
	.MobileCart{
		display: block;
	}
	.MobileCart .WrapBox{
		display: block;
		margin: 20px auto;
	}
	.MobileCart .TicketDetails{
		border: 0;
		text-align: left;
		padding: 0;
		border-radius: 0;
		display: block;
	}
	.MobileCart .TicketDetails .EventInfo{
		padding: 0;
		margin: 0;
	}
	.MobileCart .PriceDetails .PriceBlock{
		margin-left: 0;
		margin-right: 0;
	}
	.Payment{
		width: 90%;
	}
	.MobileCart .TicketOwner{
		text-align: left;
	}
	.BackgroundContainer{
		margin-top: 190px;
	}
	.NextButton button{
		padding: 10px 40px;
		color: var(--black);
		background: var(--blue);
		border-radius: 10px;
		outline: none;
		border: 0;
		font-size: 18px;
		font-family: 'GothamRounded-Medium';
	  cursor: pointer;
	  margin: 0 10px;
	}
	.PaymentBackground{
	}
}


@media (max-width: 700px){
	.Agreement{
		margin: 10px;
	}
	.Agreement label div{
		margin-left: 5px;
	}
	.Agreement label span{
		flex-basis: 10%;
		max-width: 20px;
	}
	.Payment .Container.WrapBox{
		padding: 20px 0;
		width: 95%;
	}
	.Payment .ProgressBar .Progress div img{
		height: 50px;
	}
	.Payment .ProgressBar .Progress .StepOneGap{
		margin: 0 15px;
		letter-spacing: 15px;
	}
	.Payment .ProgressBar .Close{
		left: 20px;
	}

	.ShippingCharge .LabelText .PostCompanyName, .ShippingCharge .LabelText .ShippingPrice{
		font-size: 16px;
	}
	.ShippingCharge .LabelText div:nth-child(2){
		font-size: 12px;
	}
	.NextButton button{
		padding: 10px 20px;
		font-size: 14px;
	}
	.PaymentMethods{
		margin: 10px;
	}
	.PaymentMethod{
		padding: 30px 10px;
		padding-left: 50px;
	}
	.PaymentMethod .Checkmark {
		left: 20px;
		top: 30px;
	}
	.PaymentMethod .Heading{
		font-size: 16px;
	}
	.PaymentMethod .Heading span{
		display: none;
	}
	.PaymentMethod .Tagline{
		display: none;
	}
	.PaymentMethod img{
		height: 20px;
	}
	.PaymentMethod .Logo{
		top: 15px;
		right: 10px;
	}
	.MobileCart .CollapsedCart{
		font-size: 14px;
		padding-left: 20px;
	}
	.Payment .PaymentStatus .Close{
		left: 50px;
		top: 30px;
		font-size: 48px;
	}
	.Payment .PaymentStatus .Title{
		font-size: 16px;
	}
	.PaymentStatusMessage{
		font-size: 12px;
		font-family: 'GothamRounded-Book';
		margin: 20px;
	}
	.Disclaimer{
		font-size: 12px;
	}
	.Payment .PaymentStatusButtons button{
		padding: 10px 30px;
		margin: 0 5px;
		border-radius: 20px;
		font-size: 14px;
	}
	.Payment .PaymentStatusFailImage img{
		height: 50px;
	}
	.Payment .PaymentStatusMessage.SuccessMessage{
		display: none;
	}
	.Payment .TicketDetails.SuccessMessage{
		padding: 0;
		border: 0;
		font-size: 14px;
		display: block;
	}
	.Payment .TicketDetails.SuccessMessage .EventInfo{
		border: 0;
		padding: 0;
	}
	.Payment .TicketDetails.SuccessMessage .EventInfo .EventLocation{
		display: inline-block;
		margin: 0;
	}
	.Payment .TicketDetails.SuccessMessage .TicketTimings div{
		display: inline-block;
	}
	.Payment .PaymentStatusImage img{
		height: 100px;
	}
	.PaymentLogos{
	
		right: 10px;
		top: 15px;
		/* border: 2px solid red; */
		width: 48%;
		display: flex;
		justify-content: space-between;
		
	}
	.VisaContainer,.AmericanContainer,.MasterCardContainer{
		
		width: 100px;
		height: 20px;
		
	}
	
	.Payment .Logo img,.VisaContainer img,.MasterCardContainer img{
		width: 100%;
		object-fit:contain;
		height: auto;
	}
	.AmericanContainer img{
		width: 100%;
		object-fit:contain ;
		height:auto;
	}
	.Radiocontainer>div>div:nth-child(1){
	    font-size: 16px;
		width: 85%;
		}
	
	.Radiocontainer>div>div:nth-child(2){
		text-align: left;
		font-family: 'GothamRounded-Medium';
		margin: -10px 0 0 0;
		font-size: 12px;
		width: 80%;
	}
	.SecondContainer,.ChangeContainer{
		font-size:16px;
		width: 20%;
	}
	.Agreement .Checkmark{
		top: unset;
		left: unset;
	}
	
}
@media(max-width:600px){
	/* .WrapBox{
		padding: 15px;
	}
	
	.SecondContainer p{
		font-size: 16px;
	} */
	.Radiocontainer>div>div:nth-child(2){
		text-align: left;
		font-family: 'GothamRounded-Medium';
		margin: -10px 0 0 0;
		font-size: 12px;
		/* width: 80%; */
		}
		.SecondContainer,.ChangeContainer{
			font-size:16px;
			/* width: 20%; */
		}
}
@media (max-width: 500px){
	.Payment .ProgressBar .Progress .StepOneGap{
		margin: 0 10px;
		letter-spacing: 10px;
	}
	.Payment .ProgressBar .Close{
		top: 0px;
	}
	.MobileCart .CollapsedCart .TicketType{
		font-size: 13px;
	}
	.MobileCart .CollapsedCart{
		font-size: 12px;
		padding-left: 10px;
	}
	.Payment .PaymentStatus .Close{
		left: 30px;
		top: 30px;
		font-size: 48px;
	}
	.Payment .PaymentStatus .Title{
		font-size: 16px;
		padding: 0px 20px 10px 20px;
	}
	.Radiocontainer>div>div:nth-child(2){
		text-align: left;
		font-family: 'GothamRounded-Medium';
		margin: -10px 0 0 0;
		font-size: 12px;
		width: 80%;
		}
		.SecondContainer,.ChangeContainer{
			font-size:12px;
			width: 20%;
		}
}


@media (max-width: 400px){
	.Payment .ProgressBar{
		padding: 10px 5px;
	}
	.Payment .ProgressBar .Progress div img{
		height: 50px;
	}
	.Payment .ProgressBar .Progress .StepOneGap{
		margin: 0 10px;
	}
	.Payment .ProgressBar .Close{
		left: 10px;
	}
	.Payment{
		width: 100%;
	}
	.Payment .PaymentStatusFailImage{
		position: absolute;
		right: 30px;
		top: 40px;
	}
	.Payment .PaymentStatusFailImage img{
		height: 40px;
	}
	.MobileCart .CollapsedCart{
		margin: 10px 10px;
	}
	.SecondContainer p{
		font-size: 12px;
		margin: 15px 0 0 0;
	}

}



/* NEW TICKET TYPE CSS */
.TicketmasterDetails{
	text-align: left;
}
.TicketmasterFormContainer > div{
	display: flex;
	font-size: 14px;
	margin: 15px 0;
	justify-content: flex-start;
}
.TicketmasterFormContainer > div > label{
	flex-basis: 20%;
}
.TicketmasterFormContainer > div > div{
	flex-basis: 80%;
}
.TicketmasterFormContainer label{
	color: var(--black);
	font-family: 'GothamRounded-Medium';
}
.TMInputContainer{
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
}
.TMInputContainer > div{
	margin: 0 10px;
	margin-bottom: 5px;
}
.TMInputContainer input{
	position: relative;
	background: var(--white);
	border: 1px solid var(--black);
	border-radius: 5px;
	opacity: 1;
	text-align: center;
}
.TMInputContainer input::placeholder{
	font-size: 12px;
	text-align: center;
}
/* NEW TICKET TYPE CSS */