.ErrorBox{
	position: fixed;
	width: 100%;
	margin: auto;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 99999;
	background: rgba(0,0,0,0.7);
	text-align: center;
	overflow: auto;
}

.Container{
	margin-top: 100px;
	width: 60%;
	display: inline-block;
}

.WrapBox{
	background: var(--black);
	border: 2px solid var(--blue);
	border-radius: 20px;
	padding: 20px;
	margin: 0 20px;
}

.ErrorTitle{
	color: var(--red);
	font-family: 'GothamRounded-Bold';
	font-size: 18px;
	margin: 10px 0;
}
.ErrorSubTitle{
	color: var(--white);
	font-family: 'GothamRounded-Light';
	font-size: 16px;
	margin: 20px 0;
}
.Errors{
	width: 60%;
	margin: auto;
	text-align: left;
}
.ErrorMessage{
	color: var(--white);
	font-size: 14px;
	font-family: 'GothamRounded-Book';
	margin: 10px 0;
	padding-bottom: 20px;
	border-bottom: 2px solid var(--blue);
}
.ErrorMessage span{
	padding: 9px;
	background: var(--red);
	border-radius: 50px;
	margin: 0 10px;
	vertical-align: middle;
}
.ErrorButtons{
	margin-top: 20px;
}
.ErrorButtons button{
	padding: 10px 25px;
	color: var(--white);
	font-family: 'GothamRounded-Medium';
	font-size: 12px;
	background: transparent;
	border: 1px solid var(--white);
	border-radius: 10px;
	margin: 0 20px;
}

.ErrorButtons button:hover{
	background: var(--white);
	color: var(--black);
	font-family: 'GothamRounded-Bold';
}
.ErrorContainer{
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}
@media (max-width: 1200px){
	.Errors{
		width: 80%;
	}
	.Container{
		width: 80%;
	}
}
@media (max-width: 768px){
}
@media (max-width: 350px){
	.WrapBox{
		background: var(--black);
		border: 2px solid var(--blue);
		border-radius: 20px;
		padding: 20px 5px;
		margin: 0 5px;
	}	
}
@media (max-width: 600px){
	.Errors{
		width: 90%;
	}
	.Container{
		width: 100%;
	}
}
@media (max-width: 450px){
	.ErrorMessage{
		font-size: 12px;
	}
	.Container{
		width: 100%;
	}
	.WrapBox{
		background: var(--black);
		border: 2px solid var(--blue);
		border-radius: 20px;
		padding: 20px 10px;
		margin: 0 15px;
	}
	.ErrorMessage span{
		margin: 0;
		margin-right: 2px;
	}
}