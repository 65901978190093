.PayPalButtonStyle > div{
	height: 50px !important;
}
.PayPalButtonStyle{
	width: 150%;
}
.PayPalButtonStyle iframe{
	filter: opacity(0);
	-webkit-filter: opacity(0);
	-moz-filter: opacity(0);
}