.Footer{
    width: 90%;
    margin: auto;
    margin-top: 20px;
    border-top: 1.5px solid var(--black);
    padding: 15px 0;
    color: var(--black);
    max-width: var(--max-size);
}
.Flex{
    display: flex;
    justify-content: space-between;
}
.Div0 img{
    height: 40px;
    filter: brightness(0);
}
.Div0{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.Div0 ul{
    display: flex;
    justify-content: space-between;
}
.FooterLogo{
    font-family: 'GothamRounded-Medium';
    color: var(--black);
    font-size: 18px;
}
.FooterLogo > div:nth-child(1){
    margin-bottom: 30px;
}
.Div1 ul, .Div0 ul, .Div5 ul{
    margin: 0;
    padding: 0;
}
.Div1 ul li, .Div0 ul li, .Div5 ul li{
    list-style: none;
    text-decoration: none;
    font-size: 15px;
    margin-bottom: 8px;
    font-family: GothamRounded-Medium;
}
.Div1 ul li a, .Div0 ul li a, .Div5 ul li a{
    color: var(--black);
}
.Div5{
    margin-top: 30px;
}
.Div5 ul{
    display: flex;
    justify-content: space-between;
}
.Div1 ul li:first-of-type{
    text-decoration: none;
    font-size: 22px;
    color: var(--black);
    margin-bottom: 20px;
    white-space: nowrap;
}

.Div2{
    display: grid;
    grid-template-columns: repeat(4, 140px);
    align-self: baseline;
}
.Div2 div{
    margin-bottom: 10px;
}
.Div2 img{
    width: 130px;
    margin-right: 10px;
}


.Div3 img{
    width: 60px;
    margin-bottom: 50px;
    float: right;
}
.Div3{
    font-size: 13px;
    display: none;
    flex-direction: column;
    justify-content: space-between;
}
.Powered{
    float: right;
}
.Div3 label{
    color: var(--black);
    font-family: GothamRounded-Medium;
    float: right;
}

.Div4, .Div5{
    display: none;
}

.Helpful , .Powered1{
    display: none;
}
.Powered1 a, .Powered a{
    color: var(--black);
}
.MobileSocials{
    display: none;
    font-family: 'GothamRounded-Medium';
}
.MobileSocials div:first-of-type {
    font-size: 15px;
    color: var(--black);
}
.MobileSocials div{
    margin-bottom: 16px;
}
.MobileSocials a{
    color: var(--black);
    font-size: 13px;
    display: flex;
    align-items: flex-end;
}
.MobileSocials img{
    height: 20px;
    width: auto;
    margin: 0;
    filter: brightness(0) invert(1);
    vertical-align: top;
    margin-left: 10px;
}
.CookiesBox{
    background: var(--black);
    color: var(--white);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    padding: 2px 20px;
    box-sizing: border-box;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 2px solid var(--blue);
    flex-wrap: wrap;
}
.CookiesBox > div{
    margin: 0 5px;
}
.CookiesBox button{
    background: var(--blue);
    outline: none;
    border: 0;
    border-radius: 5px;
    margin: 0 2px;
}


@media(max-width: 1300px){
    .Div2{
        grid-template-columns: repeat(3, 140px);
    }
}
@media(max-width: 1100px){
    .FooterLogo img{
        height: 30px;
    }
}
@media(max-width: 1024px){
    .Footer{
        padding-bottom: 60px;
    }
    .Div2{
        grid-template-columns: repeat(2, 140px);
    }
}
@media(max-width:800px){

    .Powered1{
        display: block;
        text-align: center;
        font-size: small;
        
    }

    .Powered1 label{
        color: var(--black);
        font-family: GothamRounded-Medium;
    }
    .Powered{
        display: none;
    }
    .Helpful{
        display: block;
        color: var(--white);
        text-align: center;
        font-size: 14px;
        margin: 0;
        margin-bottom: 10px;
        font-family: GothamRounded-Bold;
    }
    
    .Div2{
        display: none;
    }
    .Hide{
        display: none;
    }

    .Div4{
        display: block;
        display: grid;
        margin-top: 15px;
        justify-content: space-around;
        grid-template-columns: repeat(3, 120px);
    }

    .Div4 img{
        width: 120px;
        margin-bottom: 20px;
        float: right;        
    }

    .MobileSocials{
/*        display: block;*/
    }
    .Div3{
        display: block;
    }
    .Div5{
        display: block;
    }
    .Div5 ul{
        display: block;
    }
    .Div5 ul li{
        margin-bottom: 15px;
    }
    .Div0 > div:nth-child(2){
        display: none;
    }
    .Footer .Flex{
        display: grid;
        grid-template-columns: 65vw 1fr;
    }
    .Div0{
        grid-row: 1;
        margin-bottom: 40px;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .Div1, .Div3{
        grid-row: 2;
    }
    .FooterLogo > div:nth-child(1){
        margin-bottom: 10px;
    }
}

@media(max-width:400px)
{
    .Div4{
        margin: 15px 0px;
        grid-template-columns: repeat(3, 100px);
    }   
    .Div4 img{
        width: 100px;
        margin-bottom: 15px;
    }
    .MobileSocials img{
        margin-left: 2px;
    }
    .Footer .Flex{
        grid-template-columns: 60vw 1fr;
    }
}

@media(max-width:350px)
{
    .Div4{
        grid-template-columns: repeat(3, 90px);
    }
    .Div4 img{
        width: 85px;
    }
    .Footer .Flex{
        grid-template-columns: 55vw 1fr;
    }
    .FooterLogo img{
        height: 25px;
    }
    .FooterLogo{
        font-size: 13px;
    }
}