.AccountList{
    text-align: center;
	padding-top: var(--offset-top);
    /* min-height: 100vh; */
	/* backdrop-filter: blur(8px); */
	font-family: 'GothamRounded-Book';
}
.AccountHr{
	width: 55%;
	height: 2px;
	margin: auto;
	background-color: var(--blue);
}
.AccountHrMedia{
	width: 100%;
	height: 1px;
	margin: auto;
	background-color: var(--black);
	display: none;
}
.AccountHeading{
	font-family: 'GothamRounded-Medium';
	color: var(--black);
	margin:  15px auto;
}

.SettingsStyle {
	margin:  20px auto;
	
}
.AccountHeading img{
	width: 25px;
	vertical-align: middle;
	margin-right: 10px;
	filter: brightness(0);
}

.AccountButton{
	margin-bottom: 40px;
}
.NormalB{
	font-family: 'GothamRounded-Medium';
	background-color: var(--white);
	border: 0px solid var(--blue);
	border-right:1px solid var(--white);
	padding:10px 70px;
}
.AccountButton button:first-of-type {
	border-radius: 30px 0 0 30px;
}
.AccountButton button:last-of-type{
	border-radius: 0 30px 30px 0px;
}

.SettingsStyle button:first-of-type {
	border-radius: 30px 0 0 30px;
}
.SettingsStyle button:last-of-type{
	border-radius: 0 30px 30px 0px;
}

.ChangeB{
	background-color: var(--blue);
	color: var(--black);
	font-family: 'GothamRounded-Medium';
	border: 0px solid var(--blue);
	border-right:1px solid var(--white);
	padding:10px 70px;
}
.ChangeB:hover{
	opacity: 1;
}
.CheckImg{
	width: 14px;
	vertical-align: middle;
	margin-left: 10px;
	display: none;
} 

@media(max-width:1180px)
{
	.OrdersHide{
		display: none;
	}
}

@media(max-width:1010px)
{

	
	 .AccountHr{
		width: 100%;
		height: 1px;
	}
	.SettingsStyle{
		
		display: flex;
		flex-wrap: wrap;
		justify-content: space-evenly;
		width: 100%;
		margin: 20px auto;

		/* grid-template-columns: repeat(2, 210px); */
	
	
	}
	.AccountHrMedia{
		display: block;
		
	}
	.CheckImg{
		display: inline;
	}
	.SettingsStyle button{
		border-radius: 10px;
		margin: 10px;
	}
	.SettingsStyle button:first-of-type {
		border-radius: 10px;
	}
	.SettingsStyle button:last-of-type{
		border-radius:10px;
	}
	.ChangeB , .NormalB{
		border-right: 1px solid  var(--black);
		padding: 10px 0;
		width: 160px;
	}
.NormalB{
	background-color: inherit;
	color: var(--black);
}
.ChangeB{
	background-color: var(--blue);
	color: var(--black);
}
.SettingsStyle img{
	width: 13px;
	margin-left: 3px;
}
	

	
}




@media(max-width:500px)
{
	.ChangeB , .NormalB{
		width: 100px;
		font-size: 12px;
	}
	.SettingsStyle button{
		width: 160px;
		margin: 5px;
	}
	.CheckImg{
		width: 11px;
	}
}
