.AccountCont {
    text-align: center;
    font-family: 'GothamRounded-Bold';
}

.AccountUl {
    padding: 0;
}

.AccountUl li {
    list-style: none;
    border-bottom: 2px solid var(--blue);
    padding: 15px;
}

.AccName {
    font-size: 25px;
    margin-top: -10px;
    color: var(--blue-dark);
}

.AccName1 {
    background-image: url('../../assets/images/icons/account-menu-1.jpg');
    background-position: left;
    background-size: 300px;
}

.AccName2 {
    background-image: url('../../assets/images/icons/account-menu-2.jpg');
    background-position: right;
    background-size: 300px;
}

.AccName3 {
    background-image: url('../../assets/images/icons/account-menu-3.jpg');
    background-position: left;
    background-size: 300px;
}

.AccName4 {
    background-image: url('../../assets/images/icons/account-menu-4.jpg');
    background-position: right;
    background-size: 300px;
}
.AccName1:hover {
    background-image: url('../../assets/images/icons/account-menu-1-hover.jpg');
}

.AccName2:hover {
    background-image: url('../../assets/images/icons/account-menu-2-hover.jpg');
}

.AccName3:hover {
    background-image: url('../../assets/images/icons/account-menu-3-hover.jpg');
}

.AccName4:hover {
    background-image: url('../../assets/images/icons/account-menu-4-hover.jpg');
}

.AccClass {
    background-size: 300px;
    font-size: 15px;
    cursor: pointer;
}

.AccButton {
    background-color: var(--white);
    border: 3.5px solid var(--blue);
    margin-bottom: 20px;
}