.Header {
  height: 70px;
  padding-top: 30px;
  margin: 0 auto;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  z-index: 1000;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--blue);
  max-width: var(--max-size);
}

.Logo {
  cursor: pointer;
  height: 20px;
 
  vertical-align: middle;
}

.Logo1{
  cursor: pointer;
  height: 45px;
  filter:  brightness(0) invert(1);
  vertical-align: middle;
  padding: 0px 10px;
}

.Logo2{
  filter:  brightness(0) invert(0);
  height: 45px;
}


.Header1{
  height: 70px;
  padding-top: 30px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  background-color: var(--black);
}
.Header1 > div{
  height: 70px;
  padding-top: 30px;
  margin: 0 auto;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  z-index: 1000;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--blue);
  background-color: var(--black);
  max-width: var(--max-size);
}


.HeaderSellMobile{
  border: 3.5px solid var(--blue);
  padding: 8px 25px;
  border-radius: 15px;
  font-family: 'GothamRounded-Bold';
  margin: 0px 10px;
}

.HeaderSell span {
  border: 3.5px solid var(--blue);
  padding: 8px 10px;
  border-radius: 15px;
  font-family: 'GothamRounded-Bold';
}

a.HeaderSellLink{
  color: var(--blue);
}
a.HeaderSellLink:hover{
  color: var(--black);
}
.HeaderIcon {
  margin-left: 30px;
}

.HeaderIcon img {
  padding-right: 20px;
}

.HeaderSell {
  position: absolute;
  left: 50%;
}

.HeaderSell span {
  position: relative;
  left: -50%;
  transition: 0.6s;
}

.HeaderSell span:hover {
  cursor: pointer;
  box-shadow: inset 12em 0 0 0 rgba(141, 238, 253, 0.5);
  background-color: var(--blue);
  color: var(--black);
}

.HeaderSellMobile:hover{
  cursor: pointer;
  box-shadow: inset 12em 0 0 0 rgba(141, 238, 253, 0.5);
  background-color: var(--blue);
  color: var(--black);
}


.container {
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
  padding: 0px 10px;

}

.bar1, .bar2, .bar3 {
  width: 35px;
  height: 3px;
  background-color: var(--blue);
  margin: 6px 0;
  transition: 0.4s;
  
}

.HeaderLogin{
  display: flex;
  align-items: center;
}
.HeaderLogin span {
  padding: 10px;
  border-right: 1px solid var(--blue);
  font-family: 'GothamRounded-Book';
}
.HeaderLogin span:last-child {
  border: none;
}

.HeaderLogin span:last-child img {
  vertical-align: middle;
}

.HeaderLogin span:hover {
  cursor: pointer;
  color: var(--white);
}
.HeaderLogin img:hover{
  filter: brightness(0) invert(1);
}
.HeaderLang {
  padding-right: 40px;
}

.Backdrop {
  font-size: smaller;
  padding: 10px 20px;
  margin-right: 200px;
  float: right;
  width: 275px;
  border: 2px solid var(--blue);
  border-radius: 20px 0px 20px 20px;
  background-color: var(--white);
}

.BackdropCart {
  margin-right: 100px;
  padding-left: 0;
  padding-right: 0;
  width: 300px;
  margin-bottom: 20px;
  z-index: 1;
}

.BackdropAcc {
  margin-right: 110px;
  float: right;
  width: 275px;
  border: 2px solid var(--blue);
  border-radius: 20px 0px 20px 20px;
  background-color: var(--white);
}

.LangImg {
  padding: 0 10px;
  width: 25px;
  fill: var(--blue);
}

.HeaderIconImg {
  height: 25px;
  cursor: pointer;
  vertical-align: top;
}
.HoverWhite:hover{
  filter: brightness(0) invert(1); /*White Filter*/
}
.HeaderIconImg1 {
  margin-right: 5px;
}

.HeaderIconImg2 {
  margin-left: 5px;
}
.HeaderNewLang{
  display: none;
}
.HeaderNewLang img {
  vertical-align: middle;
  padding-right: 0px;
}

.HeaderNewLang img:last-of-type {
  padding-right: 10px;
}
.HeaderNewLang.HamburgerLang{
  padding: 0;
}
.HeaderNewLang.HamburgerLang .HeaderIconImg2{
  height: 35px;
  margin: 0;
  padding: 2px 0;
}
.LangName {
  padding: 5px;
  cursor: pointer;
}

.HeaderNewLang {
  margin-right: 10px;
  padding: 10px 0;
  border-right: 1px solid var(--blue);
}

.HeaderLangOpen {
  margin-left: 30px;
  background-color: var(--black);
  color: var(--blue);
  border: 2px solid var(--blue);
  border-radius: 0px 10px 10px 10px;
  width: 100px;
  text-align: center;
  font-weight: bold;
}
.HeaderNewLang:hover{
  color: var(--white);
}
.HeaderLangOpen div img {
  margin-left: 10px;
}

.LangOpen1 {
  padding: 15px 0;
  border-bottom: 1px solid var(--blue);
}

.LangOpen1:hover , .LangOpen2:hover {
  color: var(--white);
  cursor: pointer;
}

.LangOpen2 {
  padding: 15px 0;
}

.HeaderSell span:hover {
  cursor: pointer;
  box-shadow: inset 12em 0 0 0 rgba(141, 238, 253, 0.5);
  background-color: var(--blue);
  color: var(--black);
}

.HeaderSell span {
  border: 1px solid var(--blue);
  padding: 8px 10px;
  border-radius: 25px;
  font-family: 'GothamRounded-Medium';
  font-size: 14px;
}

.HeaderSell1 {
  margin-left: 20%;
}

.HeaderSell1 span {
  padding: 5px 35px;
}


.Hamburger{
  position: fixed;
  background-color: var(--blue);
  height: 100vh;
  width: 100%;
  z-index: 1000;


}

.HamTop{
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.HamIcon , .HamIcon1{
  /* padding-top: 5px; */
  cursor: pointer;


}

.HamIcon{
  width: 35px;
  filter:  brightness(0) invert(0);
  
}

.HamIcon1{
  padding-top: 7px;
  width: 25px;
  padding-right: 10px;
}

.AccountMobile{
  text-align: center;
  font-family: 'GothamRounded-Medium';
 
}

.AccountMobile p{
  font-size: 28px;
  padding: 20px 0px;
  text-decoration: underline;
}

.AccountMobile button , .CartLogout button{
border: 2px solid var(--white);
padding: 10px 60px;
background-color: inherit;
font-family: 'GothamRounded-Medium';
font-size: larger;
border-radius: 10px;

}
.AccountMobile p.ContactBtn{
  margin-top: 30px;
  font-size: 15px;
  text-decoration: none;
}
.AccountMobile p.ContactBtn img{
  height: 20px;
  filter: brightness(0);
  vertical-align: middle;
  margin-right: 10px;
}
.CartLogout{
 display: flex;
 justify-content: space-around;
 margin-top: 25px;
}

.MobileLogin{
  padding: 20px;
}
.LoginPop {
  position: fixed;
  bottom: 20px;
  left: 20px;
  border: 2px solid var(--blue);
  padding: 10px 20px;
  border-radius: 15px;
  color: var(--blue);
  animation: FadeAnimation 10s ease-in 0.9s forwards;
  z-index: 1000000;
  background-color: rgba(0,0,0,0.8);
}

.SocialMediaIcons{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 55px;
}
.SocialMediaIcons img{
  filter: brightness(0);
  height: 30px;
  margin: 0 10px;
}

@keyframes FadeAnimation {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.HeaderLogin{
  position: relative;
}
.CartBadge{
  background: var(--white);
  font-size: 12px;
  position: absolute;
  right: 140px;
  top: 0;
  padding: 1px 5px;
  border-radius: 50%;
  color: var(--black);
}
.MobileCartDiv{
  position: relative;
}
.MobileCartDiv .CartBadge{
  right: -10px;
}
.MobileCartSpan{
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.MobileCartSpan > img{
  width: 30px;
}
.MobileCartSpan .CartBadge{
  right: -5px;
}
.LoginPop span {
  color: var(--white);
}

.HeaderLogo img{
  height: 20px;
  cursor: pointer;
}

@media(max-width: 1300px){
  .HeaderSell{
    display: none;
  }
}
@media(min-width: 1100px) and (max-width: 1200px){
  .HeaderSell1{
    margin-left: 22%;
  }
}
@media(max-width: 1100px){
}
@media(max-width:800px) {
  .HeaderLogin span:nth-child(2) {
    border-right: 0;
  }
  .HeaderNewLang{
    display: inline;
  }
  .HeaderLangOpen {
    margin-right: 20%;
  }
  .MobileLogin input{
    border: 1.5px solid black;
  }

  .LangName , .IconMobile {
    display: none;
  }

  .Backdrop , .BackdropAcc {
    margin-right: 70px;
  }

  .BackdropCart {
    margin-right: 15px;
  }

  .HeaderSell span {
    padding: 8px 30px;
    transition: 0.6s;
    margin-left: 10px;
  
  }

  .LoginPop {
    bottom: 0px;
    font-size: smaller;
    padding: 10px;
  }

  .LoginPop {
    position: fixed;
    text-align: center;
    width: 100%;
    bottom: 30%;
    left: 0;
    animation: FadeAnimation 3s ease-in 0.9s forwards;
    z-index: 1000000;
    border: 0;
    background: transparent;
  }
  .CartBadge{
    right: 0px;
  }
  .LoginPop div{
      background-color: rgba(0,0,0,0.8);
      border: 2px solid var(--blue);
      padding: 10px 20px;
      border-radius: 15px;
      color: var(--blue);
      width: max-content;
      margin: auto;
  }

  .HeaderLangOpen {
    margin-right: 15%;
  }
  .HeaderLogo{
    display: none;
  }
  .HeaderLogin span:nth-child(3){
    display: none;
  }

}

.Hide{
  display: none;
}
.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {opacity: 0;}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}
.HeaderText{
  color: var(--white);
  font-size: 14px;
  position: absolute;
  top: 15px;
  text-align: center;
  width: 100%;
}
.HeaderText span{
}
@media(max-width: 1024px){
  .HeaderText{
    font-size: 11px;
  }
}
@media(max-width:600px) {
  .HeaderLangOpen {
    margin-right: 10%;
  }
  .HeaderText{
    font-size: 10px;
    top: 10px;
  }
}
@media(max-width:470px) {

  .HeaderSellMobile{
    padding: 8px 15px;
  }

  .bar1, .bar2, .bar3 {
    width: 25px;
  }
  
  .HeaderSell {
    z-index: -1;
  }

  .Header , .Header1 {
    font-size: 14px;
  }

  .HeaderSell span {
    padding: 8px 20px;
    transition: 0.6s;
  }

  .HeaderIcon {
    margin-left: 15px;
  }

  .HeaderLangOpen {
    margin-left: 15px;
    margin-right: 5%;
  }

  .HeaderIconImg {
    height: 20px;
  }

  .LangImg {
    padding: 0;
  }

  .HeaderLogin {
    margin-right: 15px;
  }

  .HeaderLogin span {
    padding: 5px;
    z-index: 2;
  }

  .Backdrop , .BackdropAcc {
    margin-right: 50px;
    width: 210px;
  }

  .BackdropCart {
    margin-right: 15px;
  }
}

@media(max-width:380px){
  .AccountMobile p{
    padding: 12px 0px;
  }
  .HeaderLangOpen {
    margin-right: 2%;
  }  
  .HeaderSellMobile{
    padding: 8px;
  }
}

@media(max-width:370px){
  .Logo1{
    height: 25px;
  }
}

@media(max-height: 600px){
  .SocialMediaIcons > div{
    position: unset;
  }
  .Hamburger{
    overflow: auto;
  }
}



.LangSwitch {
  position: relative;
  display: flex;
  gap: 10px;
  border: 1px solid var(--blue);
  border-radius: 20px;
}
.LangSwitch img{
  height: 25px;
  align-self: center;
  cursor: pointer;
  padding: 0 3px;
}
.LangSwitch img:hover{
  filter: unset;
}
.LangSwitch img.Selected{
  border: 4px solid var(--blue);
  border-radius: 15px;
  padding: 0;
}