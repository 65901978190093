.EditForm{
    width: 96%;
    background-color: var(--blue);
    margin:15px auto;
    border-radius: 20px;
    color: var(--black);
    font-size: small;
    font-family: GothamRounded-Medium;
}
.EditFormp{
    background-color: var(--white);
    text-align: center;
    border-radius: 20px 20px 0 0;
    padding: 10px 0 10px 0;
    font-family: 'GothamRounded-Bold';
    font-size: 17px;
}
.OtherContainer{
    margin: 5px 0 0 0;
    /* text-align: right; */
}
.OtherContainer label{
    margin: 0 5px 0 0;
   width: 55px;
}
.OtherContainer input{
    margin: 0 0 0 46px;
}
.FormMain{
    padding: 15px 10px 15px 20px;
    position: relative;
 
}
.Form1{
    display: flex;
    flex-direction: row;
    /* border: 2px solid red; */
    /* justify-content: start; */
    justify-content: space-between;
    width: 100%;
    margin:  10px 0 0 20px;
}
.Form2{
    margin: 55px 0 20px 0px;
}
.Form1_1{
    width: 50%;
    /* text-align: center; */
    /* margin: auto; */
    /* margin: 0 0 0 25px; */
}

.Form1_2{
    width: 50%;
    /* margin: 0 0 0 25px; */
}
.Form1{
    position: relative;
}
.Form1 span{
    display: inline-block;
    width: 20%;
    /* position:absolute;
    top: 5px; */
}
.ShippedContainer{
    display: flex;
    align-items: center;
    margin: 0px 0 0 0;
}
.Form1_1 select{
    width:80%;
}
.Form1_1 span{
    width: 20%;
}
.Form1_2 select {
    width:80%;
}


.Form2_1 , .PriceDiv1{
    width: 50%;
    margin-bottom: 10px;
}
.Form2_2 , .PriceDiv2{
    width: 50%;
    margin-bottom: 15px;
}
.Form2_1 span , .Form2_2 span , .PriceDiv1 span , .PriceDiv2 span{
    width: auto;
    margin-right: 50px;
}

.Form2_1 input , .Form2_2 input {
    width: 110px;
}
.Form2_1 input[disabled] , .Form2_2 input[disabled] {
    background-color: var(--l-gray);
}

.EditForm select , .EditForm input{
    border:2px solid var(--blue);
    border-radius: 10px;
    padding: 5px;
    text-align: center;
    text-align-last: center;
    -ms-text-align-last: center;
    -moz-text-align-last: center;
    color: var(--gray);
    
}


.TableAdj{
    border-radius: 10px;
    width: 26px;
    height: 26px;
    vertical-align: middle;
}
.TableAdj1{
    display: -webkit-inline-box;
    vertical-align: middle;
    margin-left: 10px;
}
.TableAdj3{
    width: 80px;
    display: -webkit-inline-box;
    vertical-align: middle;
    margin-left: 10px;
}
.Adjac{
    position: absolute;
    padding-left: 2px;
}
.TableHr1{
    height: 1px;
    width: 55%;
    margin: 15px auto 20px auto;
    background-color: var(--black);

}
.CrossContainer{
    text-align: right;
    position: relative;
    margin: 0px 10px 0 0px;
}
.CrossContainer img{
    width: 18px;
    height: auto;
    position: absolute;
    top: -25px;
    right: 0px;
    cursor: pointer;
}
.CrossContainer img:hover{
    filter: invert(1);
}
.InformContainer{
    display: flex;
    align-items: center;
    margin: 0 0 0 23px;
}

.IClassContainer{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border:2px solid var(--black);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px 0 0;
}
.IClassContainer p{
    font-family: 'GothamRounded-Bold';
   
}
.ParaContainer{
    width: 90%;
    font-size: var( --l-small);
}

.TableHr{
    height: 2px;
    width: 50%;
    margin: 8px auto;
 background-color: var(--gray);
}

.TableOther{
    text-align: center;
    margin: 10px auto 5px auto;
}
.TableOther1{
    text-align: center;
    margin: 30px auto;
    cursor: not-allowed;
}
.TableOther1 input{
    background: var(--l-gray);
}

.RowInput{
    background: var(--l-gray);
    box-sizing: border-box;
    cursor: not-allowed;
    margin:  0 0 0px 25px;
    width: 60%;
}
.RowInput.Allowed{
    width: 60%;
    background: var(--white);
    cursor: unset;
    margin:  0 0 0px 25px;
}
.Price{
    font-family: 'GothamRounded-Bold';
    font-size: 16px;
}
.AdjMain2{
 display: none;
}

.Tablep{
    text-align: center;
    margin: 20px auto;
}

.TablepCheck p {
    margin:0;
}

.EditFlexMain{
    width: 70%;
    margin: auto;
}

.EditFlex{
    display: grid;
    grid-template-columns: repeat(4,150px);
    row-gap: 10px;
   justify-content: space-evenly;
}
.EditFlex > div{
    display: flex;
}
.File{
    border: none;
}
.HardTicket{
    /* padding: 15px; */
   box-sizing: border-box;
    width: 100%;
    /* margin:5px auto 15px auto; */
    /* text-align: center; */
    display: flex;
    gap:18px;
    margin: 20px 0 0 10px; 
}
.PickUp{
    margin: 5px 0 0px 19px;
    position: absolute;
}
.HardTicketText{
    width: 10%;
    margin: 0 0 0 10px;
}
.PhoneInput{
    margin: -5px 0 0 0;
}
.PhoneInput input[disabled]{
    background-color: var(--l-gray);
}
.ShippingOptions{
    /* display:flex; */
   /* border: 2px solid red; */
   margin:  35px 0 0 15px;
   align-items: center;
   /* gap: 24px; */
}
.ShippingValue{
    position: relative;
}
.ShippingValueContainer{
    display: flex;
    align-items: flex-start;
}
.ShippingValueContainer p{
    margin-top: 16px;
    margin-bottom: 16px;
}
.PhoneInput input{
    /* width: 100%; */
    /* margin: 0; */
	/* height: 29px; */
	padding-left: 70px;
    outline: none;
    /* border: none; */
}
.ShippingText > p{
    margin-top: 18px;
}
.ShippingMethodText{
    display: block;
    position: relative;
}
.ShippingMethodText p{
    margin-left: 20px;
    font-family: 'GothamRounded-Bold';
    font-size: var(--l-large);
    display: inline-block;
    margin-right: 3px;
}
.HardTicketPhoto{
    /* width: 50%; */
    /* display: flex; */
    /* height: 120px; */
    /* justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    background-color: var(--white); */
    /* padding: 50px; */
    /* width:50%;
    height: auto; */
    background: none;
}
.HardTicketPhoto div{
    /* height: 120px; */
    /* width: 100%; */
}
.HardTicketPhoto img{
    max-height: 100%;
}
.Browse{
    /* width: 200px; */
    margin: auto 32px auto 13px;
    border: 2px solid var(--gray);
    padding: 5px 17px;
    border-radius: 8px;
    background-color: var(--m-gray);
}
.BrowseHide{
    display: none;
}
.FileNameText{
    margin: 10px 0;
}
.EditButtons{
    text-align: center;
}
.EditButtons img{
    width: 15px;
    vertical-align: middle;
    margin: auto 5px ;
}

.EditButtons button{
    padding: 10px 7px;
min-width: 145px;
height: 39px;
    border-radius: 15px;
    border: none;
    font-family: 'GothamRounded-Medium';
    margin-right: 10px;
}
.EditButtons button:last-of-type{
    /* margin-right: 0; */
}
.TableButton1{
    background-color: var(--white);
}
.TableButton2{
    background-color: var(--l-green);
}
.TableButton3{
   background-color: var(--pink);
}

.ToggleStyle{
    display: block;
    text-align: right;
    padding-right: 35px;
}

.Form1_1.OtherDetailsTextArea{
    /* margin-top: 15px; */
    width: 70%;
    margin: 15px auto 0 auto;
}
.Form1_1.OtherDetailsTextArea textarea{
    width: 100%;
    height: 130px;
    box-sizing: border-box;
    margin-bottom: 2px;
    color: var(--black);
    font-family: 'GothamRounded-Medium';
    border: 2px solid var(--blue);
    border-radius: 10px;
    padding: 10px 5px 5px 5px;
    text-align: center;
    text-align-last: center;
    outline: none;
}
.Form1_1.OtherDetailsTextArea textarea[disabled]{
    background-color: var(--l-gray);
}
.Form1_1.OtherDetailsTextArea span{
    vertical-align: top;
    margin-right: 10px;
}
.ShippingInfo{
    max-width: 100px;
    font-family: 'GothamRounded-Light';
    font-size: 12px;
}
.ShippingInfo > div > span, .ShippingMethodText .Help{
    margin-right: 3px;
    border: 0.5px solid black;
    border-radius: 50%;
}
.ShippingMethodText .Help{
    padding: 0px 6px;
}
.ShippingInfo > div > span{
    padding: 0px 4.5px;
}
.ShippingMethodText .Help{
    display: inline-block;
    cursor: pointer;
    position: relative;
}
.Tooltip{
    visibility: hidden;
    position: absolute;
    width: 150px;
    top: 5px;
    left: 20px;
    background-color: rgba(0,0,0,0.9);
    color: var(--white);
    padding: 5px 20px;
    border-radius: 0px 10px 10px 10px;
    font-size: small;
    border: 1px solid var(--white);
    z-index: 99;
}
.Help:hover .Tooltip{
    visibility: visible;
}


@media(max-width:768px){
    .Form1_1.OtherDetailsTextArea{
        width: 80%;
    }
    .HardTicket{
        flex-direction: column;
        width: 100%;
    }
    .HardTicketText{
        width: 100%;
    }
    .HardTicketText p{
        text-align: center;
    }
    .HardTicket{
        margin: 0;
    }
    .HardTicketPhoto{
        width: 60%;
        height: auto;
        margin:  auto;
    
      text-align: center;
    }
    .HardTicketPhoto div{
        width: 100%;
        height:auto;
        /* margin:  auto; */
         
      text-align: center;
    }
    .HardTicketPhoto img{
        max-height: 100%;
        width: 100%;
    }
    .InformContainer{
        width: 90%;
        margin:auto;
        align-items: flex-start;
    }
    .IClassContainer{
        width: 15px;
        height: 15px;
        margin: 13px 5px 0 0;
    }
    .PhoneInput input{
        padding-left: 140px;
    }
    .ShippingValueContainer{
        width: 65%;
    }
}
@media(max-width:700px){
    .PhoneInput input{
        padding-left: 78px;
    }
}
@media (max-width:820px) {

    .Form2_1 span,.Form2_2 span{
        width: 40%;
        margin: 0;
    }
    .Form2_1 input,.Form2_2 input{
        width: 50%;
    }
    .EditButtons button{
        min-width: 130px;
    }
    .EditForm{
        font-size: 13px;
    }
    .FormMain{
        padding: 15px 0;
        /* height: 350px;
        overflow-y: scroll;
        overflow-x: hidden; */
    }
    ::-webkit-scrollbar {
        display: none;
    }
   .AdjMain2{
    display: block;
   }
    .Form1 , .Form2{
        flex-direction: column;
    }
    .Form1_1 , .Form1_2 , .Form2_1 , .Form2_2{
        width: 100%;
        /* text-align: center; */
    }
    .ShippedContainer{
        /* justify-content: space-around; */
    }
    .OtherContainer label{
        width: 17%;
    }

    .Form1_1 span , .Form1_2 span, .Form2_1 span , .Form2_2 span {
        text-align: -webkit-left;
    }

    .Form1_1 select , .Form1_2 select{
        width: 220px;
    }

    /* .Form2_1 span , .Form2_2 span{
        width: 175px;
   
    } */

    .PriceDiv1  ,.PriceDiv2 {
      
        width: 100%;
        /* text-align: center; */
        display: flex;
    }

    .PriceDiv1 span , .PriceDiv2 span{
 
        width: 40%;
        /* text-align: center; */
    }

  .AdjMain{
      display: none;
  }

  .EditFlex{
    grid-template-columns: repeat(2,150px);
  }
}

@media(max-width:600px){
    .Form1_1.OtherDetailsTextArea{
        width: 90%;
    }
    .ShippingValueContainer{
        width: 100%;
    }
}

@media(max-width:550px){
  .PhoneInput input{
    padding-left: 40px;
  }
  .TableButtonView{
    display: inherit;
    margin-bottom: 15px;
  }
  .Tooltip{
    right: 20px;
    left: auto;
  }
}
@media(max-width: 500px){
    .EditFlex{
        grid-template-columns: repeat(1,200px);
    }
    .TableAdj1{
        width: auto;
    }
    .ShippingText{
        width: 20%;
    }
    /* .PhoneInput input{
        padding-left: 50px;
    } */
    .PhoneInput input{
        padding-left: 30px;
    }
}
@media(max-width:450px){
    .PhoneInput input{
    }
}
@media(max-width:400px){
    .PhoneInput input{
    }
}
@media (max-width:370px) {
    .Form1_1 select , .Form1_2 select{
        width: 150px;
    }
    .Form2_1 span , .Form2_2 span{
        width: 105px;
   
    }
    .EditButtons button{
        width: 130px;
        padding: 10px 5px;
    }
   
    .EditButtons img{
        margin-right: 1px;
    }
    .ShippingText{
        width: 15%;
    }
    /* .PhoneInput input{
        padding-left: 20px;
    } */
    .PhoneInput input{
        width: 170px;
    }
}