.title {
  text-align: center;
}

.customSelectContainer {
  display: block;
  text-align: center;
  position: relative;
}

.selectedText {
  cursor: pointer;
}
.RoundedList{
  border-radius: 0px 0px 15px 15px;
}
.selectedText::after {
  content: "";
  position: absolute;
  right: 10px;
  top: 15px;
  border: 7px solid transparent;
  border-color: var(--black) transparent transparent transparent;
}
.selectedText.Active::after {
  top: 8px;
  border-color: transparent transparent var(--black) transparent;
}

.selectedText.DarkBackground::after {
  content: "";
  position: absolute;
  right: 10px;
  top: 15px;
  border: 7px solid transparent;
  border-color: var(--white) transparent transparent transparent;
}
.selectedText.DarkBackground.Active::after {
  top: 8px;
  border-color: transparent transparent var(--white) transparent;
}

ul.selectOptions {
  margin: 0;
  padding: 0;
  text-align: center;
  z-index: 1;
  max-height: 300px;
  overflow: auto;
}

.selectOptions {
  position: absolute;
  width: 100%;
}

ul.selectOptions li {
  list-style-type: none;
  padding: 10px 20px;
  border-bottom: 1px solid var(--white);
  cursor: pointer;
}

ul.selectOptions li:hover {
}