.Jumbotron{
	text-align: center;
  /* min-height: 100vh; */
	font-family: 'GothamRounded-Book';
/*	background: url('../../assets/images/background/light_background.jpg');*/
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: fixed;
}

.Jumbotron2{
  margin-top: 90px;
  padding-top: 80px;
  padding-bottom: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: url('../../assets/images/background/home_banner.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
}

.Logo{
	height: 40px;
  filter: brightness(0) invert(1);
}

.TicketImage{
  position: absolute;
  right: -50px;
  top: 10px;
}
.TicketImage img{
  transform: scaleX(-1) scaleY(-1) scale(1.1);
}
.ArtistBackground{
  background: linear-gradient(280.71deg, #000000 46.61%, #8DEEFD 211.29%);
  overflow: hidden;
}
.ArtistBanner{
  display: flex;
  justify-content: space-around;
  padding: 20px 0;
  color: var(--blue);
}
.ArtistBanner:hover{
  animation-play-state: paused;
}
.Artist{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
.Artist a{
  color: var(--blue);
}
/* Marquee animation */
@keyframes marquee {
  0% {
    transform: translateX(0); /* Start at normal position */
  }
  100% {
    transform: translateX(-100%); /* Pause at the end briefly */
  }
}
.ArtistContainer{
  box-sizing: border-box;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 14px;
  gap: 50px;
  white-space: nowrap;
  width: 100%;
}
.SecondIter{
  display: none;
}
.ArtistBanner .EmptyBox{
  height: 8px;
  width: 8px;
  background: var(--white);
}
.FlipContent{
  font-family: 'GothamRounded-Light';
	color: var(--white);
	margin-top: 5px;
	font-size: 22px;
}

.FlipContent div {
  overflow: hidden;
  position: relative;
  height: 65px;
}
.FlipContent div ul{
	padding: 0;
	margin: 0;
}
.FlipContent div li {
  font-weight: 700;
  height: 45px;
  margin-bottom: 45px;
  display: block;
}

.flip2 {
  -webkit-animation: flip2 8s linear infinite;
          animation: flip2 8s linear infinite;
}

.flip3 {
  -webkit-animation: flip3 10s cubic-bezier(0.23, 1, 0.32, 0.2) infinite;
          animation: flip3 10s cubic-bezier(0.23, 1, 0.32, 0.2) infinite;
}

.flip4 {
  -webkit-animation: flip4 10s cubic-bezier(0.23, 1, 0.32, 1.2) infinite;
          animation: flip4 10s cubic-bezier(0.23, 1, 0.32, 1.2) infinite;
}

@-webkit-keyframes flip2 {
  0% {
    margin-top: -180px;
  }
  5% {
    margin-top: -90px;
  }
  50% {
    margin-top: -90px;
  }
  55% {
    margin-top: 0px;
  }
  99.99% {
    margin-top: 0px;
  }
  100% {
    margin-top: -270px;
  }
}

@keyframes flip2 {
  0% {
    margin-top: -180px;
  }
  5% {
    margin-top: -90px;
  }
  50% {
    margin-top: -90px;
  }
  55% {
    margin-top: 0px;
  }
  99.99% {
    margin-top: 0px;
  }
  100% {
    margin-top: -270px;
  }
}
@-webkit-keyframes flip3 {
  0% {
    margin-top: -270px;
  }
  5% {
    margin-top: -180px;
  }
  33% {
    margin-top: -180px;
  }
  38% {
    margin-top: -90px;
  }
  66% {
    margin-top: -90px;
  }
  71% {
    margin-top: 0px;
  }
  99.99% {
    margin-top: 0px;
  }
  100% {
    margin-top: -270px;
  }
}
@keyframes flip3 {
  0% {
    margin-top: -270px;
  }
  5% {
    margin-top: -180px;
  }
  33% {
    margin-top: -180px;
  }
  38% {
    margin-top: -90px;
  }
  66% {
    margin-top: -90px;
  }
  71% {
    margin-top: 0px;
  }
  99.99% {
    margin-top: 0px;
  }
  100% {
    margin-top: -270px;
  }
}
@-webkit-keyframes flip4 {
  0% {
    margin-top: -360px;
  }
  5% {
    margin-top: -270px;
  }
  25% {
    margin-top: -270px;
  }
  30% {
    margin-top: -180px;
  }
  50% {
    margin-top: -180px;
  }
  55% {
    margin-top: -90px;
  }
  75% {
    margin-top: -90px;
  }
  80% {
    margin-top: 0px;
  }
  99.99% {
    margin-top: 0px;
  }
  100% {
    margin-top: -270px;
  }
}
@keyframes flip4 {
  0% {
    margin-top: -360px;
  }
  5% {
    margin-top: -270px;
  }
  25% {
    margin-top: -270px;
  }
  30% {
    margin-top: -180px;
  }
  50% {
    margin-top: -180px;
  }
  55% {
    margin-top: -90px;
  }
  75% {
    margin-top: -90px;
  }
  80% {
    margin-top: 0px;
  }
  99.99% {
    margin-top: 0px;
  }
  100% {
    margin-top: -270px;
  }
}







.BrowseEvents{
	/* color: var(--blue);
	font-family: 'GothamRounded-Medium';
 padding-top: 10px; */
/* padding-bottom: 5px; */
	/* position: absolute; */
	/* left: 50%; */
	/* bottom: 0; */

}
/* .BrowseEvents p {
  margin-bottom: 0;
}
.BrowseEventsIcon{
	height: 30px;
} */

/* .BrowseEventsContainer{ */
	/* position: relative;
	left: -50%; */
/* } */
@media (max-width: 1200px){
  .ArtistBanner{
    animation: marquee 30s linear infinite;
  }
  .ArtistContainer{
    flex: 0 0 200px;
  }
  .SecondIter{
    display: flex;
  }

}

@media (max-width: 768px){
  .Logo{
    height: 22px;
  }
  .FlipContent{
    font-size: 20px;
  }
  .Jumbotron{
    background-size: contain;
    background-repeat: unset;
  }
  .TicketImage{
    display: none;
  }
}


@media (max-width: 470px){
  .Logo{
    height: 20px;
  }
  .FlipContent{
    font-size: 18px;
  }
}