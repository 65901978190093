.ChangePasswordContainer{
	width: 70%;
	margin: 25px auto;
	padding: 20px 0;
}
.TitleText{
	color: var(--black);
	font-family: 'GothamRounded-Book';
	font-size: 12px;
	margin: 20px 0;
}

.ChangePasswordForm{
	width: 80%;
	margin: auto;
}

.InputContainer{
	position: relative;
}
.Input{
	width: 100%;
	box-sizing: border-box;
	margin: 5px 0;
	border: 2px solid var(--black);
}
.InputIcon{
	display: block;
	position: absolute;
	right: 10px;
	top: 12px;
	width: 20px;
	cursor: pointer;
	padding: 3px 0;
	filter: brightness(0);
}

.ButtonContainer{
	text-align: center;
	margin: 20px 0;
}

.Button{
	padding:8px 30px;
	border: 5px solid var(--blue);
	border-radius: 10px;
	outline: none;
	margin: 0px 10px;
	font-family: 'GothamRounded-Bold';
	background: var(--blue);
	color: var(--black);
}
/*.Button:hover{
	background-color: transparent;	
	color: var(--white);
	opacity: 1;
}*/
.Heading{
	color: rgba(255,255,255,0.85);
	display: none;
}

@media(max-width:850px)
{
	.ChangePasswordContainer{
		width: 95%;
	}
	.ChangePasswordForm{
		width: 95%;
	}
	.Heading{
		display: block;
	}
}

@media(max-width: 500px){
	.ButtonContainer button{
		margin: 10px;
	}

}