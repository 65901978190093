.EventsContainer{
    padding-top: 240px;
    position: relative;
    width: 100%;
    max-width: var(--max-size);
}
.Events{
    color: rgba(255,255,255,0.85);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(12px); /* For Chrome and Safari */
    -moz-backdrop-filter: blur(12px);    /* For Firefox */
    justify-content: space-between;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
}
.EventListContainer{
    display: flex;
}
.Events2{
    width: 65%;
    margin-left: 10px;
}

.Events1 p {
    text-align: center;
    margin: 8px 0;
    font-size: smaller;
    font-family: 'GothamRounded-Medium';
    color: var(--black);
    margin-top: 114px;
}
.Events1 .ExploreAll{
    background: rgba(var(--blue-rgb), 0.3);
    color: var(--black);
    margin: 5px 0;
    cursor: pointer;
    border: 2px solid var(--black);
    padding: 5px 20px;
    border-radius: 15px;
}
.Events1 .MapDescText{
    color: var(--green);
    margin: 15px 0;
    font-size: 12px;
}
.Events1 > div{
    overflow: auto;
}
.Events1 div{
    height: 100%;
    width: 100%;
}
text tspan{
    fill: var(--black) !important;
}
text{
    fill: var(--black) !important;
}
path, rect, ellipse, circle, polygon{
    fill: var(--l-gray) !important;
}
svg text{
    user-select: none;
    cursor: default;
    pointer-events: none;
}
svg text tspan{
    pointer-events: none;
}
/*path:hover, rect:hover{
    fill: var(--blue) !important;
}*/
.ZoomingButtons{
    display: flex;
    position: absolute;
    bottom: 10px;
    left: 5px;
    color: var(--black);
    flex-direction: column;
    text-align: center;
}
.ZoomingButtons > button{
    padding: 0px 5px;
    border: 1px solid var(--black);
    background: var(--l-gray);
    font-size: 20px;
    height: auto;
    width: auto;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none;
}
.Events1 {
    height: 250px;
    width: 350px;
    margin: 10px auto 80px 20px;
}
.Events1 img{
    height: 100%;
    width: 100%;
}
.Events1 > div{
    height: 100%;
    background-color: var(--white);
    border-radius: 15px;
}
.Events2Top{
    border-bottom: 2px solid var(--blue);
    color: var(--blue);
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Events2Top img{
   width: 25px;
   vertical-align:middle; 
}
.ApplyFilters{
    text-align: center;
    display: flex;
    justify-content: space-between;
    font-family: 'GothamRounded-Book';
    font-size: 14px;
    margin: 10px 20px;
    margin-right: 0;
}
.ApplyFilters .MinimumQuantityFilter{
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    align-items: center;
    color: var(--black);
}
.ApplyFilters .MinimumQuantityFilter div{
    margin: 0 5px;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}
.ApplyFilters .MinimumQuantityFilter .QuantityFilterButton{
    padding: 3px 25px;
    border: 2px solid var(--black);
    border-radius: 30px;
    margin-bottom: 10px
}
.ApplyFilters .MinimumQuantityFilter .QuantityFilterButton.Selected{
    color: var(--black);
    background: var(--blue);
    font-family: 'GothamRounded-Bold';
}
.ApplyFilters .MinimumQuantityFilterMobile{
    color: var(--black);
}
.ApplyFilters .MinimumQuantityFilterMobile .FilterButtonContainer{
    border: 2px solid var(--black);
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}
.ApplyFilters .MinimumQuantityFilterMobile .QuantityFilterButton{
    padding: 25px;
}
.ApplyFilters .MinimumQuantityFilterMobile .QuantityFilterButton.Selected{
    color: var(--black);
    border: 2px solid var(--blue);
    background: var(--blue);
    border-radius: 15px;
    font-family: 'GothamRounded-Bold';
    
}


.ApplyFilters .SeatingAreaFilter select, .ApplyFilters .TicketTypeFilter select{
    background: var(--blue);
    color: var(--black);
    padding: 8px 20px;
    border-radius: 30px;
    font-family: 'GothamRounded-Medium';
    margin-top: 5px;
    width: max-content;
}
.ApplyFilters .SeatingAreaFilter select option, .ApplyFilters .TicketTypeFilter select option{
    background: var(--white);
}
/*.ApplyFilters .FilterBy{
    font-size: 14px;
    color: var(--white);
}
.ApplyFilters .FilterButtons{
    margin: 10px 0;
}
.ApplyFilters .FilterButtons img{
    height: 30px;
    vertical-align: middle;
    filter: invert(79%) sepia(29%) saturate(575%) hue-rotate(155deg) brightness(105%) contrast(98%);
}
.ApplyFilters .FilterButtons .FilterButton{
    display: inline-block;
    border: 2px solid var(--blue);
    border-radius: 20px;
    padding: 0px 10px;
    font-family: 'GothamRounded-Medium';
    font-size: 14px;
    margin: 0px 10px;
    color: var(--white);
    cursor: pointer;
    vertical-align: middle;
}
.ApplyFilters .FilterButtons .FilterButton:nth-child(1){
    padding: 7px 15px;
}
.ApplyFilters .FilterButton.ActiveButton{
    background: var(--blue);
    color: var(--black);
    font-family: 'GothamRounded-Bold';
}
.ApplyFilters .FilterButton.ActiveButton img{
    filter: none;
}
.ApplyFilters .FilterSeats{
    color: var(--blue);
    font-size: 14px;
    text-decoration: underline;
    font-family: 'GothamRounded-Medium';
    cursor: pointer;
}
.ApplyFilters .FilterSeats div{
    padding-bottom: 10px;
}*/
.FilterImage{
    margin-right: 10px;
}

.Events2Bottom{
    opacity: 0.5;
}

.EventsNum{
    border: 2px solid var(--blue);
    border-radius: 10px;
    padding:10px 15px
}

.FilterNames{
    background-color: var(--white);
    padding: 20px 40px 10px 10px;
    position: absolute;
    z-index: 100;
    margin-top: -10px;  
    right: 55px;
    border: 2px solid var(--blue);
    border-radius: 10px 0 10px 10px;
    color: rgb(0,0,0,0.8);
    font-size: small;
    font-family: "GothamRounded-Medium";
}
.FilterNames p {
    margin:0
}
.FilterPopup{
    text-align: center;
}
.FilterHeading{
    color: var(--blue);
    padding: 0px 30px;
    padding-bottom: 5px;
    border-bottom: 1px solid var(--blue);
    font-family: 'GothamRounded-Medium';
}
.FilterSubHeading{
    color: var(--black);
    margin: 10px 0;
    font-size: 12px;
}
.FilterPopupContainer{
    width: 200px;
    margin: 50px auto 0 auto;
    background: var(--black);
    border-radius: 20px;
    border: 2px solid var(--blue);
    padding: 20px 50px;
    color: var(--black);
}
.FilterItem{
    margin: 10px 0;
    font-size: 12px;
    font-family: 'GothamRounded-Medium';
}
.FilterItem input{
    height: 30px;
    width: 30px;
    vertical-align: middle;
}
.ConfirmFilter button{
    padding: 7px 40px;
    border-radius: 20px;
}
.EventsList{   
    display: flex;
    flex-direction: row;
    border-radius: 20px;
    margin: 20px 0;
    padding: 3px 0;
    max-width: 870px;
}
.EventsList.MobileEventList.WhiteBorder{
    border-color: var(--black);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.Events2Div::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.Events2Div {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.Seat , .Price{
    font-family: "GothamRounded-Bold";
}
.Price{
    white-space: nowrap;
}
.EventsList p{
    margin:0;
    margin-bottom: 8px;
    font-size: small;
}
.EventsList4 img{
    width: 55px;
}
.EventsList4:hover img{
    filter: invert(1);
}
.EventsList > div{
    padding: 20px 20px 15px 20px
}
.EventsList1{
    width: 30%;
}
.EventsList2
{
    width: 50%;
}
.QuantityButton{
    border: 0;
    text-align: center;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}
/*.EventsList2 .QuantityButton div{
    border: 0;
    display: inline-block;
    background: var(--blue);
    color: var(--black);
    padding: 5px 20px;
    font-family: 'GothamRounded-Bold';
    margin: 0px 2px;
    font-size: 14px;
}
.EventsList2 .QuantityButton .QuantityChangeButton{
    cursor: pointer;
}
.EventsList2 .QuantityButton div:nth-child(1){
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}
.EventsList2 .QuantityButton div:nth-child(2){
}
.EventsList2 .QuantityButton div:nth-child(3){
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}*/
.EventsList3{
    width: 26%;
}

.EventsList3 p:first-of-type{
    font-size: 20px;
}

.EventsList4 {
    border-radius: 0px 20px 20px 0px;
    border-left: 1px solid var(--black);
    background-color: var(--blue);
    margin  : -5px;
    width: 5%;
    position: relative;
    cursor: pointer;
}
.EventsList4 img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.RightArrow{   
    transform: rotate(180deg);
    transition: transform 0.25s linear;
}

.LeftArrow{   
    transform: rotate(0deg);
    transition: transform 0.25s linear;
}
.ApplyFilters .MinimumQuantityFilterMobile{
    display: none;
}


.MobileEventList.EventsList{   
    display: table;
    border-radius: 25px;
    margin: 10px auto 10px auto;
    padding: 0;
    color: var(--black);
}
.MobileEventList.EventsList p{
    margin:0;
    margin-bottom: 8px;
}
.MobileEventList.EventsList > div{
    padding: 10px 5px;
    padding-bottom: 0;
    display: table-cell;
    box-sizing: border-box;
}
.MobileEventList.EventsList > div:nth-child(1){
    width: 7.5%;
}
.MobileEventList.EventsList > div:nth-child(2){
    width: 85%;
    padding: 0;
    vertical-align: top;
    background: var(--white);
}
.MobileEventList.EventsList > div:nth-child(3){
    width: 7.5%;
}
.MobileEventList .EventsList1{
    width: auto;
    display: flex;
    justify-content: space-between;
}
.MobileEventList .EventsList1 p:last-of-type {
}
.MobileEventList .EventsList2 {
    width: 100%;
    font-size: 14px;
    padding: 10px;
}
.MobileEventList .EventsList2 .FilterTitle{
    font-size: 20px;
    font-family: 'GothamRounded-Bold';
}
.MobileEventList .EventsList2 .soldByIcon{
    height: 15px;
    vertical-align: middle;
    filter: brightness(0);
}
.MobileEventList .EventsList2 p span{
    font-family: 'GothamRounded-Light';
    font-size: 14px;
}
.MobileEventList.EventsList .QuantityButton{
    border: 0;
    text-align: center;
    display: block;
    padding-bottom: 20px;
    padding-top: 5px;
}
/*.MobileEventList.EventsList .QuantityButton div{
    border: 0;
    display: inline-block;
    background: var(--blue);
    color: var(--black);
    padding: 5px 20px;
    font-family: 'GothamRounded-Bold';
    margin: 0px 2px;
    font-size: 14px;
}
.MobileEventList.EventsList .QuantityButton .QuantityChangeButton{
    cursor: pointer;
}
.MobileEventList.EventsList .QuantityButton div:nth-child(1){
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}
.MobileEventList.EventsList .QuantityButton div:nth-child(2){
    width: 70px;
}
.MobileEventList.EventsList .QuantityButton div:nth-child(3){
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}*/
.MobileEventList .EventsList3{
    width: auto;
    border-left: 2px solid var(--black);
    padding: 10px;
}
.MobileEventList .EventsList4 {
    border-radius: 0px 20px 20px 0px;
    background-color: var(--blue);
    margin  : auto;
    width: auto;
    position: relative;
}
.MobileEventList .EventsList4 img{
    position: relative;
    transform: unset;
    top: 50px;
    left: auto;
    padding: 5px;
    box-sizing: border-box;
    max-height: 50px;
}
.MobileEventList .EventsList5 {
    border-radius: 20px 0px 0px 20px;
    border-right: 1px solid var(--black);
    background-color: var(--blue);
    margin: auto;
    width: auto;
    position: relative;
}
.MobileEventList .EventsList5.WhiteBackground, .MobileEventList .EventsList4.WhiteBackground{
    background-color: var(--white);
}
.MobileEventList .EventsList4.WhiteBackground:hover img{
    filter: unset;
}

.MobileEventList .EventsList5 img{
    position: relative;
    transform: unset;
    top: 50px;
    padding: 5px;
    box-sizing: border-box;
    left: auto;
    max-height: 50px;
}

.Events2Div{
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 310px);
}

.LowerBorder{
    border-top: 1px solid var(--black);
    padding: 10px 0;
    text-align: center;
    font-size: 14px;
    font-family: 'GothamRounded-Medium';
}

.MobileEventList.EventsList .QuantityButton.MobileQuantityButton{
    display: none;
}
.NotAvailable{
    color: var(--black);
    font-family: 'GothamRounded-Medium';
    text-align: center;
}
.NotAvailable span{
    color: var(--black);
    cursor: pointer;
    text-decoration: underline;
}
.Shake{
    animation: ShakeText 0.1s forwards;
}
.SmallFont{
    font-size: 14px;
}
.CheckDetails{
    display: flex;
    flex-direction: column;
    margin: 0px 0;
    min-height: 0.01px; /* Work around for preventing overlapping of margin in safari */
    font-weight: 900;
}
.CheckDetails > div > div{
    margin-top: 5px;
}
.AdjacentSeats{
    font-style: italic;
}
.MobileEventList .EventsList2 .ShippingETA span{
    font-size: 16px;
}
.MobileEventList .EventsList2 .ShippingETA{
    font-size: 16px;
    margin: 10px 0 0 10px;
    color: var(--black);
    font-family: 'GothamRounded-Medium';
}
.ShippingETA > div > img{
    height: 18px;
    filter: brightness(0);
}
.ShippingETA .TicketmasterDisclaimer{
    color: var(--black);
    font-size: 11px;
    margin-top: 5px;
}
.RowSeats{
    display: flex;
}
.RowSeats p:nth-child(1){
    padding-right: 10px;
}
.RowSeats p:nth-child(2){
    border-left: 2px solid var(--black);
    padding-left: 10px;
}

.NoEventsContainer{
}
.NoEventsContainer > div{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    margin: 20px auto;
    border-radius: 15px;
    border: 2px solid var(--black);
    padding: 20px 10px;
    color: var(--black);
    font-family: 'GothamRounded-Bold';
    cursor: pointer;
    background: rgba(var(--blue-rgb), 0.3);
}
.NoEventsContainer > div:hover{
    background: var(--blue);
    color: var(--black);
}
.NoEventsContainer > div > img{
    margin-right: 20px;
    height: 40px;
    filter: brightness(0);
}
@keyframes ShakeText{
    0%{
        transform: translateX(0px);
    }
    25%{
        transform: translateX(-5px);
    }
    75%{
        transform: translateX(5px);
    }
    100%{
        transform: translateX(0px);
    }
}

@media (min-width: 1441px){
    .Events1 {
        height: 400px;
        width: 30%;
        margin: 10px auto 80px 20px;
    }
}

@media (max-width: 1150px){
    .ApplyFilters{
        margin: 10px 0px;
    }
    .Events1{
        margin-left: 0;
    }

}
@media (max-width:1050px) {
    .EventsContainer{
        padding-top: 10px;
        position: relative;
    }
    .Events{
        flex-direction: column;
        position: relative;
        padding-top: 240px;
    }
    .EventListContainer{
        display: block;
    }
    .EventListContainer.ReverseItems{
        display: flex;
        flex-direction: column-reverse;
    }
    .NoEventsContainer .HideOnMobile{
        display: none;
    }
    .NoEventsContainer{
        display: flex;
    }
    .NoEventsContainer > div{
        margin: 20px 5px;
    }
    .Events2{
        width: 100%;
        margin: 0;
    }
    .Events1{
        margin: 10px auto 20px auto;
        height: auto;
    }
    .Events2Div{
        height: auto;
    }
    .ApplyFilters{
        display: block;
    }
    .ApplyFilters .MinimumQuantityFilter{
        display: none;
    }
    .ApplyFilters .MinimumQuantityFilterMobile{
        display: block;
    }
    .ApplyFilters .SeatingAreaFilter, .ApplyFilters .TicketTypeFilter{
        display: inline-block;
        margin: 0px 10px;
    }
}
@media (max-width: 768px){
    .MobileEventList.EventsList .QuantityButton{
        display: none;
    }
    .MobileEventList.EventsList .QuantityButton.MobileQuantityButton{
        display: block;
    }
    .MobileEventList .EventsList5 img, .MobileEventList .EventsList4 img{
        top: 45px;
        width: 40px;
    }
    .CheckDetails{
        display: block;
        width: auto;
    }
    .MobileEventList .EventsList2 .ShippingETA span{
        font-size: 14px;
    }
    .MobileEventList .EventsList2 .ShippingETA{
        font-size: 15px;
    }
    .NoEventsContainer > div{
        width: 90%;
    }
}
@media (max-width: 600px){
    .MobileEventList .EventsList2 {
        font-size: 12px;
    }
    .MobileEventList .EventsList2 .FilterTitle{
        font-size: 14px;
    }
    .MobileEventList .EventsList2 span{
        font-size: 12px;
    }
    .MobileEventList .EventsList3{
        font-size: 12px;
        padding-left: 5px;
    }
    .MobileEventList .EventsList3 .Price{
        font-size: 14px;
    }
}
@media (max-width: 500px){
    .Events1 {
        width: 90%;
    }
    .Events{
        padding: 20px 10px 20px 10px;
        padding-top: 260px;
    }
    .Events2{
        margin-left: 0;
    }
    .ApplyFilters{
        margin: 0px;
    }
    .MobileEventList.EventsList > div:nth-child(2){
        padding: 0;
    }
    .ApplyFilters .SeatingAreaFilter, .ApplyFilters .TicketTypeFilter{
        width: 48%;
        margin: 0px 2px;
    }
    .ApplyFilters .SeatingAreaFilter select, .ApplyFilters .TicketTypeFilter select{
        width: 100%;
        box-sizing: border-box;
    }
    .ApplyFilters .MinimumQuantityFilterMobile .QuantityFilterButton{
        padding: 20px 15px;
    }


}
@media (max-width: 400px){
    /*.ApplyFilters .FilterButtons .FilterButton{
        padding: 3px 6px;
        margin: 0px 2px;
    }
    .ApplyFilters .FilterButtons .FilterButton:nth-child(1){
        padding: 4px 6px;
    }
    .ApplyFilters .FilterButtons img{
        height: 20px;
    }*/
    .MobileEventList.EventsList > div{
        padding: 10px 0;
    }
    .MobileEventList.EventsList .QuantityButton div:nth-child(2){
        width: auto;
    }
    .NoEventsContainer{
        display: block;
    }
    .NoEventsContainer > div{
        margin: 10px 0;
    }

}


.Help{
    display: inline-block;
    margin: 0 3px;
    cursor: pointer;
    position: relative;
}
.Help div{
    background: var(--blue);
    color: var(--black);
    padding: 1px 5px;
    border-radius: 50%;
}

.Tooltip{
    visibility: hidden;
    position: absolute;
    width: 150px;
    top: 0px;
    left: 20px;
    background-color: rgba(0,0,0,0.9);
    color: var(--white);
    padding: 5px 20px;
    border-radius: 0px 10px 10px 10px;
    font-size: small;
    border: 1px solid var(--black);
    z-index: 99;
}
.Help:hover .Tooltip{
    visibility: visible;
}

@media (max-width: 420px){
    .Tooltip{
        top: 20px;
        left: -70px;
    }
}