.Sales{
    padding-top: var(--offset-top);
	padding-bottom: 30px;
}
.AccountHr {
	width: 55%;
	height: 1.5px;
	margin: auto;
	background-color: var(--blue);
}

.AccountHeading {
	text-align: center;
	color: var(--black);
	margin: 15px auto;
	font-family: 'GothamRounded-Medium';
}

.AccountHeading img {
	width: 25px;
	vertical-align: middle;
	margin-right: 10px;
	filter: brightness(0);
}
.AccountHeading span {
	vertical-align: middle;
}
.ListingSearch{
    width: 480px;
    margin: auto;
}
.ListingSearch input{
    width: 100%;
    box-sizing: border-box;
}
.SalesTop{
	width: 450px;
    margin: 10px auto 35px auto;
	font-size: 14px;
	text-align: center;
	color: var(--black);
}
.SalesTop .HideSoldTickets span{
	padding: 2px 8px;
	background: var(--white);
	border-radius: 50%;
	vertical-align: middle;
	margin: 5px;
	border: 2px solid var(--black);
	cursor: pointer;
}
.SalesTop .HideSoldTickets input{
	display: none;
}
.SalesTop .HideSoldTickets input:checked ~ span{
	background: var(--blue-dark);
}
.Top1{
	display: flex;
	flex-direction: row;
	padding:10px;	
}

.Top1 div{
	width: 50%;
}

.Big , .Blue{
	margin: 0;
	margin-bottom: 8px;
	color: var(--black);
}
.Top1 div:first-of-type{
	border-right: 2px solid var(--black);
}
.Blue{
}
.Big{
	font-size: 30px;
	font-family: GothamRounded-Bold;
}

.Empty{
	text-align: center;
	color: rgba(255,255,255,0.8);
	font-size: 15px;
	width: 50%;
	margin: auto;
}

.Empty img{
	width: 22px;
	vertical-align: middle;
	
}
.Empty img:first-of-type{
	margin-right: 18px;
}
.Empty img:last-of-type{
	margin-left: 18px;
}

.Empty button{
	background-color: var(--blue);
	border: none;
	border-radius: 20px;
	padding: 15px 18px;
	font-family: GothamRounded-Bold;
}
.TicketsMain{
	border: 2px solid var(--black);
    border-radius: 20px;
    background: var(--white);
    width: 900px;
	margin: 30px auto;
	color: var(--black);
	font-family: GothamRounded-Medium;

}

.TicketType{
	margin: 0;
	margin: -1px;
	padding: 1px;
	border-radius: 20px 20px 0 0;
	font-size: small;
    color: var(--black);
    text-align: center;
    background-color: var(--white);
    border-bottom: 1px solid var(--black);
}

.TicketTopDetails{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 20px 10px 5px 10px;
	font-size: 13px;
}
.TicketTopDetails div{
	border-left: 1px solid var(--white);
	padding-left: 15px;
	padding-right: 5px;
}
.TicketTopDetails div label{
	line-height: 20px;
}
.TicketTopDetailsDiv1 , .TicketTopDetailsDiv2{
	display: flex;
	align-items: center;
	text-align: center;
	line-height: 1.4;
}
.TicketTopDetailsDiv2 label{
	cursor: pointer;
}
.TicketTopDetailsDiv2{
	text-align: left;
}

.TicketTopDetails div:first-of-type{
	border: none;
}

.Big1{
	font-size: 18px;
	font-family: GothamRounded-Bold;
	color: var(--black);
}
/*.Button{
	background-color: var(--blue);
	color: var(--blue);
	font-family: GothamRounded-Bold;
	border: none;
	border-radius: 20px;
	padding: 12px 30px;
	margin-top: 10px;
	margin-bottom: 15px;
}*/

.ButtonDone{
	background-color: var(--white);
	color: var(--black);
	font-family: GothamRounded-Bold;
	border: none;
	border-radius: 20px;
	padding: 12px 30px;
	margin-top: 10px;
	margin-bottom: 15px;
}
.TicketBottom{
	padding: 20px;
}
.BottomCont{
	border-radius: 20px;
	border: 2px solid var(--blue);
	padding: 5px 30px;
	margin: 10px auto;
	
	
}
.BottomDiv1 p{
	text-align: center;
	font-family: GothamRounded-Bold;
	color:var(--blue);
	font-size: 22px;
	
	
}



.BottomDiv2 p{
	text-align: center;
	font-family: GothamRounded-Bold;
	color:var(--blue);
	font-size: 22px;
}
.Uncheck{
	
	float: right;
    margin-top: -50px;
	background-color: var(--blue);
	border-radius: 50px;
	padding: 5px 10px;
    margin-right: 20px;
	color: var(--black);
	font-family: GothamRounded-Bold;
	cursor: pointer;
	
}

.Check img{
	width: 25px;
		
	float: right;
    margin-top: -50px;

	border-radius: 50px;
	padding: 5px 10px;
    margin-right: 20px;
	color: var(--black);
	font-family: GothamRounded-Bold;

}

.BottomDiv1Done{
	border-radius: 20px;
	border: 2px solid var(--blue);
	padding: 0px 30px;
	margin: 10px auto;
	background-color: var(--blue);

}

.BottomDiv2_1{
	border-radius: 20px;
	border: 2px solid var(--red);
	padding: 5px 30px;
	margin: 10px auto;
	background-color: var(--red);
}
.Center1{
	text-align: center;
	padding: 10px 0px;
	

}
.Center1 p {
	color: rgba(255,255,255,0.8);
	margin: 0;
	font-size: 15px;
}
.BottomDiv2 p {
	color: var(--black);
}
.Received{
	border-radius: 20px;
	border: 2px solid var(--green);
	padding: 0px 30px;
	margin: 10px auto;
	background-color: var(--green);
}

.Received p {
	color: var(--black);
}

.BottomDiv1Done p {
	color: var(--black);
}

.BottomButtonDiv{
	width: 50%;
	margin:auto;
	display: flex;
	justify-content: space-evenly;
}
.Center{
	text-align: center;
	font-size: 20px;
	font-family: GothamRounded-Medium;
}

.BottomDiv2 {
	font-size: 13.5px;
	line-height: 18px;
	letter-spacing: 0.6px;
}
.More{
	font-size: 13.5px;
	text-align: center;
	padding: 5px 25px;
	letter-spacing: 0.5px;
}
.ProgressBar .Progress{
	text-align: center;
	margin-top: 30px;
}
.ProgressBar .Progress .Step, .ProgressBar .Progress .Step > div{
	display: inline-block;
}
.ProgressBar .Progress .Step > div{
	vertical-align: middle;
}
.ProgressBar .Progress .Step .Text{
	text-align: left;
	margin: 10px 0;
	color: var(--gray);
	font-size: 15px;
	font-family: 'GothamRounded-Bold';
}
.ProgressBar .Progress div img{
	height: 40px;
	vertical-align: middle;
	filter: invert(50%) sepia(3%) saturate(16%) hue-rotate(314deg) brightness(86%) contrast(83%);
}
.ProgressBar .Progress .StepGap{
	color: var(--gray);
	margin: 0 51.5px;
	letter-spacing: 20px;
	font-size: 48px;
	display: inline-block;
}

.ProgressBar .Progress .StepGapFill .StepGap{
	color: var(--black);
}
.ProgressBar .Progress .StepGapFill .Text{
	color: var(--black);
}
.ProgressBar .Progress .StepGapFill img{
	filter: brightness(0);
}
.ProgressBar .Progress .StepGapFill .StepGapArrow img{
	filter: brightness(0);
}

.ProgressBar .Progress .StepGapComplete .StepGap{
	color: var(--black);
}
.ProgressBar .Progress .StepGapComplete .Text{
	color: var(--black);
}
.ProgressBar .Progress .StepGapComplete img{
	filter: brightness(0);
}
.ProgressBar .Progress .StepGapComplete .StepGapArrow img{
	filter: brightness(0);
}
.ProgressBar .Progress .StepGapArrow img{
	height: 20px;
	margin: 0 80.5px;
}

.ProgressBar .Progress .ExpandCollapseButton img{
	height: 10px;
	margin-left: 3px;
}
.ProgressBar .Progress .ExpandCollapseButton .ArrowImageUp img{
	transform: rotate(180deg);
}
.ExpandCollapseButton{
	color: var(--black);
	font-size: 14px;
	text-decoration: underline;
	padding: 2px 0 8px 0;
}
.ExpandCollapseButton.BlueText{
	color: var(--black);
}

.StatusDescription .StatusBox{
	width: 80%;
	border: 2px solid var(--black);
	padding: 16px;
	margin: auto;
	border-radius: 20px;
	display: flex;
	align-items: center;
}
.StatusDescription{
	margin-bottom: 50px;
}
.StatusDescription .StatusBox .Text{
	font-size: 12px;
	text-align: center;
}
.StatusDescription .StatusBox .Text a{
	color: var(--blue-dark);
}
.StatusDescription .StatusBox > div:nth-child(1){
	width: 100%;
	margin: auto;
	padding-right: 5px;
	box-sizing: border-box;
}
.StatusDescription .StatusBox > div:nth-child(2){
	width: 30%;
}
.StatusDescription .Buttons button{
	display: block;
	margin: 20px auto;
	background: var(--blue);
	border-radius: 20px;
	padding: 10px 30px;
	border: 0;
	outline: none;
	font-family: 'GothamRounded-Medium';
}
.StatusDescription .Buttons button img{
	height: 20px;
	vertical-align: middle;
}
.ProgressBar .StatusIcon{
	width: 100px;
	padding-bottom: 5px;
}
.ProgressBar .StatusIcon .CheckMark{
	height: 15px;
}
.ProgressBar .StepGapFill .CheckMark,.ProgressBar .StepGapComplete .CheckMark{
	height: 15px;
	margin-left: 10px;
	vertical-align: middle;
	position: absolute;
}
.ProgressBar .CheckedStatus.StatusIcon{
	border: 2px solid var(--black);
	border-radius: 20px;
	padding: 25px;
	background: var(--white);
	border-bottom: 0;
	padding-bottom: 50px;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	z-index: 9;
	margin-bottom: -2px;
}
.ProgressBar .StatusIcon:hover img{
	opacity: 0.8;
}
.ProgressBar .StatusIcon.Error img:nth-child(1){
	filter: invert(55%) sepia(58%) saturate(957%) hue-rotate(311deg) brightness(99%) contrast(93%);
}
.ProgressBar .StatusIcon.Error img:nth-child(2){
	height: 20px;
	margin-left: 10px;
	filter: unset;
}
.ProgressBar .StatusIcon.Error .Text{
	color: var(--pink);
}
.StatusDescription .StatusBox{
	background: var(--white);
}
.StatusDescription .StatusBox.RemoveLeftCorner{
	border-top-left-radius: 0;
	padding-left: 17px;
}
.StatusDescription .StatusBox.RemoveRightCorner{
	border-top-right-radius: 0;
}

.StepGapComplete .StatusIcon, .StepGapFill .StatusIcon{
	cursor: pointer;
	position: relative;
}


.SalesDetails{
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    backdrop-filter: blur(8px);
    height: 100%;
    width: 100%;
    text-align: center;
    overflow: auto;
}
@-moz-document url-prefix() {
    .SalesDetails{
      background: rgba(0,0,0,0.8);
    }
}

.SalesDetails .Container{
	width: 40%;
	margin: auto;
}
.WrapBox{
    background: var(--white);
    border: 2px solid var(--black);
    border-radius: 20px;
    color: var(--black);
    padding: 20px;
    margin: 50px auto;
    width: 80%;
    text-align: left;
}
.SalesDetails .EventDetails{
	display: flex;
	justify-content: space-between;
}

.SalesDetails .EventDetails > div:nth-child(2){
	text-align: center;
	border-left: 1px solid var(--white);
	padding-left: 20px;
}

.SalesDetails .EventDetails > div:nth-child(1) div{
	margin: 10px 0;
	font-size: 14px;
}

.SalesDetails .EventDetails .TicketCount{
	font-size: 32px;
	font-family: 'GothamRounded-Medium';
}
.SalesDetails .EventDetails .TicketText{
	font-size: 14px;
	font-family: 'GothamRounded-Light';
}
.SalesDetails .SaleDetails{
	margin: 20px 0;
}
.SalesDetails .SaleDetails::before{
	content: " ";
	padding-right: 80px;
	border-top: 1px solid var(--white);
}
.SalesDetails .SaleDetails::after{
	content: " ";
	padding-right: 80px;
	border-bottom: 1px solid var(--white);
}
.SalesDetails .SaleDetails div{
	font-size: 16px;
	font-family: 'GothamRounded-Medium';
}
.SalesDetails .SaleDetails div span{
	color: var(--black);
	font-family: 'GothamRounded-Medium';
}
.SalesDetails .Extras div{
	font-size: 14px;
	font-family: 'GothamRounded-Light';
	margin: 10px 0;
}
.SalesDetails .Extras div:nth-child(1){
	font-family: 'GothamRounded-Medium';
}
.SalesDetails .Extras .ExtraContent a{
	color: var(--black);
	text-decoration: underline;
}
.SalesDetails .PricingInfo{
	font-size: 14px;
	text-align: center;
}
.SalesDetails .PricingInfo span{
	color: var(--black);
	font-size: 16px;
	font-family: 'GothamRounded-Medium';
}
.SalesDetails .AcceptButton{
	text-align: center;
	margin-top: 20px;
}
.SalesDetails .AcceptButton button{
	padding: 12px 40px;
}
.MobileSearch{
	display: none;
}
.DesktopCheckbox{
	width: 90%;
    vertical-align: middle;
}
.DesktopCheckbox, .MobileCheckbox{
	display: inline-block;
}
.MobileCheckbox{
	display: none;
}
.TicketTopDetails div.GoToEventButton{
	padding: 0;
}
.GoToEventButton .EventAnchor{
	color:var(--blue);
	text-decoration: underline;
}
.GoToEventButton{
	margin-bottom: 0px;
}
.PriceBig1{
	display: none;
}
.TicketTopDetails .BlueLabelCreation{
	margin-bottom: 15px;
	color: var(--blue);
	border: none;
	padding: 0;
}

.StatusDescription .Buttons button.DisabledButton{
	filter: brightness(0.5);
}

@media (max-width: 1000px){
	.SalesDetails .Container{
		width: 50%;
	}
	.TicketsMain{
		width: 95%;
	}
	.StatusDescription .StatusBox{
		border: 0;
		border-top: 2px solid var(--blue);
		border-radius: 0;
	}
	.ProgressBar .CheckedStatus.StatusIcon{
		border: 0;
		border-radius: 0;
		margin: 0;
	}
	.ProgressBar .CheckedStatus.StatusIcon:after{
		content: " ";
		padding: 20px;
		border-left: 2px solid var(--blue);
		position: absolute;
		left: 70px;
		bottom: 0
	}
	.ProgressBar .Progress .StepGapArrow img{
		margin: 0 50px;
	}

}

@media (max-width: 800px){
	.TicketTopDetails div label{
		font-size: 12px;
	}
	.TicketTopDetails div label.Big1{
		font-size: 14px;
	}
	.TicketTopDetails div label.Big{
		font-size: 30px;
	}
	.SalesDetails .Container{
		width: 60%;
	}
	.ProgressBar .Progress .StepGap{
		margin: 0 10px;
	}	
}
@media (max-width: 768px){
	.StatusDescription .StatusBox{
		width: 90%;
	}
	.TicketTopDetails > div:nth-child(3){
		display: none;
	}
	.ListingSearch{
		display: none;
	}
	.ListingSearch.MobileSearch{
		display: block;
		margin: 20px 0;
		width: 100%;
	}
	.ListingSearch.MobileSearch input{
		display: block;
	}
	.DesktopCheckbox{
		display: none;
	}
	.MobileCheckbox{
		display: inline-block;
	}
	.TicketTopDetailsDiv2 button.Button{
		display: none;
	}
	.TicketTopDetailsDiv1 , .TicketTopDetailsDiv2{
		align-items: unset;
	}
	.TicketTopDetailsDiv1 > div:nth-child(1){
		margin-top: 10px;
	}
	.StatusDescription .StatusBox{
		display: block;
	}
	.StatusDescription .StatusBox > div:nth-child(1){
		width: auto;
		display: block;
	}
	.StatusDescription .StatusBox > div:nth-child(2){
		width: auto;
		display: block;
	}
	.PriceBig1{
		display: block;
	}
	.SalesDetails .Container{
		width: 65%;
	}
	.TicketTopDetailsDiv2{
		align-items: center;
	}
	.ProgressBar .Progress .StepGapArrow img{
		margin: 0 40px;
	}
	.ProgressBar .CheckedStatus.StatusIcon:after{
		padding: 10px;
	}
}

@media (max-width: 650px){
	.StatusDescription .StatusBox{
		width: 90%;
	}
	.TicketTopDetails > div:nth-child(3){
		display: none;
	}
	.ProgressBar .Progress div img{
		height: 30px;
	}
	.ProgressBar .Progress .Step .Text{
		font-size: 14px;
	}
	.ProgressBar .CheckedStatus.StatusIcon{
		padding-left: 0;
	}
	.ProgressBar .CheckedStatus.StatusIcon:after{
		left: 20px;
	}
	.ProgressBar .Progress .Step .Text{
		display: none;
	}
	.ProgressBar .StatusIcon .CheckMark{
		height: 10px;
	}
	.ProgressBar .StepGapFill .CheckMark,.ProgressBar .StepGapComplete .CheckMark{
		height: 10px;
	}
	.StatusDescription{
		margin-bottom: 20px;
	}
	.SalesDetails .Container{
		width: 75%;
	}
	.ProgressBar .StatusIcon{
		width: auto;
	}
	.ProgressBar .Progress .StepGapArrow img{
		height: 15px;
		margin: 0 25px;
	}
	.ExpandCollapseButton{
		font-size: 12px;
		padding: 2px 0 0px 0;
	}
	.ProgressBar .Progress{
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		margin-top: 20px;
	}
	.ProgressBar .Progress .StepGapArrow{
		margin-left: 40px;
	}
	.ProgressBar .CheckedStatus.StatusIcon{
		padding-bottom: 30px;
	}

}
@media (max-width: 550px){
	.ProgressBar .Progress .StepGapArrow{
		margin-left: 30px;
	}
}
@media (max-width: 500px){
	.SalesTop{
		width: 90%;
	}
	.TicketTopDetails div{
		padding: 0 2px;
	}
	.ProgressBar .Progress .StepGap{
		letter-spacing: 10px;
	}
	.ProgressBar .Progress div img{
		height: 25px;
	}
	.ProgressBar .CheckedStatus.StatusIcon:after{
		left: 20px;
	}
	.ProgressBar .Progress .StepGap{
		margin: 0 20px;
	}
	.SalesDetails .Container{
		width: 80%;
	}
	.ProgressBar .Progress .StepGapArrow img{
		height: 15px;
		margin: 0px;
	}
	.ExpandCollapseButton{
		font-size: 8px;
	}
	.ProgressBar .Progress .StepGapArrow{
		margin-left: 50px;
	}
}

@media (max-width: 450px){
	.ProgressBar .Progress .StepGap{
		margin: 0 20px;
	}
	.ProgressBar .Progress .Step > div{
	}
	.TicketTopDetails div label.Big1{
		font-size: 12px;
	}
	.TicketTopDetails div label.Big{
		font-size: 20px;
	}
	.TicketTopDetails{
		padding: 20px 5px 10px 0;
	}
	.ProgressBar .CheckedStatus.StatusIcon{
		padding-right: 0px;
	}
	.SalesDetails .Container{
		width: 85%;
	}
	.SalesTop{
		font-size: 12px;
	}
	.SalesTop .Big{
		font-size: 18px;
	}
	.ProgressBar .Progress{
		margin-top: 10px;
	}
}

@media (max-width: 400px){
	.ProgressBar .StatusIcon{
		position: relative;
	}
	/*.ProgressBar .StatusIcon .CheckMark, .ProgressBar .StepGapFill .CheckMark,.ProgressBar .StepGapComplete .CheckMark{
		top: -15px;
		left: 0;
		position: absolute;
		margin-left: 10px;
	}*/
	.StatusDescription .StatusBox{
		padding: 10px;
	}
	.ProgressBar .CheckedStatus.StatusIcon{
		padding-top: 0;
	}
	.ProgressBar .CheckedStatus.StatusIcon:after{
		left: 10px;
	}
	.ProgressBar .Progress .StepGap{
		margin-left: 5px;
		margin-right: 5px;
	}
	.SalesDetails .Container{
		width: 95%;
	}
	.SalesDetails .EventDetails > div:nth-child(1) div{
		font-size: 12px;
	}

	.SalesDetails .EventDetails .TicketCount{
		font-size: 24px;
	}
	.SalesDetails .EventDetails .TicketText{
		font-size: 12px;
	}
	.SalesDetails .SaleDetails div{
		font-size: 14px;
	}
	.SalesDetails .Extras div{
		font-size: 12px;
	}
	.SalesDetails .PricingInfo{
		font-size: 12px;
	}
	.SalesDetails .PricingInfo span{
		font-size: 14px;
	}
	.SalesDetails .EventDetails > div:nth-child(2){
		padding-left: 5px;
	}
	.SalesDetails .EventDetails .Big1{
		font-size: 14px;
	}
	.ProgressBar .Progress .StepGapArrow{
		margin-left: 30px;
	}
}
@media (max-width: 350px){
	.ProgressBar .Progress .StepGapArrow{
		margin-left: 20px;
	}

}



/* EVENTIM STYLES FROM HERE */
.EventimURLs{
	text-align: left;
}
.EventimURLs .URLItem{
	display: block;
	margin: 10px 0;
}
.EventimURLs .EventimContainer{
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}
.URLItem input{
	border-radius: 10px;
	outline: none;
	border: 0;
	margin-left: 10px;
	border: 1px solid var(--black);
}
.SeeAllText{
	margin-top: 20px;
	color: var(--black);
	text-decoration: underline;
	cursor: pointer;
}
.DisclaimerText{
	margin-top: 20px;
}
.URLBox{
	position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    backdrop-filter: blur(8px);
    height: 100%;
    width: 100%;
    text-align: center;
    overflow: auto;
}
.URLBox .Close{
	cursor: pointer;
	color: var(--black);
	position: absolute;
	top: 5px;
	right: 10px;
	font-size: 40px;
}
.URLBox .WrapBox{
	position: relative;
	width: 50%;
}
.URLBox .WrapBox .URLList{
	text-align: center;
	margin-top: 30px;
}
.URLItem label, .HeadText{
	font-family: "GothamRounded-Bold";
}
.URLBox .WrapBox .URLItem input{
	width: 60%;
}
.URLItem .TicketDownloadButton{
	background: var(--blue);
	outline: none;
	border: 0;
	border-radius: 5px;
	padding: 5px 30px;
	font-family: 'GothamRounded-Medium';
	font-size: 12px;
}
.TicketmasterUploadContainer{
	text-align: center;
}
.TicketmasterUploadContainer label input{
	display: none;
}
.TicketmasterUploadContainer label{
	display: inline-block;
	margin: 20px auto;
	background: var(--blue);
	color: var(--black);
	cursor: pointer;
	border-radius: 20px;
	padding: 10px 30px;
	border: 0;
	outline: none;
	font-family: 'GothamRounded-Medium';
}

.ScreenshotsContainer{
	display: flex;
	align-items: center;
}
.ScreenshotsContainer .ScreenshotItem{
	height: 200px;
	border: 5px solid var(--blue);
	border-radius: 5px;
	margin-right: 10px;
}
.ScreenshotItem img{
	height: 100%;
	width: 100px;
	object-fit: contain;
}
.ChangeURLText{
	margin-top: 10px;
}
.CustomerService{
	color: var(--black);
	cursor: pointer;
	text-decoration: underline;
}

@media (max-width: 768px){
	.EventimURLs .EventimContainer{
		align-items: unset;
		flex-direction: column;
	}
}
@media (max-width: 400px){
	.URLItem label{
		display: flex;
		flex-direction: column;
	}
	.URLItem input{
		margin: 0;
	}
}