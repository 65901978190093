.Login{
}
.RegisterForm{
    text-align: center;
}
.LoginHead{
    text-align: center;
    font-weight: bolder;
}

.Head{
    text-align: center;
    font-family: GothamRounded-Medium;
    font-size: 18px;
    margin: 0;
}
.LoginCont {  
    text-align: center;    
}

.LoginCont div p{
    text-align: center;
}
.LoginCont div p {
    margin: 0;
    height:30px;
    text-align: left;
}

.LoginInput{
    text-align: center;
    border:2px solid var(--blue);   
    border-radius: 8px;
    padding:6px;
    width:95%;
    outline:none;
}
.InputIcon{
    display: block;
    position: absolute;
    right: 10px;
    top: 9px;
    width: 20px;
    cursor: pointer;
    padding-bottom: 6px;
}
.PasswordInputContainer{
    position: relative;
}

.Error{
    border-color: var(--red);
}
.Success{
    border-color: var(--green);
}

.LoginButton , .LoginButton1{
    display: flex;
    justify-content: center;
}
.LoginButton button{
    border: none;
    background-color: var(--blue);
    font-weight: bold;
    padding:12px 36px;
    border-radius: 10px;
    margin-bottom: 0 10px;
}

.LoginButton1 button{
    font-family: GothamRounded-Medium;
    background-color: var(--white);
    width: 75%;
    padding: 20px 0px;
    font-size: 18px;
    margin: bottom;
    border: none;
    border-radius: 10px;
}

.LoginP{
    margin-top: 20px;
    text-align: center;
    font-size: xx-small;
}
.LoginP span a{
    color: var(--blue-dark);
}
.RememberMe label{
    position: relative;
}
.RememberMe label span{
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -3px;
    left: 3px;
    position: absolute;
    background-color: var(--white);
    display: inline-block;
    visibility: visible;
    border: 1px solid var(--blue);  
}
.RememberMe .Radio:checked + span {
    width: 10px;
    height: 10px;
    background-color: var(--blue-dark);
    padding: 2px;
}
.RememberMe input{
    margin-right: 5px;
}
.LoginA {
    color: var(--blue-dark);
}

.LoginA1{
    color: var(--black);
    font-family: GothamRounded-Medium;
 
    text-decoration: underline;
}
.LoginLabel{
    margin-right: 0.6rem;
}
.LoginF{
    font-size: 11px;
    text-align: center;
}
.LoginCont .LoginF{
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}
.LoginF .RememberMe{
    display: inline-block;
    margin: 10px 0;
}
.LoginF .Radio{
    vertical-align: sub;
    visibility: hidden;
}

.ForgotPassword{
    position: fixed;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(8px);
    top: 0;
    left: 0;
    z-index: 9999;
    text-align: center;
}
@-moz-document url-prefix() {
    .ForgotPassword{
      background: rgba(0,0,0,0.8);
    }
}

.ForgotPasswordContainer{
    margin-top: 100px;
}
.ForgotPasswordContainer .WrapBox{
    width: 350px;
    margin: auto;
    background: var(--black);
    border: 2px solid var(--blue);
    border-radius: 20px;
    padding: 20px;
    color: var(--white);
    font-size: 14px;
}

.ForgotPasswordContainer .InputMethod select{
    width: 210px;
    padding: 5px 10px;
    background: var(--black);
    color: var(--white);
    border: 2px solid var(--blue);
    border-radius: 20px;
    margin: 15px 0;
}

.ForgotPasswordContainer .InputMethod input{
    width: 300px;
    padding: 5px 10px;
    background: var(--white);
    border: 2px solid var(--blue);
    border-radius: 5px;
    margin: 15px 0;
    box-sizing: border-box;
}
.ForgotPasswordContainer .ActionButtons{
    margin-top: 10px;
}
.ForgotPasswordContainer .ActionButtons button{
    margin: 0 10px;
    background: var(--blue);
    color: var(--black);
    border: 2px solid var(--blue);
    padding: 7px 25px;
    border-radius: 10px;
    font-family: 'GothamRounded-Bold';
}
.ForgotPasswordContainer .ActionButtons button:hover{
    opacity: 1;
    background: transparent;
    color: var(--blue);
}
.ForgotPasswordContainer .ActionButtons button:nth-child(2):hover{
    background: var(--blue);
    color: var(--black);
}
.ForgotPasswordContainer .ActionButtons button:nth-child(2){
    background: transparent;
    color: var(--blue);
    border: 2px solid var(--blue);
}
@media (max-width: 450px){
    .ForgotPasswordContainer .WrapBox{
        width: 80%;
        margin: auto;
    }
    .ForgotPasswordContainer .ActionButtons{
        display: flex;
        justify-content: space-between;
    }
    .ForgotPasswordContainer .ActionButtons button{
        margin: 0;
        padding: 10px 40px;
    }
    .ForgotPasswordContainer .InputMethod input, .ForgotPasswordContainer .InputMethod select{
        width: 100%;
    }
}

@media (max-width: 450px){
    .ForgotPasswordContainer .ActionButtons button{
        padding: 10px 30px;
    }
}