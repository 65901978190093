.InstagramPosts{
	width: 60%;
	margin: auto;
}

.AboutPopup{
	position: fixed;
	z-index: 99999;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: linear-gradient(0deg, rgba(1, 1, 1, 0.8) 48.38%, rgba(141, 238, 253, 0.08) 143.94%);
}

.AboutPopupBlock{
	position: relative;
	height: 350px;
	width: 60%;
	margin: auto;
	margin-top: 100px;
	background: var(--white);
	padding: 10px;
	border-radius: 25px;
}
.AboutPopupBlock > div{
	display: flex;
	height: 100%;
}
.AboutPopupBlock .Close{
	position: absolute;
	right: 20px;
	top: 20px;
	cursor: pointer;
}
.AboutPopupBlock .Close img{
	height: 15px;
}
.AboutImageSection{
	position: relative;
}
.AboutImageSection .BG{
	height: 100%;
}
.AboutImageSection .BG img{
	height: 100%;
}
.AboutImageSection .Logo{
	position: absolute;
	top: 20px;
	left: 20px;
}
.AboutImageSection .Logo img{
	height: 15px;
	filter: brightness(0) invert(1);
}

.AboutTextSection{
	padding: 15px;
}
.AboutTextSection > div:nth-child(1){
	font-family: GothamRounded-Bold;
	font-size: 24px;
}
.AboutTextSection > div:nth-child(2){
	margin-top: 15px;
	font-family: GothamRounded-Medium;
	font-size: 14px;
}

.AboutInfoImage{
	display: none;
}
.ButtonCoverMobile{
	display: none;
}

.BuySellProcess{
	font-family: GothamRounded-Book;
	max-width: var(--max-size);
}
.BuySellProcessContainer{
	padding: 20px 0;
}

.Header{
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}
.Header .About{
	position: absolute;
	right: 30px;
	background: var(--white);
	padding: 10px 30px;
	border: 2px solid var(--black);
	font-family: 'GothamRounded-Medium';
	border-radius: 25px;
	cursor: pointer;
}
.Header .About:hover{
	background: var(--blue-hover-2);
	border-color: var(--blue-hover-2);
}
.ButtonContainer {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 400px; /* Adjust width as needed */
	height: 43px;
	background-color: #DDFAFF;
	border-radius: 25px;
	overflow: hidden;
}

.Button {
	flex: 1;
	text-align: center;
	z-index: 2; /* Ensure it stays above the slider */
	padding: 12px 0;
	font-size: 16px;
	cursor: pointer;
	color: #000;
	transition: color 0.3s ease;
}

.Button:hover{
	background: var(--blue-hover-2);
	border-radius: 25px;
}
.Button.Selected:hover{
	background: var(--blue-hover);
}

.Slider {
	position: absolute;
	top: 0;
	left: 0;
	width: 50%; /* Half the container width */
	height: 100%;
	background-color: var(--blue-thick); /* The sliding background color */
	border-radius: 25px;
	transition: transform 0.3s ease;
}



.StepsContainer{
	display: flex;
	justify-content: center;
	align-items: flex-start;
	padding: 0 50px;
	width: 1100px;
	margin: auto;
	margin-top: 100px;
	gap: 20px;
}
.Step{
	background: var(--white);
	width: 140px;
	border: 1px solid var(--blue);
	border-radius: 15px;
	padding: 20px;
	position: relative;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 
            0 1px 3px rgba(0, 0, 0, 0.06);
}
.Step:nth-child(even){
	margin-top: 50px;
}
.StepContent .Title{
	font-family: 'GothamRounded-Bold';
}
.StepContent .Title span{
	font-size: 24px;
}
.StepContent .Content{
	margin-top: 20px;
	font-family: 'GothamRounded-Book';
	font-size: 12px;
}
.Content .BulletPoints{
	margin-bottom: 10px;
	position: relative;
}
.Content .BulletPoints::before {
  content: "•  ";
  position: relative;
  left: 0;
  top: 1px;
  font-size: 16px;
}
.HeadIcon{
	position: absolute;
	top: -40px;
	left: 50%;
	transform: translateX(-50%);
	height: 20px;
	width: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 15px;
	border: 1px solid var(--blue);
	border-radius: 15px;
	background: var(--white);
}
.HeadIcon img{
	height: 20px;
	width: auto;
}

.BottomButtonContainer{
	display: flex;
	margin-top: 100px;
	justify-content: center;
}
.SellTicketsButton, .BuyTicketsButton{
	padding: 12px 40px;
	font-family: 'GothamRounded-Medium';
	font-size: 14px;
	background: var(--blue-thick);
	border-radius: 25px;
	cursor: pointer;
}
.SellTicketsButton:hover, .BuyTicketsButton:hover{
	background: var(--blue-hover-2);
}
.BuyTicketsButton{
	background: var(--blue);
}
.SelectedTicketsTitle{
	display: flex;
	font-family: 'GothamRounded-Medium';
	font-size: 18px;
	justify-content: center;
	margin-top: 30px;
}

.BackgroundLines{
	position: relative;
	width: 1000px;
	margin: auto;
}
.BackgroundLines img{
	position: absolute;
	top: -20px;
}
.BackgroundLines img:nth-child(1){
	left: 24px;
}
.BackgroundLines img:nth-child(2){
	left: 396px;
}
.BackgroundLines img:nth-child(3){
	left: 770px;
}
.BackgroundLines img:nth-child(4){
	left: 215px;
	transform: rotate(180deg);
	top: 280px;
}
.BackgroundLines img:nth-child(5){
	left: 585px;
	transform: rotate(180deg);
	top: 280px;
}
.BackgroundLines img:nth-child(6){
	left: 415px;
	top: 180px;
}
.BackgroundLines img:nth-child(7){
	left: 785px;
	top: 180px;
}


.HighlightContainer{
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	background: #0000008A;
	z-index: 999999999;
	overflow: hidden;
}
.Highlight{
	position: relative;
	width: 100%;
	top: 285px;
	text-align: center;
}


@media (max-width: 1200px){
	.AboutPopupBlock{
		width: 80%;
	}
	.BackgroundLines{
		display: none;
	}
	.StepsContainer{
		width: auto;
		gap: 40px;
	}
}
@media (max-width: 1024px){
	.Header{
		flex-direction: column-reverse;
	}
	.Header .About{
		position: relative;
		right: unset;
		margin-top: 10px;
		padding: 10px 47px;
		width: 230px;
		box-sizing: border-box;
		text-align: center;
	}
	.ButtonContainer{
		margin-top: 30px;
		width: 230px;
		border: 2px solid var(--blue-thick);
		height: 35px;
	}
	.ButtonContainer .Button{
		font-size: 13px;
		font-family: GothamRounded-Medium;
	}
	.ButtonContainer .Button.Selected{
	}
	.ButtonContainer .Slider{
	}
	.ButtonCoverMobile{
		display: block;
		position: absolute;
		width: 240px;
		height: 70px;
		top: 60px;
		text-align: center;
		font-size: 13px;
		font-family: 'GothamRounded-Medium';
		border-radius: 25px;
		background: #DDFAFF;
	}
	.ButtonCoverMobile div{
		margin-top: 3px;
	}

	.StepsContainer{
		flex-wrap: wrap;
		margin-top: 50px;
	}
	.Step:nth-child(even){
		margin-top: 20px;
	}
	.Step{
		margin-top: 20px;
	}
	.AboutInfoImage{
		display: block;
		position: absolute;
		right: 5px;
		top: 8px;
	}
	.StickyButton{
		position: fixed;
		bottom: 80px;
		left: 50%;
		transform: translateX(-50%);
		box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 
            0 1px 3px rgba(0, 0, 0, 0.06);
    border-radius: 25px;
    white-space: nowrap;
    z-index: 99;
	}

}
@media (max-width: 768px){
	.InstagramPosts{
		width: 80%;
	}
	.AboutPopup{
		overflow: auto;
	}
	.AboutPopupBlock > div{
		flex-direction: column;
	}
	.AboutPopupBlock{
		height: auto;
		margin-top: 20px;
		width: 95%;
	}
	.AboutImageSection .BG{
		width: 100%;
		height: 180px;
	}
	.AboutImageSection .BG img{
		height: 100%;
		width: 100%;
		border-radius: 25px;
		object-fit: cover;
		object-position: 0px -150px;
	}
	.AboutPopupBlock .Close{
		z-index: 1;
	}
	.AboutPopupBlock .Close img{
		filter: invert(1);
	}
	.StepsContainer .Step{
		width: 100%;
	}
	.StepsContainer{
		padding: 0 20px;
	}
	.Highlight{
		top: 268px;
	}
}

@media (max-width: 475px){
	.InstagramPosts{
		width: 90%;
	}
	.AboutImageSection .BG img{
		object-position: 0px -50px;
	}

}