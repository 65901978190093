.InnerContainer{
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 99999999999;
    background-color: rgba(0, 0, 0, 0.8);
    top: 0;
    bottom: 0;
    left: 0;
}
.MainContainer,.ModelContainer{
    opacity: 1;
    width: 95%;
    margin: 15px auto 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
   height: 95%;
   position: relative;
}
.MainContainer .ArtImage{
    width: auto;
    height: 100%;
    text-align: center;
}
.MainContainer .ArtImage img{
    height: 100%;
    text-align: center;
    object-fit: contain;
}
.MainContainer .ArtImage video{
    margin: 15px 0 0 0;
    width:100%; 
    height:95%;
}
.ArrowContainer img{
    position: absolute;
    top: 10px;
    right: 0px;
    width: 20px;
    height: auto;
   cursor: pointer;
   filter: invert(1);
}