/* Buy Tickets */
.BuyTickets .Title{
	color: var(--black);
	font-family: 'GothamRounded-Medium';
	font-size: 20px;
	display: inline-block;
	padding: 0 30px 10px 30px;
	border-bottom: 1px solid var(--black);
}
.BuyTickets{
	position: relative;
}
.BuyTickets .Title img{
	height: 25px;
	position: absolute;
	left: 0;
	cursor: pointer;
	filter: brightness(0);
}
.BuyTickets .Container{
	width: 450px;
}
.TicketOwner{
	margin: 20px 0;
}
.TicketOwner .OwnerImage img{
	height: 50px;
	vertical-align: middle;
}
.TicketOwner .OwnerInfo,
.TicketOwner .OwnerImage{
	display: inline-block;
	margin-right: 20px;
	vertical-align: middle;
}
.TicketOwner .OwnerInfo{
	font-family: 'GothamRounded-Medium';
	text-align: left;
	font-size: 14px;
}
.TicketOwner .OwnerInfo div:nth-child(1){
	color: var(--black);
}
.TicketOwner .OwnerInfo div:nth-child(2){
	color: var(--black);
}
.TicketOwner .OwnerInfo div:nth-child(2) img{
	height: 15px;
	vertical-align: middle;
	filter: brightness(0);
}


.Ticket{
	border: 2px solid var(--black);
	border-radius: 20px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: right 10px;
	text-align: left;
	position: relative;
}
.Ticket .Title{
	color: var(--black);
	background: var(--blue);
	display: block;
	border-top-left-radius: 18px;
	border-top-right-radius: 18px;
	font-size: 12px;
	padding: 3px 0;
	letter-spacing: 2px;
	font-family: 'GothamRounded-Bold';
	text-align: center;
	margin-top: -1px;
}


.TicketInfo{
	display: inline-block;
	margin: 10px 0 10px 20px;
	padding-right: 20px;
	border-bottom: 1px solid var(--black);
}
.TicketInfo div{
	margin: 8px 0;
}
.TicketInfo .EventName{
	font-family: 'GothamRounded-Bold';
	width: 85%;
}
.TicketInfo .EventLocation{
	font-family: 'GothamRounded-Book';
	font-size: 14px;
}

.TicketTimings{
	margin: 10px 0 10px 20px;
}
.TicketTimings div{
	font-size: 14px;
	margin: 5px 0;
}
.TicketPrice{
	margin: 20px;
	text-align: left;
	font-size: 14px;
}
.TicketPrice > div{
	display: inline-block;
	vertical-align: middle;
}
.TicketPrice .Price{
	font-size: 18px;
	font-family: 'GothamRounded-Bold';
	position: absolute;
	right: 20px;
}
.BuyButton{
	margin: 20px 0;
}
.BuyButton button{
	padding: 15px 40px;
	color: var(--black);
	background: var(--blue);
	border-radius: 10px;
	outline: none;
	border: 0;
	font-family: 'GothamRounded-Bold';
}
.ShipperInfo{
	margin: 20px 0;
	font-size: 14px;
	font-family: 'GothamRounded-Medium';
}
.MobileShipperInfo{
	display: none;
}
.Agreement{
	width: 70%;
	font-size: 12px;
	text-align: center;
	margin: auto;
}
.Agreement a{
	color: var(--black);
	text-decoration: underline;
}
.Quantity{
	text-align: center;
	color: var(--black);
	position: absolute;
	right: 25px;
	top: 60px;
}
.Quantity .Number{
	font-size: 28px;
	font-family: 'GothamRounded-Bold';
}
.Quantity .Number div{
	font-size: 14px;
}

.LoadingIcon img{
	height: 40px;
	margin: 20px 0;
	animation: Rotate linear infinite 2s;
}

@keyframes Rotate{
	from {
		transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
	}
}
.LoadingIcon{
	margin-top: 30px;
}
.LoadingIcon .Heading{
	color: var(--blue);
	font-size: 14px;
	font-family: 'GothamRounded-Bold';
	margin: 5px 0;
}
.LoadingIcon .SubHeading{
	font-size: 14px;
	font-family: 'GothamRounded-Book';
}
/* Buy Tickets */



.MobileTicket{
	text-align: center;
	position: relative;
	display: none;
}
.MobileTicket .Title{
	color: var(--blue);
	display: inline-block;
	font-size: 12px;
	padding: 10px 50px;
	letter-spacing: 2px;
	font-family: 'GothamRounded-Bold';
	text-align: center;
	margin-top: -1px;
	border: 0;
	border-top: 1px solid var(--blue);
}


.MobileTicket .TicketInfo{
	display: block;
	margin: 0;
	padding: 0;
	border: 0;
}
.MobileTicket .TicketInfo div{
	margin: 8px 0;
}
.MobileTicket .TicketInfo .EventName{
	font-family: 'GothamRounded-Bold';
	margin: 5px auto;
}
.MobileTicket .TicketInfo .EventLocation{
	font-family: 'GothamRounded-Book';
	font-size: 14px;
	display: inline-block;
	padding: 0px 10px;
	margin: 5px 0;
}

.MobileTicket .TicketTimings{
	margin: 0;
}
.MobileTicket .TicketTimings div{
	font-size: 14px;
	margin: 5px;
	display: inline-block;
}


.TicketImage{
	text-align: center;
}
.TicketImage img{
	height: 120px;
	border-radius: 10px;
	background: var(--white);
}
.LoginBeforeProceed{
	color: var(--black);
	font-family: 'GothamRounded-Bold';
	font-size: 18px;
}
.LoginBeforeProceedButton{
	margin-top: 10px;
}
.LoginBeforeProceedButton button{
	color: var(--black);
	background: var(--blue);
	padding: 10px 20px;
}
.TicketImageDisclaimer{
	text-align: center;
	font-size: 12px;
	margin: 5px 0;
}
@media (max-width: 768px){
	.Ticket{
		display: none;
	}
	.MobileTicket{
		display: block;
	}
	.TicketPrice .PriceText{
		display: none;
	}
	.TicketPrice{
		display: block;
		text-align: center;
	}
	.TicketPrice .Price{
		position: relative;
		font-size: 22px;
		right: auto;
		border-bottom: 1px solid var(--blue);
		padding: 10px 60px;
	}
	.ShipperInfo{
		display: none;
	}
	.MobileShipperInfo{
		display: block;
	}
	.BuyTickets .Container{
		width: 100%;
		margin: auto;
	}
}

@media (max-width: 400px){
	.BuyTickets .Title{
		font-size: 16px;
	}
	.TicketOwner .OwnerImage img{
		height: 40px;
	}
	.TicketOwner .OwnerImage{
		margin-right: 5px;
	}
	.TicketOwner .OwnerInfo{
		font-size: 12px;
	}
	.MobileTicket .Title{
		font-size: 12px;
	}
	.MobileTicket .TicketInfo{
		font-size: 14px;
	}
	.MobileTicket .TicketTimings{
		font-size: 14px;
	}
	.TicketPrice{
		margin: 5px;
	}
	.BuyButton button{
		padding: 10px 30px;
		border-radius: 20px;
	}
	.MobileTicket .Title{
		padding: 10px 30px;
	}

}