.TransitionContainer{
	width: 25%;
	/* border: 2px solid green; */
	display: flex;
	align-items: center;
	margin: 20px auto 50px auto;
	justify-content: space-between;
}
.BigHeading p{
    color: var(--black);
}
.OuterPaddingContainer{
    padding: 30px 0 0 0;
}
.StepOneContainer{
	/* border: 2px solid red; */
	display: flex;
	flex-direction: column;
	align-items: center;
	/* border: 2px solid red; */

	width: 10%;
	margin: -21px 0 0px 0;
	
}
.StepTwoContainer,.StepThreeContainer{
	width: 10%;
}
.StepNumberOneTextContainer  p{
	color: var(--blue);
	font-family: 'GothamRounded-Bold';
	font-size: var(--large);
}
.StepNumberOneImageContainer{
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-color: var(--blue);
	position: relative;
	margin: -7px 0 0 0;
	overflow: hidden;
}
.StepNumberOneImageContainer img{
	width: 100%;
	height: auto;
	filter: invert(1) brightness(0);
	position: absolute;
	top:4px;
	right: 10px;
}
.FirstThreeDotsContainer{
	width: 30%;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 25px;
}

.FirstThreeDotsContainer div{
	background-color: var(--blue);
	color: var(--blue);
	width: 8px;
	height: 8px;
	border-radius: 50%;
	/* border: 2px solid blue; */
	opacity: 1;
	margin: 30px 0 0 0;
}

.StepTwoImageContainer{
	width: 50px;
	height: auto;
}
.StepTwoImageContainer img{
	width: 100%;
	height: auto;
}
.SecondThreeDotsContainer{
	width: 30%;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 25px;
}
.SecondThreeDotsContainer div{
	background-color: var(--blue);
	color: var(--blue);
	width: 8px;
	height: 8px;
	border-radius: 50%;
	margin: 30px 0 0 0;

}
.StepThreeImageContainer{
	width: 50px;
	height: auto;
}
.StepThreeImageContainer img{
	width: 100%;
	height: auto;
}
.CongratsInfo{
    width: 60%;
    margin: 30px auto 40px auto;
 }
.CongratsInfo p{
    color: var(--black);
    font-size: var(--S-small);
}
.CongratsInfo a{
	color: var(--black);
	text-decoration: underline;
}