.TeamDiv1 {
    width: 80%;
    margin: 20px auto;
    height: 300px;
    font-family: GothamRounded-Book;
}
.TeamDiv1 img{
    max-width: 100%;
    height: 100%;
    border-radius: 20px;
}
.TeamHead {
    font-size: 22px;
    color: var(--black);
}

.TeamPhotoMain {
    width: 60%;
    margin: auto;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
}

.TeamPhotoCont {
    width: 190px;
    margin: 20px auto;
    background-color: var(--blue);
    padding: 10px;
    border-radius: 20px;
}

.TeamPhoto {
    border-radius: 20px;
    height: 190px;
    background-color: var(--black);
}

.TeamPhoto img{
    height: 190px;
object-fit: cover;
width: 190px;
object-position: center;
border-radius: 10px;

}

.TeamPhoto .Text {
    padding-top: 70px;
}

.TeamPhoto .Text{
    color: var(--black);
}

.TeamPhotoName {
    margin: 0;
    margin-top: 10px;
}

.TeamHr {
    background-color: var(--black);
    height: 2px;
    width: 30%;
    margin: 35px auto;
}
p.AddToTeamLink{
    margin: 50px 0;
}
p.AddToTeamLink a{
    color: var(--black);
    text-decoration: underline;
}

@media(max-width: 1200px){
    .TeamDiv1 {
        width: 100%;
        height: auto;
    }
    .TeamDiv1 img{
        max-width: 100%;
        height: auto;
        border-radius: 0px;
    }
}
@media(max-width:768px){
    .TeamHead{
        display: none;
    }
    .TeamDiv1{
        margin-top: 20px;
    }
    .TeamPhotoMain {
        width: 90%;
    }
}

@media(max-width: 500px){
    .TeamPhotoCont {
        width: 140px;
    }
    .TeamPhoto {
        height: 140px;
    }
    .TeamPhoto img{
        height: 140px;
        width: 140px;
    }
    .TeamPhotoMain {
        width: 100%;
        box-sizing: border-box;
        padding: 10px;
    }
}