.Deregister{
    position: fixed;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(8px);
    top: 0;
    left: 0;
    z-index: 9999;
    text-align: center;
}
@-moz-document url-prefix() {
    .Deregister{
      background: rgba(0,0,0,0.8);
    }
}

.DeregisterContainer{
    margin-top: 100px;
}
.DeregisterContainer .WrapBox{
    width: 350px;
    margin: auto;
    background: var(--black);
    border: 2px solid var(--blue);
    border-radius: 20px;
    padding: 20px;
    color: var(--white);
    font-size: 14px;
}
.DeregisterContainer .WrapBox .Text{
    margin: 10px 0;
    font-size: 16px;
    font-family: 'GothamRounded-Medium';
}
.DeregisterContainer .WrapBox .Text a{
    color: var(--blue);
}
.DeregisterContainer .WrapBox .Text div{
    font-size: 12px;
    margin-top: 10px;
    font-family: 'GothamRounded-Light';
}
.DeregisterContainer .InputMethod input{
    width: 300px;
    padding: 5px 10px;
    background: var(--white);
    border: 2px solid var(--blue);
    border-radius: 5px;
    margin: 5px 0;
    box-sizing: border-box;
}
.DeregisterContainer .ActionButtons{
    margin-top: 10px;
}
.DeregisterContainer .ActionButtons button{
    margin: 0 10px;
    background: var(--blue);
    color: var(--black);
    border: 2px solid var(--blue);
    padding: 7px 25px;
    border-radius: 10px;
    font-family: 'GothamRounded-Bold';
}
.DeregisterContainer .ActionButtons button:hover{
    opacity: 1;
    background: transparent;
    color: var(--blue);
}
.DeregisterContainer .ActionButtons button:nth-child(2):hover{
    background: var(--blue);
    color: var(--black);
}
.DeregisterContainer .ActionButtons button:nth-child(2){
    background: transparent;
    color: var(--blue);
    border: 2px solid var(--blue);
}
@media (max-width: 450px){
    .DeregisterContainer .WrapBox{
        width: 80%;
        margin: auto;
    }
    .DeregisterContainer .ActionButtons{
    }
    .DeregisterContainer .ActionButtons button{
        margin: 0 5px;
        padding: 10px 40px;
    }
    .DeregisterContainer .InputMethod input, .DeregisterContainer .InputMethod select{
        width: 100%;
    }
}

@media (max-width: 450px){
    .DeregisterContainer .ActionButtons button{
        padding: 10px 30px;
    }
}