.AppBackground{
	/*background: url('assets/images/background/light_background.jpg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: fixed;*/
	background: linear-gradient(180deg, #FFFFFF 11.8%, #DEFFCE 87.7%);
}

.AppFront{
	min-height: 100vh;
	max-width: var(--max-size);
	margin: auto;
}