.Jumbotron{
	text-align: center;
  /* min-height: 100vh; */
	font-family: 'GothamRounded-Book';
	/* background: url('../../assets/images/background/landingpage_bg.jpg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: fixed; */
}

.Jumbotron2{
  display: flex;
  justify-content: space-around;
  padding-top: 160px;
  padding-bottom: 60px;
  background: url('../../assets/images/background/sell_tickets_banner.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
hr{
  width: 50%;
  border: 1px solid var(--black);
}
.LogoContainer{
    margin-bottom: 20px;
}
.LogoContainer span{
  color: var(--white);
  font-family: 'AllRoundGothic-Medium';
  font-size: 35px;
  vertical-align: middle;
  margin-right:  10px;
}
.Logo{
	height: 25px;
  vertical-align: middle;
  cursor: pointer;
  filter: brightness(0) invert(1);
}

.KaufmeinticketUSP{
  display: flex;
  justify-content: center;
  margin: 50px;
  margin-top: 150px;
  position: relative;
}
.USP{
  position: relative;
  border: 0.71px solid #00000033;
  border-radius: 20px;
  text-align: left;
  margin: 0 15px;
  height: 230px;
  width: 27%;
  background: var(--white);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 
            0 1px 3px rgba(0, 0, 0, 0.06);
}
.USP .USPIcon, .USP .USPText{
  display: inline-block;
}
.USP .USPIcon{
  display: none;
  width: 30%;
  height: -moz-available;          /* WebKit-based browsers will ignore this. */
  height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  height: fill-available;
  background: var(--blue);
  border-top-left-radius: 17px;
  border-bottom-left-radius: 17px;
  padding: 25px 0;
  text-align: center;
}
.USP .USPText{
  padding: 0 20px;
  box-sizing: border-box;
  vertical-align: top;
  color: var(--black);
}
.USP .USPText div:nth-child(1){
  margin: 20px 0;
  font-family: 'GothamRounded-Medium';
  font-size: 20px;
}
.USP .USPText div:nth-child(2){
  color: var(--black);
  font-family: 'GothamRounded-Light';
  font-size: 14px;
  line-height: 1.5;
}
.USP .USPIcon img{
  margin-top: 25%;
  width: 100%;
  max-height: 100px;
  max-width: 100px;
  padding: 5px;
  box-sizing: border-box;
  filter: brightness(0);
}

.USP .USPIconMobile{
  background: var(--blue);
  border-top-right-radius: 17px;
  border-bottom-right-radius: 17px;  
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.USP .USPIconMobile{
  display: none;
}

.ConnectingBorder{
  position: absolute;
  top: -47px;
  width: 650px;
  border-top: 2px solid var(--blue);
}

.FloatingIcon{
  position: absolute;
  left: 50%;
  top: -70px;
  transform: translateX(-50%);
}
.FloatingIcon img{
  padding: 15px;
  width: 15px;
  height: 15px;
  background: #E2FBFF;
  border-radius: 50%;
  border: 0.7px solid var(--blue);
}

.SellTicketBtn{
  font-family: 'GothamRounded-Bold';
  background: transparent;
  border-radius: 20px;
  color: var(--black);
  border: 2px solid var(--black);
  outline: none;
  padding: 8px 25px;
  transition: 0.6s;
}
.SellTicketBtn:hover{
  box-shadow: inset 12em 0 0 0 rgba(141, 238, 253, 0.5);
  background: var(--blue);
  color: var(--black);
  opacity: 1;
}

.Help{
  color: var(--white);
  font-size: 14px;
}
.Help div{
  margin-top: 15px;
  color: var(--black);
}
.Help a{
  color: var(--black);
  text-decoration: underline;
}

.HelpingButtons{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.HelpingButtons .Button{
  padding: 15px 35px;
  background: var(--blue);
  border-radius: 25px;
  font-size: 12px;
  cursor: pointer;
  font-family: 'GothamRounded-Medium';
}
.HelpingButtons .Button:hover{
  background: var(--blue-hover-2);
}


.HighlightContainer{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: #0000008A;
  z-index: 999999999;
  overflow: hidden;
}
.Highlight{
  position: relative;
  width: 100%;
  top: 220px;
  text-align: center;
}



@media (max-width: 1100px){
  .USP{
    margin: 0 10px;
  }
  .USP .USPText{
    padding: 0 10px;
  }
  .USP .USPIcon{
    padding: 30px 0;
  }
  .KaufmeinticketUSP{
  }
}

@media (max-width: 950px){
  .KaufmeinticketUSP{
    display: block;
  }
  .USP{
    width: 350px;
    margin: 50px auto;
    border-color: var(--blue);
  }
  .USPText{
    margin-top: 20px;
  }
  hr{
    width: 70%;
  }
  .USPIcon.MobileHide{
    display: none;
  }
  .USPIcon.USPIconMobile{
  }
  .FloatingIcon{
    top: -24px;
  }
}

@media (max-width: 470px){
  .Logo{
    display: block;
    margin: 10px auto;
  }
  .LogoContainer span{
    font-size: 28px;
    font-family: 'GothamRounded-Book';
    margin: 0 auto;
  }
  .KaufmeinticketUSP{
    margin:0 auto;
  }
  .Highlight{
    top: 248px;
  }
}
@media (max-width: 400px){
  .USP{
    width: 280px
  }
  .USP .USPText div:nth-child(1){
  }
}

@media (min-width: 1200px){
  .USP{
    width: 300px;
  }
}
@media (min-width: 1400px){
  .USP .USPIcon img{
    margin-top: 20%;
  }
}
@media (min-width: 1750px){
  .USP .USPIcon img{
    margin-top: 16%;
  }
}

/* JUST FOR CONNECTING BORDER LINE */
@media (max-width: 1200px){
  .ConnectingBorder{
    width: 600px;
  }
}
@media (max-width: 1100px){
  .ConnectingBorder{
    display: none;
  }
}
/* JUST FOR CONNECTING BORDER LINE */