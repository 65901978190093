.Block{
	background: var(--white);
	border-radius: 10px;
	border: 1px solid var(--black);
	width: 45%;
	margin: auto;
	margin-top: 60px;
}

@media (max-width:850px) {
	.Block{
		width: 90%;
	}
}