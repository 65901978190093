.SearchContainer{
	/* margin-top: 10px; */
	width: max-content;
	margin: auto;
}

.SearchIcon img{
	background: var(--blue-thick);
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	border: 0px solid var(--blue);
	height: 16px;
	padding: 12px 10px;
	vertical-align: middle;
	cursor: pointer;
	border-left: 0px;
}

/* SEARCH INPUT */
.SearchInput{
	border: 0px solid var(--blue);
	width: 500px;
	padding: 5px;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
	border-right: 0px;
	height: 30px;
	outline: none;
	vertical-align: middle;
	text-align: center;
	transition: 0.6s;
}
.SearchInput::placeholder{
	padding-left: 30px;
	font-size: 15px;
	font-family: 'GothamRounded-Book';
}


.HeaderAbsolute{
	position: absolute;
	left: 55%; 
	top: 18px;
	z-index: -1;
}

.SearchInput.Header{
	/* width: auto;
	text-align: left; */
}
.SearchInput.Header::placeholder{
	text-align: center;
}
.SearchContainer.Header{
	margin: 0;
	position: relative;
	left: -75%;
}

.SearchDrop , .SearchDrop1{
	background-color: var(--white);
	max-height: 50vh;
	height: 50vh;
	overflow-y: scroll;
	width: 545px;
	margin: auto;
	transition: 0.6s;
}
.SeeAllButton{
	color: var(--blue-dark);
	background: transparent;
	font-size: 16px;
}
.SearchDrop1{
	width: 450px;
}
.SearchNames{
	margin: 0px 20px;
	margin-top: 30px;;
	text-align: left;
	padding-bottom: 10px;
	border-bottom: 1px solid var(--gray);	
}
.SearchNames1{
	margin-left: 40px;
	text-align: left;
}
.SearchNames1{
	margin: 0px;
	padding: 10px;
	padding-left: 40px;
	text-align: left;
	color: var(--black);
	overflow: hidden;
}
.SearchNames1:hover{
	/*background: var(--blue);*/
	border: 1px solid var(--black);
	margin: 0 2px;
	cursor: pointer;
	font-family: 'GothamRounded-Bold';
}
.SearchNames1:hover .SubList{
	font-family: 'GothamRounded-Book';
}
.SearchNames1.SubList:hover{
	background: var(--blue);
	border: 1px solid var(--black);
	margin: 0 2px;
	cursor: pointer;
}
.SearchNames1:hover a{
	color: var(--black);
}
.SearchNames1 a{
	color: var(--gray);
}
.SearchNames a {
	color: var(--black);
}
.SearchNames1 .Count{
	float: right;
	color: var(--blue-dark);
	font-weight: bold;
}
.SearchNames1 .Count button{
	padding: 3px 20px;
	margin-right: 3px;
}

.animated {
	-webkit-animation-duration: 0.3s;
	animation-duration: 0.3s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}
.EventTitle{
	margin: 5px 0;
}
@-webkit-keyframes fadeIn {
	0% {opacity: 0;}
	100% {opacity: 1;}
}

@keyframes fadeIn {
	0% {opacity: 0;}
	100% {opacity: 1;}
}

.fadeIn {
	-webkit-animation-name: fadeIn;
	animation-name: fadeIn;
}
@media(max-width: 1070px){
	.SearchNames1 .Count{
		float: none;
		display: block;
		margin: 5px 0;
	}

}
@media(max-width:680px)
{
	.SearchInput{
		width: 380px;
		transition: 0.6s;
	}
	.SearchDrop{
		width: 430px;
		transition: 0.6s;
	}
	.SearchInput::placeholder{
		padding-left: 0px;
	}
}

@media(max-width:470px)
{
	.SearchInput{
		width: 220px;
		transition: 0.6s;
	}
	.SearchDrop{
		width: 265px;
		transition: 0.6s;
	}
	.SearchInput::placeholder{
		font-size: 9px;
	}
}

@media(max-width:270px)
{
	.SearchInput{
		width: 100px;
		transition: 0.6s;
		
	}
	.SearchDrop{
		width: 150px;
		transition: 0.6s;
		font-size: smaller;
	}
}

/*.FilterSelect{
	width: 18%;
	padding: 5px;
	border-radius: 5px;
	height: 33px;
	outline: none;
	vertical-align: middle;
	text-align: center;
	margin: 0 20px;
}
.FilterSelect option:hover {
    box-shadow: 0 0 10px 100px #1882A8 inset;
}

.FilterSelect:focus{
	border: 2px solid var(--blue);
}


.AdvancedSearchContainer{
	color: var(--blue);
	font-family: 'GothamRounded-Medium';
	margin: 25px 0;
}

.AdvancedSearch{
	text-decoration: underline;
	cursor: pointer;
}

.AdvancedSearchButton{
	padding: 9px 0px;
	height: 15px;
	box-sizing: unset;
	width: 18%;
	background: var(--blue);
	outline: none;
	border: 0;
	border-radius: 5px;
	vertical-align: middle;
	cursor: pointer;
}
.AdvancedSearchButton img{
	height: 15px;
	vertical-align: middle;
	margin-left: 10px;
}
*/