.Inner{
    background-color: var(--white);
    color: var(--black);
    width: 280px;
    margin: auto;
    
    border: 2px solid var(--black);
    border-radius: 15px;
    padding: 30px 10px;
  margin-top: 200px;
}
.Inner p:first-of-type{
    color: var(--black);
    
    
}
.Inner p:last-of-type{
    font-family: GothamRounded-Light;
    font-size: 13px;
}