.SocialSignup{
	text-align: center;
	margin: 10px auto;
}
.SocialSignup .SocialIcon{
	margin: 10px;
	cursor: pointer;
}
.SocialSignup .SocialIcon img{
	height: 35px;
	
}

.Black{
	filter:  brightness(0) invert(0);
}

.PaypalLabel, .FacebookLabel, .GmailLabel{
    visibility: hidden;
    position: absolute;
    margin-top: -25px;
    margin-left: -10px;
    background-color: rgba(0,0,0,0.9);
    color: var(--white);
    padding: 5px 20px;
    border-radius: 10px 10px 10px 0px;
    font-size: small;
    border: 1px solid var(--white);
}
.SocialIcon:hover + .Label{
    visibility: visible;
}