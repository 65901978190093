.OrdersSearch{
    width: 480px;
    margin: auto;
}
.OrdersSearch input{
    width: 100%;
}
.TitleText{
    color: var(--black);
    font-family: 'GothamRounded-Medium';
    margin: 20px 0;
}
.UrlContainer{
    display: flex;
    text-align: left;
    align-items: flex-start;
    gap: 20px;
    justify-content: space-around;
    flex-direction: column;
}
.UrlsUpperContainer{
    display: flex;
    /* width: 100%; */
    flex-direction: column;
    padding: 0px 60px 20px 60px;
    justify-content: space-between;
}
.UrlsUpperContainer div{
    gap: 5px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.UrlsUpperContainer p{
    margin: 0px;
    font-size: smaller;
}
.BackButton{
    
    align-self: flex-end;
}
.Urls {
    border: 2px solid var(--blue);
    padding: 20px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: 5px;
    box-sizing: border-box;
}
.LowerContainer{
    padding: 30px  60px 0px 60px;
    border-top: 2px solid var(--blue);
}
.LowerContainerTicket{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.Urls span,.UrlsUpperContainer span{
    color: var(--black);
    font-family: 'GothamRounded-Bold';
    font-size: medium;
}
.Urls p{
    margin: 0px;
    font-size: smaller;
}
.Urls div{
    gap: 10px;
    display: flex;
    flex-direction: column;
}
.UrlCopy{
    cursor: pointer;
}
.UrlCopy img{
    transform: rotateY(180deg);
    height: 30px;
}
.UrlContainer span{

}
.UrlContainer span{
    
}
.MoreInfoMobileTickets{
    color: var(--black);
    margin-right: 10px;
    font-size: 13px;
    cursor: pointer;
}
.MoreInfoMobileTickets img{
    height: 10px;
    margin-right: 5px;
    transform: rotate(180deg);
    filter: brightness(0);
}
.MoreInfoMobileTickets img.Rotate180{
    transform: unset;
}

.ActionButtons p{
    font-family: 'GothamRounded-Medium';
}
.ConfirmImage{
    display: flex;
    gap: 50px;
    cursor: pointer;
}
.TitleTicketMaster{
    padding: 0px 0px 30px 0px;
}
.TitleTicketMaster p{
    text-align: start;
    margin: 0px;
    font-family: 'GothamRounded-Bold';
}
.ConfirmImageDiv img{
    width: 40px;
    height: 40px;
}
.ConfirmImageDiv{
    align-items: center;
    cursor: pointer;
    justify-content: center;
    display: flex;
    height: 160px;
    width: 110px;
    border-radius: 5px;
    background: var(--white);
    border-top: 20px solid var(--blue);
    border-bottom: 20px solid var(--blue);
    border-right: 10px solid var(--blue);
    border-left: 10px solid var(--blue);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.Orders{
    padding-bottom: 20px;
}
.Orders .Order{
    border: 2px solid var(--black);
    width: 80%;
    margin: auto;
    margin-top: 20px;
    border-radius: 20px;
    position: relative;
}
.Orders .SuccessBorder{
    border-color: var(--l-green);
}
.Orders .FailBorder{
    border-color: var(--pink);
}
.Order .EventPicture{
    width: 10%;
    display: inline-block;
    vertical-align: middle;
}
.Order .EventPicture{
    display: none;
}
.Order .EventPicture img{
    position: absolute;
    height: 100%;
    width: 10%;
    left: 0;
    top: 0;
    margin-left: -1px;
    object-fit: cover;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
}
.Order .EventDescription{
    display: flex;
    vertical-align: middle;
}
.Order .Title{
    background: var(--white);
    color: var(--black);
    border-bottom: 1px solid var(--black);
    font-family: 'GothamRounded-Bold';
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    margin-top: -1px;
    margin-right: -1px;
}
.EventDescription .Details .EventTitle{
    color: var(--black);
    font-family: 'GothamRounded-Medium';
    display: flex;
    justify-content: space-between;
    font-size: 18px;
}
.EventDescription .Details .OrderId{
    color: var(--black);
    font-size: 14px;
    margin-top: 5px;
    font-family: 'GothamRounded-Medium';
    text-align: left;
}
.EventDescription .Details .OrderId span{
    color: var(--black);
}

.EventDescription .Details .EventTitle div:nth-child(2){
    margin-right: 40px;
}
.EventDescription .Details{
    width: 90%;
    display: inline-block;
    padding: 30px;
    padding-bottom: 5px;
    box-sizing: border-box;
    background: var(--white);
    border-bottom-left-radius: 20px;
}
.EventDescription .Location{
    font-size: 14px;
    color: var(--black);
    font-family: 'GothamRounded-Book';
    display: flex;
    justify-content: space-between;
    text-align: left;
    margin-top: 10px;
}
.EventDescription .Location > div > div{
    margin-top: 5px;
}
.EventDescription .Location > div:nth-child(2){
    margin-right: 50px;
}
.EventDescription .Location .TicketCount{
    text-align: center;
    font-size: 28px;
    font-family: 'GothamRounded-Bold';
}
.EventDescription .EventStatus{
    width: 10%;
    display: inline-block;
}
.Buttons{
    display: flex;
    justify-content: space-between;
}
.Buttons .ActionButtons{
    text-align: left;
    margin: 20px 0;
}
.Buttons .ActionButtons img{
    height: 20px;
    vertical-align: middle;
}
.Buttons .ActionButtons button{
    margin-right: 20px;
    padding: 14px 25px;
    border-radius: 15px;
    font-size: 13px;
}
.TrackingOrderData.Buttons{
    justify-content: center;   
}
.Disabled{
    opacity: 0.3;
}
.Disabled:hover{
    opacity: 0.3;
    cursor: not-allowed;
}
div.Disabled button{
    opacity: 1;
    cursor: not-allowed;
}

.Buttons .DownloadButton {
    margin: 20px 0;
}
.Buttons .DownloadButton button{
    background: var(--white);
    display: inline-block;
    outline: none;
    border: 2px solid var(--blue);
    padding: 8px 25px;
    border-radius: 10px;
    font-family: 'GothamRounded-Bold';
}
.Buttons .DownloadButton button div{
    display: inline-block;
    vertical-align: middle;
    font-size: 13px;
}
.Buttons .DownloadButton button img{
    height: 20px;
    vertical-align: middle;
}
.Grayscale{
    filter: brightness(0.5);
}
.EventStatus{
    background: var(--blue);
    font-family: 'GothamRounded-Bold';
    border-bottom-right-radius: 20px;
    margin-bottom: -1px;
    margin-right: -1px;
    font-family: 'GothamRounded-Bold';
}
.EventStatus > div{
    margin: 30px 0;
}
.EventStatus .StatusTitle{
    font-size: 20px;
}
.EventStatus .StatusDescription {
    margin-top: 50px;
    font-size: 15px;
}
.EventStatus .StatusDescription img{
    height: 30px;
    filter: brightness(0);
}

.TrackOrder{
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    backdrop-filter: blur(8px);
    height: 100%;
    width: 100%;
    text-align: center;
    overflow: auto;
}
@-moz-document url-prefix() {
    .TrackOrder{
      background: rgba(0,0,0,0.8);
    }
}

.TrackOrderContainer{
    width: 80%;
    margin: auto;
}
.WrapBox{
    background: var(--white);
    border: 2px solid var(--black);
    border-radius: 20px;
    color: var(--black);
    padding: 20px;
    margin: 20px auto;
    width: 80%;
}

.TrackOrder .Title div{
    display: inline-block;
    color: var(--black);
    font-family: 'GothamRounded-Medium';
    margin: 20px 0;
    padding: 10px 30px;
    border-bottom: 1px solid var(--black);
}

.TrackOrder .SubHeading{
    color: var(--black);
    font-family: 'GothamRounded-Medium';
    padding: 10px 30px;
    font-size: 16px;
}
.TrackOrder .SubHeading div{
    display: inline-block;
    padding: 2px 5px;
}
.TrackOrder .SubHeading div:nth-child(1){
    border-right: 2px solid var(--black);
}
.TrackOrder .SubHeading span{
    color: var(--blue-dark);
}
.TrackOrder .SubHeading.MobileTicketReach{
    display: none;
}
.TrackOrder .SubHeading.DesktopTicketReach{
    display: block;
}
.TrackOrder .Support{
    color: var(--white);
    font-size: 13px;
    margin: 20px 0;
}
.TrackOrder .Support span{
    opacity: 0.8;
}
.TrackOrder .Support a{
    color: var(--blue);
    font-size: 14px;
    text-decoration: underline;
}
.TrackOrder .MobileSupport{
    display: none;
}
.TrackOrder .MobileSupport span{
    display: block;
    font-size: 12px;
    margin-top: 5px;
}
.TrackOrder .MobileSupport a{
    font-size: 12px;
}
.TrackOrderDetails{
    display: flex;
    text-align: left;
    align-items: center;
    justify-content: space-around;
}
.TrackOrderContainer .WrapBox{
    position: relative;
}
.OrderShippingAddress .WrapBox{
    position: relative;
}
.OrderShippingAddress .Title, .OrderPostDetails .Title{
    color: var(--black);
    font-family: 'GothamRounded-Medium';
    font-size: 14px;
    margin-bottom: 10px;
    line-height: 1.4;
}
.OrderPostDetails .Title a{
    color: var(--blue);
}
.OrderShippingAddress .WrapBox > div:nth-child(2),.OrderShippingAddress .WrapBox > div:nth-child(3){
    opacity: 0.8;
    font-size: 12px;
    margin-top: 15px;
}
.OrderShippingAddress .CollapseButton{
    position: absolute;
    right: 10px;
    top: 10px;
    display: none;
}
.OrderShippingAddress .CollapseButton img{
    height: 30px;
    filter: brightness(0);
}
.OrderShippingAddress .CollapseButton.ExpandButton img{
    transform: rotateX(180deg);
    -webkit-transform:  rotateX(180deg);
}
.OrderPostDetails .SubDetails{
    font-family: 'GothamRounded-Book';
    font-size: 12px;
}
.OrderPostDetails .SubDetails .Date{
    margin-top: 15px;
    margin-bottom: 10px;
}
.OrderPostDetails .WrapBox{
    height: 220px;
    overflow: auto;
}
.OrderPostDetails .SubDetails .DetailDescription{
    display: flex;
    line-height: 1.4;
}
.OrderPostDetails .SubDetails .DetailDescription div:nth-child(2){
    opacity: 0.8;
}
.OrderCurrentStatus{
    font-size: 14px;
    font-family: 'GothamRounded-Book';
    line-height: 1.7;
}
.OrderCurrentStatus .Dot{
    padding: 3px;
    background: var(--gray);
    border-radius: 50%;
    display: inline-block;
}
.OrderCurrentStatus .State.Active{
    color: var(--black);
}
.OrderCurrentStatus .State img{
    display: none;
}
.OrderCurrentStatus .State.Active img{
    display: inline-block;
    height: 15px;
    filter: brightness(0);
}
.OrderCurrentStatus .Process.Active{
    color: var(--white);
}
.OrderPostDetails .WrapBox{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

/* width */
.OrderPostDetails .WrapBox::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.OrderPostDetails .WrapBox::-webkit-scrollbar-track {
  /*box-shadow: inset 0 0 5px var(--gray);*/
  border-radius: 20px;
  background: transparent;
}
 
/* Handle */
.OrderPostDetails .WrapBox::-webkit-scrollbar-thumb {
  background: var(--blue);
  border-radius: 20px;
}

/* Handle on hover */
.OrderPostDetails .WrapBox::-webkit-scrollbar-thumb:hover {
  background: var(--blue-dark); 
}
.MobileEventTitle, .MobileEventLocation{
    display: none;
}
.PriceBlock{
    white-space: nowrap;
}
.Close{
    position: absolute;
    right: 10px;
    top: 45px;
    display: none;
}
.Close div{
    font-size: 12px;
    font-family: 'GothamRounded-Light';
}
.Close img{
    height: 25px;
}
.FiltersSelect{
    display: none;
    text-align: center;
    margin: 20px 0;
}
.FiltersSelect > div:nth-child(1){
    font-size: 14px;
    font-family: 'GothamRounded-Medium';
    color: var(--black);
    margin: 10px 0;
}
.FiltersSelect > div:nth-child(2) select{
    background: var(--blue);
    border: 2px solid var(--black);
    width: auto;
    text-align: center;
    border-radius: 10px;
    padding: 10px 15px;
    font-family: 'GothamRounded-Medium';
    font-size: 18px;
}
.FiltersSelect > div:nth-child(2) select option{
    background: var(--white);
}
.MobileTicketText{
    color: var(--black);
    text-align: left;
    font-size: 13px;
}
@media (max-width: 1200px){

    .OrdersSearch{
        padding-top: 20px;
    }
    .FiltersSelect{
        display: block;
    }
}
@media (max-width: 1000px){
    .EventDescription .EventPicture, .EventDescription .EventPicture img{
        width: 8%;
    }
    .EventDescription .EventStatus{
        width: 12%;
    }
    /* Track Order Popup */
    .OrderCurrentStatus{
        display: none;
    }
    .TrackOrderDetails .WrapBox{
        padding: 20px 15px;
    }
}
@media (max-width: 900px){
    .EventDescription .EventStatus{
        width: 15%;
    }
    .Buttons .ActionButtons button{
        padding: 10px 20px;
        margin-right: 10px;
    }
    .Buttons .DownloadButton button{
        padding: 5px 20px;
    }
}
@media (max-width: 768px){
    .Orders .Order{
        width: 95%;
    }
    .EventDescription .EventPicture{
        display: none;
    }
    .EventDescription .Details{
        padding: 20px 10px 0 10px;
        width: 100%;
    }
    .EventDescription .Details .EventTitle > div, .EventDescription .Location > div{
        display: none;
    }
    .EventDescription .Details .EventTitle{
        display: block;
    }
    .EventDescription .Location{
        display: inline-block;
    }
    .EventDescription .Details .EventTitle{
        width: 20%;
    }
    .EventDescription .Details .EventTitle div:nth-child(2){
        margin: 0;
    }
    .EventDescription .Location{
        width: 70%;
        border-left: 2px solid var(--white);
        padding-left: 5%;
    }
    .EventDescription .Details .EventTitle .MobileEventTitle, .EventDescription .Location .MobileEventLocation{
        display: block;
        color: var(--black);
    }
    .EventDescription .Details .EventTitle .MobileEventTitle > div:nth-child(1){
        margin-bottom: 20px;
    }
    .EventDescription .Details .EventTitle .MobileEventTitle > div:nth-child(2){
        color: var(--black);
    }
    .EventDescription .Location .MobileEventLocation > div:nth-child(1){
        color: var(--black);
        font-size: 20px;
        font-family: 'GothamRounded-Medium';
    }
    .EventDescription .Details .EventTitle .MobileEventTitle .TicketCount{
        font-size: 24px;
    }
    .EventDescription .Details .EventTitle .MobileEventTitle .TicketText{
        font-size: 12px;
    }
    .EventDescription .Location > div:nth-child(2){
        margin-right: 60px;
    }
    .Buttons .DownloadButton button div{
        display: none;
    }
    .Buttons .DownloadButton button{
        background: transparent;
        border: 0;
    }
    .Buttons .DownloadButton button img{
        filter: brightness(0) invert(1);
        height: 30px;
    }
    .Buttons .ActionButtons button:nth-child(1) span{
        display: none;
    }
    .Buttons .ActionButtons button{
        padding: 8px 25px;
    }
    .EventDescription .EventStatus{
        width: auto;
        display: flex;
    }
    .EventDescription .EventStatus .StatusTitle, .EventDescription .EventStatus .StatusDescription div{
        display: none;
    }
    .EventDescription .EventStatus .StatusDescription{
        margin: 0;
        display: flex;
        height: 100%;
        align-items: center;
    }
    .EventDescription .EventStatus .StatusDescription img{
        /*filter: invert(76%) sepia(4%) saturate(6125%) hue-rotate(174deg) brightness(118%) contrast(98%);*/
        filter: brightness(0);
        height: auto;
        width: 50px;
    }
    /* Track Order */
    .TrackOrder .SubHeading.TitleSubHeading{
        display: inline-block;
        margin: auto;
        margin-bottom: 10px;
        padding-bottom: 20px;
        border-bottom: 2px solid var(--white);
    }
    .TrackOrder .SubHeading.TitleSubHeading div{
        display: block;
    }
    .TrackOrder .SubHeading.TitleSubHeading div:nth-child(1){
        border-right: 0;
    }
    .TrackOrder .Title div{
        margin-bottom: 10px;
    }
    .TrackOrder .SubHeading.MobileTicketReach{
        display: block;
    }
    .TrackOrder .SubHeading.DesktopTicketReach{
        display: none;
    }
    .OrderPostDetails .WrapBox{
        border-radius: 20px;
    }
    .TrackOrderDetails{
        display: block;
    }
    .OrderShippingAddress, .OrderPostDetails{
        display: block;
    }
    .Close{
        display: block;
    }
    .OrderShippingAddress .CollapseButton{
        display: block;
    }
    .Support{
        display: none;
    }
    .Support.MobileSupport{
        display: block;
    }
}
@media (max-width: 550px){
    .OrdersSearch{
        width: 90%;
      
    }
    .Buttons .ActionButtons button{
        font-size: 12px;
        padding: 8px 20px;
    }
    .Buttons .ActionButtons button img{
        height: 15px;
    }
    .TrackOrder .SubHeading{
        font-size: 12px;
    }
    .OrderShippingAddress, .OrderPostDetails{
        font-size: 12px;
    }
    .TrackOrderContainer{
        width: 90%;
    }
}
@media (max-width: 450px){
    .Buttons .ActionButtons button{
        margin: 10px 0;
        display: block;
    }
}
@media (max-width: 400px){
    .Order .Details{
        padding: 20px 0px 0 5px;
    }
    .EventDescription .Details .EventTitle .MobileEventTitle > div:nth-child(2){
        font-size: 14px;
    }
    .Buttons .ActionButtons{
        text-align: center;
    }
    .Buttons .ActionButtons button{
        margin-bottom: 10px;
        padding: 10px 20px;
    }
    .EventDescription .Location .MobileEventLocation > div:nth-child(2){
        font-size: 12px;
    }
    .TrackOrderContainer{
        width: 100%;
    }
}



@media (max-width: 768px){
    .UrlsUpperContainer{
        padding: 20px 0;
    }
    .LowerContainer{
        padding: 20px 0;
    }
}